import { imageBaseUrl } from '../../utils/constants';
import './index.css';
function Footer() {
  return (
    <div style={{ textAlign: 'center',height: 100}} className='mobileotpbottommargin'>
      <section>
        <div className="imageCTRLMain">
          <div className='imageCtrlFlex'>
            <p className='imageCTRLBtn imagectrlNewbtn'>Powered by </p>
            <img src={`${imageBaseUrl}/ctrlLogo.png`} alt="imgage" className='imageCTRLBtnStyle' />
          </div>
        </div>
      </section>
    </div>
  );
}

export default Footer;
