import React, { useContext, useEffect, useState } from 'react';
import { ApiDispatchContext } from '../../context/ApiContext';
import Avatar from 'react-avatar';
import ErrorAlert from '../ErrorAlert';
import { imageBaseUrl } from '../../utils/constants';

export const CustomAvatar = (props) => {
    const api = useContext(ApiDispatchContext);
    const [profileImg, setProfileImg] = useState("");
    const [modal, setModal] = useState(false);
    const [modalTitle, setModalTitle] = useState("");
    const [modalBody, setModalBody] = useState("");
    const toggle = () => setModal(!modal);
    
    useEffect(() => {
        if(props.filename) {
            let payload = {
                "fileName": props.filename ? props.filename : ""
            }
            api.viewDocuments(payload).then(data => {
                if (data.data && data.data.success) {
                    setProfileImg(data.data.data[0]);
                } else {
                    setModalTitle("Error");
                    if (data.data.error && data.data.message) {
                      setModalBody(data.data.message)
                    } else {
                      setModalBody('Please try after sometime');
                    }
                    toggle();
                }
            }).catch(err => {
              setModalTitle("Error");
              if (err && err.response && err.response.data && err.response.data.message) {
                setModalBody(err.response.data.message);
              } else {
                setModalBody('Please try after sometime.');
              }
              toggle();
            })
        } else {
            setProfileImg(`${imageBaseUrl}/avatar.png`)
        }
    }, [props.filename]);

    return (
        <>
            <Avatar size={props.size} round={props.round} src={`${imageBaseUrl}/avatar.png`} />
            <ErrorAlert
                modal={modal}
                toggle={toggle}
                modalTitle={modalTitle}
                modalBody={modalBody}
            />
        </>
    )
}

export default CustomAvatar;