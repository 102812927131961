import React from 'react';
import CommonNavbar from "../../components/CommonNavbar";
import path from '../../constants/pathConstants';
import PerformanceReport from '../dashboard/PerformanceReport';
import AssesmentReport from '../dashboard/AssesmentReport';
import './index.css';

export function PerformanceReportPage() {
  return (
    <div className='performance-report-page'>
        <CommonNavbar title="Performance Report" backButton={path.DASHBOARD} />
        <div style={{padding:"0px 13px 0px 12px",width:"100%"}}>
        <PerformanceReport isheaderRequired={false} viewReportHyperlink={false}/>
        <AssesmentReport />
        </div>
        <span className='performance-report-page-title'>Detailed reports coming soon!</span>
        </div>
  )
}

export default PerformanceReportPage;