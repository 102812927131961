/*
 * AppReducer
 *
 * The reducer takes care of our data. Using actions, we can
 * update our application state. To add a new action,
 * add it to the switch statement in the reducer function
 *
 */

import produce from 'immer';
import { LOAD_REPOS_SUCCESS, LOAD_REPOS, LOAD_REPOS_ERROR, SET_USER_DATA } from './constants';

// The initial state of the App
export const initialState = {
  loading: false,
  error: false,
  currentUser: false,
  userData: {
    repositories: false,
  },
  userName: "Sheetal",
  role_type:"",
  vendor_emp_id: null
};

/* eslint-disable default-case, no-param-reassign */
const appReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case LOAD_REPOS:
        draft.loading = true;
        draft.error = false;
        draft.userData.repositories = false;
        break;

      case LOAD_REPOS_SUCCESS:
        draft.userData.repositories = action.repos;
        draft.loading = false;
        draft.currentUser = action.username;
        break;

      case LOAD_REPOS_ERROR:
        draft.error = action.error;
        draft.loading = false;
        break;

      case SET_USER_DATA:
        draft.userName= action.payload.employee_name;
        draft.role_type = action.payload.role_type;
        draft.vendor_emp_id = action.payload.vendor_employee_id;
        // Save the data to local storage under the globalState property
        const storedData = JSON.parse(localStorage.getItem('globalState')) || {}; // Get stored data or initialize an empty object
        storedData.userName = action.payload.employee_name;
        storedData.role_type = action.payload.role_type;
        storedData.vendor_emp_id = action.payload.vendor_employee_id;
        localStorage.setItem('globalState', JSON.stringify(storedData)); // Store the updated data

        break;
    }
  });

export default appReducer;
