import produce from "immer";
import { DEFAULT_ACTION, SEARCH_CASE_VALUE } from "./constants";

export const initialState = {
  caseSearchValue: ""
};

const searchCaseReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case DEFAULT_ACTION:
        break;
      case SEARCH_CASE_VALUE:
        draft.caseSearchValue = action.payload
        break;
    }
  });

export default searchCaseReducer;
