import React, { useEffect ,useState,useContext} from 'react'
import { ApiDispatchContext } from "../../../context/ApiContext";
import { getUrlParameter } from "../../../constants/commonFunction";
import ErrorAlert from "../../../components/ErrorAlert";
export const Basic = props => {
  const [basic, setbasic] = useState("");
  const api = useContext(ApiDispatchContext);
  const [modal, setModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalBody, setModalBody] = useState("");
  const toggle = () => setModal(!modal);
  let casid = Number(getUrlParameter('case'));
   useEffect(() => {
    if(casid){
      let payload ={
        "caseId": casid,
        "segmentType": "basic"
      }
      api.getCaseBPRSegmentDetails(payload).then(data => {
        if (data.data && data.data.success && data.data.data.length) {
          setbasic(data.data.data)
        } else {
          setModalTitle("Failed to load Basic Data.");
          if(data.data.error && data.data.message){
            setModalBody(data.data.message)
          }
          else{
            setModalBody('Please try after sometime');
          }
          toggle();
        }
      }).catch(err => {
        setModalTitle("Failed to load Basic Data.");
        if(err && err.response && err.response.data && err.response.data.message){
          setModalBody(err.response.data.message);
        } else {
          setModalBody('Please try after sometime');
        }
        toggle();
      })
    }
  }, [casid]);

  return (
    <div>
      <div className='map-data'>
        {basic.length > 0 && basic.map(item => (
          <div className='map-data-breakDIV' key={item?.label}>
            <div className='map-data-text1'>{item.label}</div>
            <div className='map-data-text2'>{item.value ? item.value : '-'}</div>
          </div>
        ))
        }
      </div>
      <ErrorAlert
        modal={modal}
        toggle={toggle}
        modalTitle={modalTitle}
        modalBody={modalBody}
      />
    </div>
  )
}

Basic.propTypes = {}

export default Basic;