import { DEFAULT_ACTION,FORMDATA, SETFORMDATA} from "./constants";

export function defaultAction() {
  return {
    type: DEFAULT_ACTION
  };
}

export function formData(data) {
  return {
    type: FORMDATA,
    payload: data
  }
}

export function setFormValue(data){
  return {
    type: SETFORMDATA,
    payload: data
  }
}

