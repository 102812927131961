import { createSelector } from "reselect";
import { initialState } from "./reducer";

const selectAssignedBlocksOpenCasesDomain = state =>
  state.assignedBlocksOpenCases || initialState;

const makeSelectAssignedBlocksOpenCases = () =>
  createSelector(
    selectAssignedBlocksOpenCasesDomain,
    substate => substate
  );

const selectForms = () =>
  createSelector(
    selectAssignedBlocksOpenCasesDomain,
    substate => { return substate.formData2 }
  );

export default makeSelectAssignedBlocksOpenCases;
export { selectAssignedBlocksOpenCasesDomain, selectForms };
