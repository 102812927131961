import produce from "immer";
import { DEFAULT_ACTION, CASEUPDATE, SELECTCASE } from "./constants";

export const initialState = {
  caseidArr: []
};

/* eslint-disable default-case, no-param-reassign */
const casesDetailsReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case DEFAULT_ACTION:
        break;
      case CASEUPDATE:
        draft.cardData = action.payload
        break;
      case SELECTCASE:
        draft.caseidArr = action.payload
        break;
    }
  });

export default casesDetailsReducer;
