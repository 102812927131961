import path from "./pathConstants"
const dummyDashboardCases = [{ cases: "", caseNumber: "", bgColor: "#1AB275", boxShadow: "0px 4px 5px rgba(26, 178, 117, 0.5)",path:path.OPEN_CASE },
{ cases: "", caseNumber: "", bgColor: "#058AEE", boxShadow: "0px 4px 5px rgba(5, 138, 238, 0.5)",path:path.ALLOTTED_CASES },
{ cases: "", caseNumber: "", bgColor: "#EBAF00", boxShadow: "0px 4px 5px rgba(235, 175, 0, 0.5)",path:path.REINVESTIGATE_CASES },
{ cases: "", caseNumber: "", bgColor: "#7C144B", boxShadow: "0px 4px 5px rgba(124, 20, 75, 0.5)",path:path.REJECTED_INVOICES }]

const dummyOpenCases = [{ cases: "", caseNumber: "", bgColor: "#1AB275", boxShadow: "0px 4px 5px rgba(26, 178, 117, 0.5)",path:path.OPEN_CASE },
{ cases: "", caseNumber: "", color: "#505050", bgColor: "rgba(5, 138, 238, 0.1)", boxShadow: "0px 4px 5px rgba(5, 138, 238, 0.5)",path:path.ALLOTTED_CASES },
{ cases: "", caseNumber: "", color: "#505050", bgColor: "rgba(235, 175, 0, 0.1)", boxShadow: "0px 4px 5px rgba(235, 175, 0, 0.5)",path:path.REINVESTIGATE_CASES },
{ cases: "", caseNumber: "", color: "#505050", bgColor: "rgba(124, 20, 75, 0.1)", boxShadow: "0px 4px 5px rgba(124, 20, 75, 0.5)",path:path.REJECTED_INVOICES }]

const dummyAllottedCases = [{ cases: "Open Cases", caseNumber: "20", bgColor: "rgba(26, 178, 117, 0.1)",color:'#3A3A3A', boxShadow: "0px 4px 5px rgba(26, 178, 117, 0.5)",path:path.OPEN_CASE },
{ cases: "Allotted Cases", caseNumber: "20", bgColor: "#058AEE", color:'#FFFFFF',boxShadow: "0px 4px 5px rgba(5, 138, 238, 0.5)",path:path.ALLOTTED_CASES },
{ cases: "Reinvestigate Cases", caseNumber: "20", bgColor: "rgba(235, 175, 0, 0.1)", color:'#3A3A3A',boxShadow: "0px 4px 5px rgba(235, 175, 0, 0.5)",path:path.REINVESTIGATE_CASES},
{ cases: "Rejected invoices", caseNumber: "20", bgColor: "rgba(124, 20, 75, 0.1)", color:'#3A3A3A',boxShadow: "0px 4px 5px rgba(124, 20, 75, 0.5)",path:path.REJECTED_INVOICES }]

const dummyReinvestigateCases = [{ cases: "Open Cases", caseNumber: "20", bgColor: "rgba(26, 178, 117, 0.1)",color:'#3A3A3A', boxShadow: "0px 4px 5px rgba(26, 178, 117, 0.5)",path:path.OPEN_CASE },
{ cases: "Allotted Cases", caseNumber: "20", bgColor: "rgba(5, 138, 238, 0.1)", color:'#3A3A3A',boxShadow: "0px 4px 5px rgba(5, 138, 238, 0.5)",path:path.ALLOTTED_CASES },
{ cases: "Reinvestigate Cases", caseNumber: "20", bgColor: "#EBAF00", color:'#FFFFFF',boxShadow: "0px 4px 5px rgba(235, 175, 0, 0.5)",path:path.REINVESTIGATE_CASES},
{ cases: "Rejected invoices", caseNumber: "20", bgColor: "rgba(124, 20, 75, 0.1)", color:'#3A3A3A',boxShadow: "0px 4px 5px rgba(124, 20, 75, 0.5)",path:path.REJECTED_INVOICES }]

const dummyRejectedInvoices = [{ cases: "Open Cases", caseNumber: "20", bgColor: "rgba(26, 178, 117, 0.1)",color:'#3A3A3A', boxShadow: "0px 4px 5px rgba(26, 178, 117, 0.5)",path:path.OPEN_CASE },
{ cases: "Allotted Cases", caseNumber: "20", color: "#505050", bgColor: "rgba(5, 138, 238, 0.1)", boxShadow: "0px 4px 5px rgba(5, 138, 238, 0.5)",path:path.ALLOTTED_CASES },
{ cases: "Reinvestigate Cases", caseNumber: "20", bgColor: "rgba(235, 175, 0, 0.1)", color:'#3A3A3A',boxShadow: "0px 4px 5px rgba(235, 175, 0, 0.5)",path:path.REINVESTIGATE_CASES},
{ cases: "Rejected invoices", caseNumber: "20", bgColor: "#7C144B", color:'#FFFFFF',boxShadow: "0px 4px 5px rgba(124, 20, 75, 0.5)",path:path.REJECTED_INVOICES }]

export {dummyDashboardCases,dummyOpenCases,dummyAllottedCases, dummyRejectedInvoices,dummyReinvestigateCases}
