import React, { useEffect, useState, useContext, memo } from 'react';
import { ApiDispatchContext } from "../../../context/ApiContext";
import ErrorAlert from "../../../components/ErrorAlert";
import Loader from '../../../components/Loader';
import { getUrlParameter } from "../../../constants/commonFunction";
import Challan from './ExternalTabs/Challan';
import DLVeridication from './ExternalTabs/DLVerification';
import PANVerification from './ExternalTabs/PANVerification';
import Vahan from './ExternalTabs/Vahan';

export const External = props => {
  const [externalInfo, setExternalInfo] = useState("");
  const api = useContext(ApiDispatchContext);
  const [modal, setModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalBody, setModalBody] = useState("");
  const toggle = () => setModal(!modal);
  let casid = Number(getUrlParameter('case'));
  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    setShowLoader(true);
    api.getExternalData({"caseId": casid}).then(data => {
      if (data?.data && data?.data?.success && data?.data?.data) {
        setShowLoader(false);
        setExternalInfo(data?.data?.data);
      } else {
        setModalTitle("Error");
        if (data?.data?.error && data?.data?.message) {
          setModalBody(data?.data?.message)
        } else {
          setModalBody('Please try after sometime');
        }
        toggle();
        setShowLoader(false);
      }
    }).catch(err => {
      setModalTitle("Error");
      if (err && err?.response && err?.response?.data && err?.response?.data?.message) {
        setModalBody(err?.response?.data?.message);
      } else {
        setModalBody('Please try after sometime.');
      }
      toggle();
      setShowLoader(false);
    })
  }, [casid]);

  return (
    <div className='basic'>
      <div className='map-data'>
      {(externalInfo && externalInfo?.vehicleInfo) && <Vahan External={externalInfo?.vehicleInfo}/>}
        {(externalInfo && externalInfo?.challanInfo) && <Challan External={externalInfo?.challanInfo}/>}
        {(externalInfo && externalInfo?.panInfo) && <PANVerification External={externalInfo?.panInfo}/>}
        {(externalInfo && externalInfo?.dlInfo) && <DLVeridication External={externalInfo?.dlInfo}/>}
      </div>
      {showLoader && <Loader />}
      <ErrorAlert
        modal={modal}
        toggle={toggle}
        modalTitle={modalTitle}
        modalBody={modalBody}
      />
    </div>
  )
}

External.propTypes = {}

export default memo(External);