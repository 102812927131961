import React, { memo } from 'react';
import './index.css';

export const Textarea = memo((props) => {
  return (
    <div className='textinput-genric-main'>
      <div className='Genericforms-text'>{props.config.renamed_block_parameter}{props.ifRequired && <sup>*</sup>}</div>
      <textarea 
      required={props.ifRequired}
      placeholder='Remarks' 
    //   value={item.value} 
      className='assignedblocks-textarea' 
      data-testid="textareaassignedblock" 
    //   onChange={(event) => { handleChange(index1, index, event) }} 
      />
    </div>
  )
})

Textarea.propTypes = {};

export default Textarea;