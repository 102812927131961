import { createSelector } from "reselect";
import { initialState } from "./reducer";

const selectOpenCasesDetailsPageDomain = state =>
  state.openCasesDetailsPage || initialState;

const makeSelectOpenCasesDetailsPage = () =>
  createSelector(
    selectOpenCasesDetailsPageDomain,
    substate => substate
  );

const getClaimcountNumber  = () =>
createSelector(
  selectOpenCasesDetailsPageDomain,
  substate => {
    return substate.claimNumber
  }
);

export default makeSelectOpenCasesDetailsPage;
export { selectOpenCasesDetailsPageDomain ,getClaimcountNumber};
