import React, { memo, useState, useEffect, useContext, useCallback } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { compose } from "redux";
import { useInjectReducer } from "../../utils/injectReducer";
import makeSelectUploadInvestigationReport from "./selectors";
import reducer from "./reducer";
import CommonNavbar from "../../components/CommonNavbar";
import FilterPopUp from "../../components/FilterPopUp";
import DocumentUpload from "../../components/DocumentUpload";
import { ApiDispatchContext } from "../../context/ApiContext";
import { getUrlParameter } from "../../constants/commonFunction";
import ErrorAlert from "../../components/ErrorAlert";
import Loader from "../../components/Loader";
import SuccessMessage from "../../components/SuccessMssgDisplay";
import UploadedFileView from "../../components/UploadedFileView";
import { push } from 'connected-react-router';
import './index.css';
import { getVendorEmployeeID } from "../App/selectors";
import { imageBaseUrl } from "../../utils/constants";
export function UploadInvestigationReport({employeeId}) {
  useInjectReducer({ key: "uploadInvestigationReport", reducer });
  const [open, setOpen] = useState(false);
  const api = useContext(ApiDispatchContext);
  const [modal, setModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [docs, setDocs] = useState(false)
  const [modalBody, setModalBody] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [showCloseDocs,setShowCloseDocs] = useState(false)
  const [docsUploaded,setDocsUploaded] = useState(false)
  const toggle = () => setModal(!modal);
  let casetype = getUrlParameter('casetype');
  let caseid = Number(getUrlParameter('case'))

  useEffect(() => {
    setShowLoader(true);
    api.getInvoiceList({ "caseId": caseid, "document_type": "investigationReport" }).then(data => {
      setShowLoader(false);
      if (data?.data?.data?.newInvoices?.length) {
        setDocs(data?.data?.data?.newInvoices)
      }
    }).catch(err => {
      setShowLoader(false);
      setModalTitle("Error");
      if (err?.response?.data?.message) {
        setModalBody(err?.response?.data?.message);
      } else {
        setModalBody('Please try after sometime');
      }
      toggle();
    });
  }, [showSuccessMessage, open, docsUploaded])

  const filterToggle = () => {
    setOpen(!open);
  }

  const uploadDocuments = useCallback(async(file, filetype) => {
    setShowLoader(true);
    await api.submitInvestigationReport({ file, caseid }).then(data => {
      if (data?.data && data?.data?.success) {
        setShowLoader(false);
        setOpen(!open)
        setShowSuccessMessage(true)
      }
    }).catch(err => {
      setModalTitle("Error");
      if (err?.response?.data?.message) {
        setModalBody(err?.response?.data?.message);
      } else {
        setModalBody('Please try after sometime');
      }
      toggle();
      setShowLoader(false);
    });
  }, [caseid, open]);

  const checkEditenable = useCallback(() => {
    for (let index = 0; index < docs?.length; index++) {
      if(docs[index].vendor_employee_id === employeeId){
        return true;
      }
    }
    return false;
  }, [docs, employeeId]);

  const onClose = useCallback((e,index)=>{
    e.stopPropagation();
    const list =  [...docs];
    let invoice_documents_id = list[index]["invoice_documents_id"];
    api.deleteNewInvoiceList({ "caseId": caseid, "invoice_documents_id": invoice_documents_id, "document_type": "investigationReport" }).then(data => {
      if (data?.data?.success) {
        list.splice(index,1);
        setDocs(list)
      } else {
        setModalTitle("Error");
        if(data?.data?.error && data?.data?.message){
          setModalBody(data?.data?.message)
        }
        else{
          setModalBody('Please try after sometime');
        }
        toggle();
      }
    }).catch(err => {
      setModalTitle("Error");
      if(err?.response?.data?.message){
        setModalBody(err?.response?.data?.message);
      } else {
        setModalBody('Please try after sometime.');
      }
      toggle();
    })
  }, [docs, caseid]);

  function borderColor() {
    return casetype === "opencase" ? " 1px solid #1AB275" : casetype === "allottedcase" ? "1px solid #058AEE" : "1px solid #EBAF00";
  }

  return <div data-testid="uploaInvestigatorReportContainer" className={casetype + "UploadInvestigationReport"}>
    <CommonNavbar title="Upload Investigation Report" />
    {docs.length > 0 ? (
      <div className="upload-reports-container">
        <div>
        <div style={{display:"flex",justifyContent:"space-between",alignItems:"center",marginLeft:"10px",marginRight:"10px"}}>
            <div className='investgation-header'>Uploaded Reports</div>
            {checkEditenable() &&
              <img style={{height:"17px",width:"17px"}} data-testid="UploadInvoiceDetails2" src={`${imageBaseUrl}/edit1.png`} onClick={()=>setShowCloseDocs(!showCloseDocs)} />
            }
            </div>  
          <div className="upload-docs-container">
              <UploadedFileView showCloseDocs={showCloseDocs} onClose={showCloseDocs?(e,index)=>onClose(e,index):null} download={false} border={borderColor()} documentList={docs} deleteOwnFileCheck={false} />
          </div>
        </div>
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
          <button className="upload-docs-btn" data-testid="uploadInvestigationReport2" onClick={filterToggle} >
            <img src={`${imageBaseUrl}/uploadIcon.png`} />
            <span>Upload</span>
          </button>
        </div>
      </div>
    ) : (
      <div className="no-documents-container">
        <img src={`${imageBaseUrl}/EmptyState.png`} alt="empty" />
        <span className="no-documents-title">No Documents</span>
        <span className="no-documents-text">Start uploading your documents</span>
        <button className="no-documents-btn" data-testid="uploadInvestigationReport1" onClick={filterToggle} >
          <img src={`${imageBaseUrl}/uploadIcon.png`} alt="upload" />
          <span>Upload</span>
        </button>
      </div>
    )}
    {open && (<FilterPopUp open={open} backgroundColor={'#8F949B80'} filterToggle={filterToggle} >
      <DocumentUpload uploadDocuments={uploadDocuments} docsUploaded={docsUploaded} setDocsUploaded={setDocsUploaded}  />
    </FilterPopUp>)}
    {showLoader && <Loader />}
    {showSuccessMessage && (
      <SuccessMessage message="Records Updated Successfully!" delay={3000} />
    )}
    <ErrorAlert
      modal={modal}
      toggle={toggle}
      modalTitle={modalTitle}
      modalBody={modalBody}
    />
  </div>;
}

UploadInvestigationReport.propTypes = {
  dispatch: PropTypes.func.isRequired
};

const mapStateToProps = createStructuredSelector({
  uploadInvestigationReport: makeSelectUploadInvestigationReport(),
  employeeId: getVendorEmployeeID()
});

export function mapDispatchToProps(dispatch) {
  return {
    redirectPath: (path) => dispatch(push(path)),
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);

export default compose( withConnect, memo)(UploadInvestigationReport);