import React, { useState, useEffect } from 'react';
import { RadialBarChart, RadialBar, PolarAngleAxis } from 'recharts';

const LeaderLineLabel = ({ cx, cy, innerRadius, outerRadius, startAngle, endAngle, index, value, color, data }) => {
  let lineLength = 100;
  let x = 75;
  let y = 100;
  let liney = 0;
  if(index === 0){
    y = 120;
    lineLength= 80;
  } else if(index === 1){
    y = 130;
    lineLength= 150;
    liney=100;
  }
  return (
    <g>
      <line
        x1={x}
        y1={y-liney}
        x2={x + lineLength}
        y2={y-liney}
        stroke={color} 
        strokeWidth="1"
      />
      <circle cx={x + lineLength} cy={y-liney} r="2" fill={color} />
      <text
        x={x + lineLength + 10}
        y={y+5 -liney}
        textAnchor="start"
        fill={color} 
        style={{fontSize:12, fontWeight:'700', lineHeight:'normal'}}
      >
        {value}%
      </text>
      <text
        x={x + lineLength + 10}
        y={y+15 -liney}
        textAnchor="start"
        fill={'#3A3A3A'}
        style={{fontSize:10, fontWeight:'600', lineHeight:'normal'}}
      >
        {data[index].total} {data[index].name}
      </text>
    </g>
  );
};

export const RadialBarChartWithLeaderLabels = ({graphData}) => {
  const [displayRadilbar, setdisplayRadilbar] = useState(true);
  let data = [
    { name: 'All Product Cases', value: 0, fill: '#1B9CFC', color: '#1B9CFC', total: 0 },
    { name: 'Health Cases', value: 0, fill: '#25CCF7',color: '#25CCF7', total: 0 },
    { name: 'Motor Cases', value: 0, fill: '#3B3B98',color: '#3B3B98', total: 0 },
  ];
  data.forEach(item => {
    if (item.name === 'Health Cases' && graphData["A&H"]) {
      item.value = parseFloat(graphData["A&H"].percentage_over_tat);
      item.total = graphData["A&H"].total;
    } else if (item.name === 'Motor Cases' && graphData["Motor"]) {
      item.value = parseFloat(graphData["Motor"].percentage_over_tat);
      item.total = graphData["Motor"].total;
    }
  });
  const filteredData = data.filter(item => item.value !== 0);
  const allValuesAreZero = filteredData.every(item => item.value === 0);

  useEffect(() => {
    if (allValuesAreZero) {
      setdisplayRadilbar(false);
    } else {
      setdisplayRadilbar(true);
    }
  }, [allValuesAreZero]);
  return (
    <div data-testid="RadialBarChart">
      {displayRadilbar ? (
        <RadialBarChart  
          width={350}
          height={140}
          cx={75}
          cy={80}
          innerRadius={40}
          outerRadius={60}
          barSize={8}
          data={filteredData}
          startAngle={270}
          endAngle={-90}> 
            <PolarAngleAxis type="number" domain={[0, 100]} angleAxisId={0} tick={false} />
            <RadialBar
            dataKey="value"
            background
            clockWise
            label={<LeaderLineLabel data={filteredData} />}
            isAnimationActive={false}
            cornerRadius={4}
            />
        </RadialBarChart>
      ) : (
        <div className="main-tooltipinfo" style={{ display: 'flex' , justifyContent:'center'}}>
          {data.map((item, i) => {
            return (
              <div className='performance-dispdata' key={i} style={{ padding: '10px 10px' }}>
                <div style={{ lineHeight: '12px' }}>
                  <div className='performance-dispdata3' style={{ color: item.color, fontSize:12, fontWeight:"700" }}>{item.value}%</div>
                  <div className='performance-dispdata4' style={{marginTop:2, fontSize:12, color:'#3A3A3A', fontWeight:"600" }}>{item.total} {item.name}</div>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default RadialBarChartWithLeaderLabels;
