import DetailsCard from "./DetailsCard";
import './index.css';

export const InvoiceDetails = ({ invoiceDetailsData }) => {
  return (
    <div>
      {invoiceDetailsData?.map((item1, index) => <DetailsCard data={item1} SrNumber={index+1} key={`invoiceDetails-${index}`} />)}
    </div>
  )
}

export default InvoiceDetails;