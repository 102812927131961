import { createSelector } from "reselect";
import { initialState } from "./reducer";

const selectUploadInvoiceDomain = state => state.uploadInvoice || initialState;

const makeSelectUploadInvoice = () =>
  createSelector(
    selectUploadInvoiceDomain,
    substate => substate
  );

  const selectForms = () =>
  createSelector(
    selectUploadInvoiceDomain,
    substate => { return substate.formData2 }
  );
  
  const setvaluefetched = () =>
  createSelector(
    selectUploadInvoiceDomain,
    substate => {
      return substate.valuefetched;
    }
  );
export default makeSelectUploadInvoice;
export { selectUploadInvoiceDomain,selectForms, setvaluefetched };
