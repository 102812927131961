import React, { memo, useCallback, useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { compose } from "redux";
import { useInjectReducer } from "../../utils/injectReducer";
import reducer from "./reducer";
import "./index.css";
import CommonNavbar from "../../components/CommonNavbar";
import path from "../../constants/pathConstants";
import { ApiDispatchContext } from "../../context/ApiContext";
import ErrorAlert from "../../components/ErrorAlert";
import SuccessMessage from "../../components/SuccessMssgDisplay";
import Loader from "../../components/Loader";
import LocationDropdown from './dropdown';
import AcceptButtons from "../OpenCasesDetailsPage/AcceptButtons";
import { useNavigate } from "react-router-dom";
import { imageBaseUrl } from "../../utils/constants";
export function AddNewLocation() {
  useInjectReducer({ key: "addNewLocation", reducer });
  const [modal, setModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalBody, setModalBody] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const toggle = () => setModal(!modal);
  const api = useContext(ApiDispatchContext);
  const [states, setstates] = useState([]);
  const [newlocation, setnewlocation] = useState([]);

  const navigate = useNavigate();
  
  useEffect(() => {
    window.scrollTo(0, 0);
    api.getStateMaster().then(data => {
      if (data?.data?.data) {
        setstates(data?.data?.data);
        initialaddAnotherState(data?.data?.data);
      } 
      else {
        setModalTitle("Error");
        if (data?.data?.error && data?.data?.message) {
          setModalBody(data?.data?.message)
        }
        else {
          setModalBody('Please try after sometime');
        }
        toggle();
      }
    }).catch(err => {
      setModalTitle("Error");
      if (err?.response?.data?.message) {
        setModalBody(err?.response?.data?.message);
      } else {
        setModalBody('Please try after sometime');
      }
      toggle();
    })
  }, []);

  const initialaddAnotherState = useCallback((states) => {
    let tempdata = [
      { label: "State", options: states, selected: '' },
      { label: "City", options: [], selected: '' }
    ];
    const updatedLocationArray = [...newlocation, tempdata];
    setnewlocation(updatedLocationArray);
  }, [newlocation]);

  const addAnotherState = useCallback(() => {
    let tempdata = [
      { label: "State", options: states, selected: '' },
      { label: "City", options: [], selected: '' }
    ];
    const updatedLocationArray = [...newlocation, tempdata];
    setnewlocation(updatedLocationArray);
  }, [states, newlocation]);

  const isSelectionPresentInAllObjects = useCallback(() => {
    if (newlocation?.length > 0) {
      return newlocation?.every((group) =>
        group?.every((options) => options?.selected !== '')
      );
    }
    return false;
  }, [newlocation]);
  
  const removeState = useCallback((index) => {
    setnewlocation((prevData) => {
      const tempData = [...prevData]; // Create a shallow copy of the previous state array
      tempData.splice(index, 1); // Remove the element at the specified index
      return tempData;
    });
  }, []);

  const addCityDropdown = useCallback((citylist, index) => {
    let tempdata = [];
    Object.assign(tempdata, newlocation);
    tempdata[index].forEach((item) => {
      if (item.label === "City") {
        item.options = citylist;
      }
    });
    setnewlocation(tempdata);
  }, [newlocation]);

  const selectDropdown = useCallback((value, index, label) => {
    let tempdata = [];
    Object.assign(tempdata, newlocation);
      tempdata[index].forEach((item) => {
        if (item.label === label) {
          item.selected = value;
        }
        if(label === 'State'){
          if(item.label === 'City'){
            item.selected = '';
            item.options = [];
          }
        }
      });
    setnewlocation(tempdata);
    if(label ==='State') {
      let payload ={
        "state": value
      }
      api.getCityMaster(payload).then(data => {
        if (data?.data?.data) {
         addCityDropdown(data?.data?.data,index);
        } 
        else {
          setModalTitle("Error");
          if (data?.data?.error && data?.data?.message) {
            setModalBody(data?.data?.message)
          }
          else {
            setModalBody('Please try after sometime');
          }
          toggle();
        }
      }).catch(err => {
        setModalTitle("Error");
        if (err?.response?.data?.message) {
          setModalBody(err?.response?.data?.message);
        } else {
          setModalBody('Please try after sometime');
        }
        toggle();
      })
    }
  }, [newlocation]);

  const submitlocation = useCallback(() => {
    let payload = newlocation?.map(items => {
      return {
        "state": items[0].selected,
        "city": items[1].selected
      };
    });
    api.addStatesAndCities(payload).then(data => {
      if (data?.data?.success) {
        navigate(path.SERVICE_LOCATIONS)
      } 
      else {
        setModalTitle("Error");
        if (data?.data?.error && data?.data?.message) {
          setModalBody(data?.data?.message)
        }
        else {
          setModalBody('Please try after sometime');
        }
        toggle();
      }
    }).catch(err => {
      setModalTitle("Error");
      if (err?.response?.data?.message) {
        setModalBody(err?.response?.data?.message);
      } else {
        setModalBody('Please try after sometime');
      }
      toggle();
    })
  }, [newlocation]);

  return(
    <div className="addNewLocation-wrapper">
      <CommonNavbar title="Add New Location" search={false} download={false} backButton={path.SERVICE_LOCATIONS} navbarname='AddNewLocation' />
      <div style={{marginTop:25}}>
        <div className="input-container">
        {newlocation && newlocation.map((locationGroup, index) => (
        <div key={index} className="addNewLocation-location-main">
          {index !== 0 &&<div className="addNewLocation-close-div-main"><button data-testid="removeState" onClick={() => removeState(index)} className="addNewLocation-details-remove"> <img src={`${imageBaseUrl}/cross.svg`} alt="cross" className="crossimgwidth-addNewLocation"/></button></div>}
            {locationGroup.map((locationItem) => (
              <div key={locationItem.id} style={{margin:'6px 0px'}}>
                <div className="addNewLocation-location-heading">{locationItem.label}</div>
                <LocationDropdown
                  stateSelected ={locationGroup[0].selected} 
                  locationItem={locationItem}
                  showArray={locationItem.options}
                  selectDropdown={(value) => selectDropdown(value,index,locationItem.label)}
                  type={locationItem.label.toLocaleLowerCase()}
                />
              </div>
            ))}
          </div>
        ))}
          <button data-testid="addAnother" className='addEmpDetails-addAnother' onClick={() => addAnotherState()}>+ Add another state</button>
          <div className="AcceptButtons-ContainerParent">
        <div className="AcceptButtons-Container" style={{position:newlocation && newlocation.length > 3 ?'absolute' :'fixed', marginTop:10, bottom:newlocation && newlocation.length > 3 ? 'unset' : '65px',}}>
          <AcceptButtons className="AddNewLocation-button" onClick={() => submitlocation()} disabled={!isSelectionPresentInAllObjects()} background={isSelectionPresentInAllObjects() ? "#E42125" : '#fef0f0'} color={isSelectionPresentInAllObjects() ? "rgb(255, 255, 255)":'#E94D51'} data-testid="save-button">Submit</AcceptButtons>
          </div>
      </div>
        </div>
      </div>
      {showLoader && <Loader />}
      {showSuccessMessage && (
        <SuccessMessage message="Records Updated Successfully!" delay={3000} />
      )}
      <ErrorAlert
        modal={modal}
        toggle={toggle}
        modalTitle={modalTitle}
        modalBody={modalBody}
      />
    </div>
  );
}

AddNewLocation.propTypes = {
  dispatch: PropTypes.func.isRequired
};

const mapStateToProps = createStructuredSelector({
});

export function mapDispatchToProps(dispatch) {
  return {
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);

export default compose( withConnect, memo)(AddNewLocation);