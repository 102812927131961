import React, { useEffect, useState, useContext, memo, useCallback } from 'react';
import { ApiDispatchContext } from "../../../../context/ApiContext";
import ErrorAlert from "../../../../components/ErrorAlert";
import { getUrlParameter } from "../../../../constants/commonFunction";
import '../cardtab.css';
import '../../../AllottedCasesDetailsPage/CardTabs/cardtab.css';
import AcceptButtons from '../../../OpenCasesDetailsPage/AcceptButtons/index';
import path from '../../../../constants/pathConstants';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import propTypes from 'prop-types';
import { compose } from 'redux';
import FilterPopUp from '../../../../components/FilterPopUp';
import Actions from '../../../../components/Actions';
import SuccessMessage from "../../../../components/SuccessMssgDisplay";
import Loader from '../../../../components/Loader';
import CaseBlocks from './CaseBlocks';
import NewBlocksAssigned from './NewBlocksAssigned';
import { useNavigate } from 'react-router-dom';
import { mandatoryBlocksIdsForHealth, mandatoryBlocksIdsForOD, mandatoryBlocksIdsForTP } from '../../../../constants/constants';

export const InvestigatorInvestigation = ({ redirectPath }) => {
  const api = useContext(ApiDispatchContext);
  const [modal, setModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalBody, setModalBody] = useState("");
  const toggle = () => setModal(!modal);
  const [itemsToDisplay, setitemsToDisplay] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const [showAll, setShowAll] = useState(false);
  const [open, setOpen] = useState(false);
  const [blockOpen, setBlockOpen] = useState(false);
  let casid = Number(getUrlParameter('case'));
  let redirectedFrom = getUrlParameter('redirectedFrom');
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [actionDisable, setActionDisable] = useState(false);
  let subProduct = getUrlParameter('subProduct');
  let lob = getUrlParameter('lob');

  const navigate = useNavigate()

  useEffect(() => {
    if (casid) {
      let payload = {
        "caseId": casid,
        "assignedToInvestigator": true,
        "page": 1,
        "pageSize": 10,
      }
      setShowLoader(true);
      api.getAssignedInvestigatorsDetails(payload).then(data => {
        if (data.data && data.data.success) {
          setShowLoader(false);
          setShowAll(true);
          setitemsToDisplay(data.data.data);
          checkActionDisable(data.data.data.assigned_blocks && data.data.data.assigned_blocks.blocks.length > 0 ? data.data.data.assigned_blocks.blocks : []);
          if (redirectedFrom && redirectedFrom === "AssignedBlocks") {
            setBlockOpen(true);
          }
        } else {
          setModalTitle("Failed to load allotedclaim Data.");
          if (data.data.error && data.data.message) {
            setModalBody(data.data.message)
          }
          else {
            setModalBody('Please try after sometime');
          }
          toggle();
          setShowLoader(false);
        }
      }).catch(err => {
        setModalTitle("Failed to load allotedclaim Data.");
        if (err && err.response && err.response.data && err.response.data.message) {
          setModalBody(err.response.data.message);
        } else {
          setModalBody('Please try after sometime.');
        }
        toggle();
        setShowLoader(false);
      })
    }
  }, [casid]);


  const handleChange = useCallback((event) => {
    let tempdata = {};
    Object.assign(tempdata, itemsToDisplay);
    tempdata['remark'] = event.target.value;
    setitemsToDisplay(tempdata);
  }, [itemsToDisplay]);

  const checkActionDisable = useCallback((blocksArr) => {
    let count = 0;
    if(["OD", "Motor/OD"].includes(subProduct)) {
      blocksArr?.length > 0 && blocksArr?.forEach((data) => {
        if(mandatoryBlocksIdsForTP.includes(data?.block_master_id)) {
          if(data?.block_status === "inProgress" || data?.block_status === "pending") {
            count++;
          }
        }
      });
    } else if(["TP", "Motor/TP"].includes(subProduct)) {
      blocksArr?.length > 0 && blocksArr?.forEach((data) => {
      if(mandatoryBlocksIdsForOD.includes(data?.block_master_id)) {
        if(data?.block_status === "inProgress" || data?.block_status === "pending") {
          count++;
        }
      }
    })} else if(lob === "AH") {
      blocksArr?.length > 0 && blocksArr?.forEach((data) => {
      if(mandatoryBlocksIdsForHealth.includes(data?.block_master_id)) {
        if(data?.block_status === "inProgress" || data?.block_status === "pending") {
          count++;
        }
      }
    })}  else {
      blocksArr?.length > 0 && blocksArr?.forEach((data) => {
        if(data?.block_status === "inProgress" || data?.block_status === "pending") {
          count++;
        }
      });
    }
    if(count == 0) {
      setActionDisable(false);
    } else {
      setActionDisable(true);
    }
  }, [subProduct]);

  const submitRemark = useCallback(async(value) => {
    setShowSuccessMessage(false);
    let payload = {
      "caseId": casid,
      "remark": value
    }
    api.submitCaseRemark(payload).then(data => {
      if (data?.data && data?.data?.success) {
        setShowSuccessMessage(true);
      } else {
        setModalTitle("Failed to Save remark Data.");
        if (data?.data?.error && data?.data?.message) {
          setModalBody(data?.data?.message)
        } else {
          setModalBody('Please try after sometime');
        }
        toggle();
      }
    }).catch(err => {
      setModalTitle("Failed to Save remark Data.");
      if (err?.response?.data?.message) {
        setModalBody(err?.response?.data?.message);
      } else {
        setModalBody('Please try after sometime.');
      }
      toggle();
    })
  }, [casid]);

  const Onviewclick = (blockid, blockname, flag) => {
    navigate(path.ASSIGNED_BLOCKS_REINVESTIGATED_CASES + '?' + btoa('case=' + casid + '&subProduct=' + subProduct + '&blockid=' + blockid + '&blockname=' + blockname + '&multiform=' + flag));
  }

  const filterToggle = () => {
    setOpen(!open);
  }

  return (
    <div className='reinvestigatedclaim-data'>
      {itemsToDisplay.new_assigned_blocks && itemsToDisplay.new_assigned_blocks.count > 0 && <NewBlocksAssigned new_assigned_blocks={itemsToDisplay.new_assigned_blocks} />}
      <CaseBlocks blockOpen={blockOpen} assigned_blocks={itemsToDisplay.assigned_blocks} remark={itemsToDisplay.remark ? itemsToDisplay.remark : ""} Onviewclick={Onviewclick} submitRemark={submitRemark} handleChange={handleChange} />
      <div className="AcceptButtons-ContainerParent" style={{ width: '100%' }}>
        <div className="AcceptButtons-Container">
          <AcceptButtons disabled={actionDisable} background={actionDisable ? "#F9CACB" : '#E42125'} color={actionDisable ? '#E42125' : '#FFFFFF'} data-testid="save-button" onClick={filterToggle}>Action</AcceptButtons>
        </div>
      </div>
      {open && (<FilterPopUp open={open} backgroundColor={'#8F949B80'} filterToggle={filterToggle} >
        <Actions caseType="reinvestigatecases" subProduct={subProduct} />
      </FilterPopUp>)}
      {showLoader && <Loader />}
      <ErrorAlert
        modal={modal}
        toggle={toggle}
        modalTitle={modalTitle}
        modalBody={modalBody}
      />
      {showSuccessMessage && (
        <SuccessMessage message="Records Updated Successfully!" delay={3000} />
      )}
    </div>
  )
}

InvestigatorInvestigation.propTypes = {
  redirectPath: propTypes.func,
}

const mapStateToProps = createStructuredSelector({
});

export function mapDispatchToProps(dispatch) {
  return {
    redirectPath: (path) => dispatch(push(path)),
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(withConnect, memo,)(InvestigatorInvestigation);