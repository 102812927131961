import produce from "immer";
import { DEFAULT_ACTION, FORMDATA, SETFORMDATA } from "./constants";

export const initialState = {
  formData: []
};

const assignedBlocksAllottedCasesReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action?.type) {
      case DEFAULT_ACTION:
        break;
      case FORMDATA:
        draft.formData = action?.payload;
        break;
      case SETFORMDATA:
        draft?.formData?.forEach((result, key) => {
          if (result?.block_parameters_id === action?.payload?.block_parameters_id) {
            draft.formData[key].value = action?.payload?.value;
          }
        })
        break;
    }
  });

export default assignedBlocksAllottedCasesReducer;
