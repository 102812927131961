import React, { memo, useState } from 'react'
import { Accordion, AccordionBody, AccordionHeader, AccordionItem,} from 'reactstrap';
import "../cardtab.css";

export const NewBlocksAssigned = memo((props) => {
    const [open, setOpen] = useState('1');
    const toggle = () => {
        setOpen('1');
    };
    return (
        <div className='assignedInvestigators NewBlocksAssigned' style={{ width: '100%' }}>
            <Accordion data-testid="NewBlocksAssigned1" open={open} toggle={toggle}>
                <AccordionItem>
                    <AccordionHeader targetId="1">Assigned Blocks - {props.assigned_blocks && props.assigned_blocks.count}</AccordionHeader>
                    <AccordionBody accordionId="1" className='new-assigned-block'>
                        <div className='row'>
                            {props.assigned_blocks && props.assigned_blocks.blocks && props.assigned_blocks.blocks.map((item) => {
                                return (
                                    <div className='col-4' style={{marginBottom:"10px"}} key={item.renamed_block_name}>
                                        <button className='NewBlocksAssignedButton'>{item.renamed_block_name} </button>
                                    </div>
                                )
                            })}
                        </div>
                    </AccordionBody>
                </AccordionItem>
            </Accordion>
        </div>
    )
})

NewBlocksAssigned.propTypes = {}

export default NewBlocksAssigned;