import React, { useEffect ,useState,useContext} from 'react';
import { ApiDispatchContext } from "../../../context/ApiContext";
import ErrorAlert from "../../../components/ErrorAlert";
import { getUrlParameter } from "../../../constants/commonFunction";
import UploadedFileView from '../../../components/UploadedFileView';
export const Proposal = props => {
  const [proposal, setProposal] = useState("");
  const [documents, setDocuments] = useState("");
  const [loaddata, setloaddata] = useState(false);
  const api = useContext(ApiDispatchContext);
  const [modal, setModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalBody, setModalBody] = useState("");
  const toggle = () => setModal(!modal);
  let casid = Number(getUrlParameter('case'));

   useEffect(() => {
    if(casid){
      let payload ={
        "caseId": casid,
        "segmentType": "proposal"
      }
      api.getCaseBPRSegmentDetails(payload).then(data => {
        if (data.data.success && (data.data.data.data.length || data.data.data.documents.length)) {
          setProposal(data.data.data.data);
          setDocuments(data.data.data.documents)
          setloaddata(true);
        }
      }).catch(err => {
        setModalTitle("Failed to load Proposal Data.");
        if(err && err.response && err.response.data && err.response.data.message){
          setModalBody(err.response.data.message);
        } else {
          setModalBody('Please try after sometime.');
        }
        toggle();
      })
    }
  }, [casid]);
  
  return (
    <div>
      {loaddata ?
        <div className='policy'>
          <div className='map-data'>
            {proposal?.length > 0 && proposal?.map((item, i) => (
              <div className='map-data-breakDIV' key={`openCaseProposal-${i}`}>
                <div className='map-data-text1'>{item.label}</div>
                <div className='map-data-text2'>{item.value ? item.value : '-'}</div>
              </div>
            ))
            }
          </div>
          {documents.length > 0 && (
            <>
              <hr />
              <span className='document-title'>Documents</span>
              <div className="docs-container">
                {Array.isArray(documents) && documents.length > 0 && 
                  <UploadedFileView download={false} border="1px solid #0984E3" documentList={documents}  />
                }
              </div>
            </>
          )}
        </div>
       : null}
      <ErrorAlert
        modal={modal}
        toggle={toggle}
        modalTitle={modalTitle}
        modalBody={modalBody}
      />
    </div>
  )
}

Proposal.propTypes = {}

export default Proposal;