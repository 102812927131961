import { createSelector } from 'reselect';
import { initialState } from './reducer';

const selectDashboard = state => state.dashBoardReducer || initialState;
const makeSelectUsername = () =>
  createSelector(
    selectDashboard,
    dashState => dashState.username,
  );

const makeSelectMobileNumber = () =>
  createSelector(
    selectDashboard,
    dashState => dashState.mobileNumber,
  );

const makeSelectMenu = () =>
  createSelector(
    selectDashboard,
    dashState => dashState.menu,
  )

const makeEmployeecount = () =>
  createSelector(
    selectDashboard,
    dashState => dashState.employeeCountdata,
  );
const makeAgeingData = () =>
  createSelector(
    selectDashboard,
    dashState => dashState.ageingData,
);

export { selectDashboard, makeSelectUsername, makeSelectMobileNumber, makeSelectMenu, makeEmployeecount, makeAgeingData };
