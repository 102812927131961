import React, { useState, useEffect, useContext, memo } from 'react';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { getUrlParameter } from '../../constants/commonFunction';
import { ApiDispatchContext } from '../../context/ApiContext';
import path from '../../constants/pathConstants';
import './index.css';
import { compose } from 'redux';
import ErrorAlert from '../ErrorAlert'
import Loader from '../Loader';
import propTypes from 'prop-types';
import CustomFileViewer from '../CustomFileViewer';
import { getEmployeeRole } from '../../containers/App/selectors';
import { useNavigate } from 'react-router-dom';

export function Actions({caseType, subProduct,  employeeRole}) {
  const [viewFileModal, setViewFileModal] = useState(false);
  const [docs, setDocs] = useState(false);
  const api = useContext(ApiDispatchContext);
  let caseid = Number(getUrlParameter('case'));
  const [modal, setModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalBody, setModalBody] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const [filePath, setFilePath] = useState("");
  const [submitdisable,setSubmitDisable] = useState(false);
  const toggle = () => setModal(!modal);

  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    api.getInvoiceList({ "caseId": caseid,"document_type":"actionInvoice" }).then(data => {
      if (data.data.success) {
        let temp = data.data.data
        if(temp.previousInvoices.length > 0 || temp.newInvoices.length > 0){
          setDocs(true)
        }
      }
    });
    let payload = {
      "caseId": caseid
    }
    api.getGradeCase(payload).then(data => {
      if(!(data.data.data.fact_finding_flag && data.data.data.investigation_report_flag && data.data.data.invoice_flag)){
        setSubmitDisable(true);
      }
    })
  }, []);

  const actionsUrl = [
    { name: "Generate Fact Finding Report", url: path.GENERATE_FACT_FINDING_REPORT, show: true },
    { name: "Upload Investigation Report", url: path.UPLOAD_INVESTIGATION_REPORT, show: true },
    { name: "Upload Invoice", url: docs ? path.UPLOAD_INVOICE_DETAILS : path.UPLOAD_INVOICE, show: true },
    { name: "Submit Final", url: path.SUBMIT_FINAL, show: employeeRole === "vendor" ? true : false}
  ]

  const checkSubmitDisable = (currentPage) => {
    if(currentPage === "/submitfinal" && submitdisable){     // disable submit button on action popup
      return false;
    } else {
      navigateToAction(currentPage);
    }
  }

  const checkDisableClass = (url) => {
    if(url === "/submitfinal" && submitdisable){
      return 'actions-action disabled';
    } else {
      return 'actions-action';
    }
  } 

  const navigateToAction = (navigateToAction) => {
    if(navigateToAction !== "/generate-fact-finding-report"){
      navigate(navigateToAction + '?'+ btoa('case=' + caseid + '&&casetype=' + caseType + '&subProduct=' + subProduct));
    } else {
      setShowLoader(true);
      api.factFindingReport({ caseId: caseid }).then(data => {
        if (data.data && data.data.success && data.data.data) {
          setFilePath(data.data.data)
          setShowLoader(false);
          if(window.innerWidth > 767) {
            window.open(data.data.data + "#toolbar=0", '_blank');
          }  else {
            setViewFileModal(true);
          }
        } else {
          setModalTitle("Error");
          if(data.data.error && data.data.message){
            setModalBody(data.data.message)
          } else{
            setModalBody('Please try after sometime');
          }
          toggle();
          setShowLoader(false);
        }
      }).catch(err => {
        setModalTitle("Error");
        if (err && err.response && err.response.data && err.response.data.message) {
          setModalBody(err.response.data.message);
        } else {
          setModalBody('Please try after sometime.');
        }
        toggle();
        setShowLoader(false);
      })
    }
  }

  return (
    <>
      <div className='actions'>
        <span className='actions-title'>Actions</span>
        {actionsUrl && actionsUrl.length > 0 && actionsUrl.map((ele, index) => (
          ele.show && <span key={index} data-testid='actions-action' className={checkDisableClass(ele.url)} onClick={() => checkSubmitDisable(ele.url)}>{ele.name}</span>
        ))}
      </div>
      {showLoader && <Loader />}
      <ErrorAlert
        modal={modal}
        toggle={toggle}
        modalTitle={modalTitle}
        modalBody={modalBody}
      />
      <CustomFileViewer download={false} DownloadDocs={null} fileType={"pdf"} filePath={filePath} setViewFileModal={setViewFileModal} viewFileModal={viewFileModal} />
    </>
  )
}

Actions.propTypes = {
  employeeRole: propTypes.string
};

const mapStateToProps = createStructuredSelector({
  employeeRole: getEmployeeRole()
});

export function mapDispatchToProps(dispatch) {
  return {
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(withConnect, memo,)(Actions);