import React, { memo } from 'react';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import './index.css';
import { compose } from 'redux';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Barchart from '../../../components/bar-chart/index.js';
import { makeAgeingData } from '../selectors';
import { getEmployeeRole } from '../../../containers/App/selectors';
const key = 'Mobile';
export function AssesmentReport({employeeRole,ageingData}) {
  
  let labelData = [
    { name: 'Within TAT', color: '#22A6B3' },
    { name: 'Outside TAT', color: '#9980FA' },
  ];

  return (
    <div className='main2-employee assesmentreport-margin'>
      <div className='main-assesment'>
        <div className='assesment-heading'>Assessment Report</div>
        <div className='assesment-maindiv'>
          <div className='assesment-barchart'>
            <Barchart ageingData={ageingData}/>
          </div>
          <div className='employee-split2 bullet-assesment'>
            {labelData.map((item, i) => {
              return (
                <div className='employee-dispdata' key={i}>
                  <div className='employee-dispdata2' style={{ background: item.color }}></div>
                  <div className='employee-dispdata3 bulletname-assesment'>{item.value} {item.name}</div>
                </div>
              )
            })}
          </div>
        </div>
        <div className='employee-viewbtn'>
        </div>
      </div>
    </div>
  )
}
AssesmentReport.propTypes = {
};

const mapStateToProps = createStructuredSelector({
  employeeRole: getEmployeeRole(),
  ageingData: makeAgeingData()
});

export function mapDispatchToProps(dispatch) {
  return {
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(withConnect, memo,)(AssesmentReport);