import { createSelector } from "reselect";
import { initialState } from "./reducer";

const selectReinvestigateCasesDetailsDomain = state =>
  state.reinvestigateCasesDetails || initialState;

const makeSelectReinvestigateCasesDetails = () =>
  createSelector(
    selectReinvestigateCasesDetailsDomain,
    substate => substate
  );

export default makeSelectReinvestigateCasesDetails;
export { selectReinvestigateCasesDetailsDomain };
