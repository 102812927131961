import React from 'react';
import "./index.css";
import { imageBaseUrl } from '../../utils/constants';

const Loader = () => {
  return (
    <div className='loaderWrapper'>
        <img src={`${imageBaseUrl}/loaderVideo.gif`} alt="loader" className='loaderGif' />
    </div>
  )
}

export default Loader;