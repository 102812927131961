import React, { memo } from "react";
import CommonCaseCard from "../../components/CommonCaseCard/CommonCaseCard";

const CaseCard = memo(({ selectCards }) => {
  return (
    <div>
      {selectCards &&
        selectCards.map((item, index) => (
          <CommonCaseCard
            key={`allottedCaseCard-${index}`}
            cardDetail={item}
            btn={false}
            cardType={"Allotted"}
          />
        ))}
    </div>
  );
});

CaseCard.propTypes = {};

export default CaseCard;
