import React from 'react';
import CommonNavbar from '../CommonNavbar';
import Message from '../Message';

function ComingSoonPage() {
  return (
    <div>
        <CommonNavbar title={"Coming soon"} search={false} download={false} />
        <Message showBackIcon={false} />
    </div>
  )
}

export default ComingSoonPage;