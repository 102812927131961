import React, { memo, useState } from 'react';
import DatePicker from 'react-datepicker';

const Hours = memo(({ config, saveFormData, formcolor, formName, testcase, handleInput, showLabel,ifRequired, isDisabled }) => {
  const dateString = '2023-04-24';
  const timeString = config?.parameter_value;
  const dateTimeString = dateString + 'T' + timeString;
  const date = new Date(dateTimeString);
  const [startDate, setStartDate] = useState(config?.parameter_value ? date : '');
  
  const setDate = (param) => {
    const date = new Date(param);
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');
    const formattedTime = `${hours}:${minutes}:${seconds}`;
    handleInput(formattedTime)
    setStartDate(date);
  }
  const handleKeyDown = e => {
    e.preventDefault(); // Prevent typing in the input field
  };

  const handleChangeRaw = (event) => {
    event.preventDefault();
  };

  return (
    <div className='textinput-genricpet-main'>
      {showLabel && (<label className='Genericforms-text'>{config?.renamed_block_parameter}{ifRequired && <sup>*</sup>}</label>)}
      <div>
        <DatePicker
          disabled={isDisabled}
          autoComplete="off"
          className={'generic-status-update-input ' + formName}
          selected={startDate}
          onKeyDown={handleKeyDown}
          onChange={(date) => { setDate(date) }}
          showTimeSelect
          showTimeSelectOnly={testcase !== undefined ? testcase : true}
          timeIntervals={15}
          dropdownMode="select"
          onChangeRaw={handleChangeRaw}
          timeCaption="Time"
          dateFormat="hh:mm aa"
          placeholderText='Please select Time'
          data-testid="genericinput3"
          style={{ backgroundColor: formcolor ? formcolor : 'rgba(108, 92, 231, 0)',border:"none" }}
        />
      </div>
    </div>
  );
})

Hours.propTypes = {};

export default Hours;