
import React, { memo } from 'react';
import Dropdown from './Dropdown';

const Hours = memo(({ config, saveFormData, formcolor, formName, testcase, ifRequired, handleGenFormInput }) => {

  return (
    <span datatestid="hours" className="time"><Dropdown style={{border:"none"}} config={config} saveFormData={saveFormData} formcolor={formcolor} ifRequired={ifRequired} handleGenFormInput={(value) => handleGenFormInput(value)} /></span>
  );
})

Hours.propTypes = {};

export default Hours;