import { DATA, DEFAULT_ACTION } from "./constants";

export function defaultAction() {
  return {
    type: DEFAULT_ACTION
  };
}

export function updateData(payload) {
  return {
    type: DATA,
    payload:payload
  };
}
