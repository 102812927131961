import React, { memo, useContext, useState } from 'react';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import "./index.css";
import CustomFileViewer from '../CustomFileViewer';
import Loader from '../Loader';
import { ApiDispatchContext } from '../../context/ApiContext';
import ErrorAlert from '../ErrorAlert';
import { createStructuredSelector } from 'reselect';
import { getVendorEmployeeID } from '../../containers/App/selectors';
import path from "../../constants/pathConstants";
import { useNavigate } from 'react-router-dom';
import { compose } from 'redux';
import { getUrlParameter } from '../../constants/commonFunction';
import { imageBaseUrl } from '../../utils/constants';

const bucketName = process.env.REACT_APP_BUCKET_NAME;

export const UploadedFileView = (props) => {
  const [viewFileModal, setViewFileModal] = useState(false);
  const [file, setFile] = useState();
  const [fileName, setFileName] = useState();
  const [fileType, setFileType] = useState();
  const [filePath, setFilePath] = useState();
  const [showLoader, setShowLoader] = useState(false);
  const [modal, setModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalBody, setModalBody] = useState("");
  const api = useContext(ApiDispatchContext);
  const caseid = Number(getUrlParameter('case'));

  const navigate = useNavigate();

  const toggle = () => setModal(!modal);
  const arrayBufferToBase64 = (buffer) => {
    var binary = '';
    var bytes = new Uint8Array(buffer);
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  }

  
  const ViewDocs = (file, filename) => {
    setShowLoader(true);
    setFile(file);
    setFileName(filename);
    let payload = {
      "fileName": filename ? filename : ""
    }
    api.viewDocuments(payload).then(data => {
      if (data.data && data.data.success) {
        setShowLoader(false);
        let lastDotIndex = filename.lastIndexOf(".");
        const fileExt = filename.substring(lastDotIndex + 1);
        setFileType(fileExt);
        setFilePath(data.data.data[0]);
        setViewFileModal(true);
      } else {
        setModalTitle("Error");
        if (data.data.error && data.data.message) {
          setModalBody(data.data.message)
        } else {
          setModalBody('Please try after sometime');
        }
        toggle();
        setShowLoader(false);
      }
    }).catch(err => {
      setModalTitle("Error");
      if (err && err.response && err.response.data && err.response.data.message) {
        setModalBody(err.response.data.message);
      } else {
        setModalBody('Please try after sometime.');
      }
      toggle();
      setShowLoader(false);
    })
  }
  const DownloadDocs = () => {
    setShowLoader(true);
    let payload = {
      "document_path": file ? `gs://${bucketName}/${fileName}` : ""
    }
    api.downloadDocuments(payload).then(data => {
      if (data.data.success && data.data.data && data.data.data.data && data.data.data.data.data) {
        setShowLoader(false);
        var chlink = document.createElement('a');
        chlink.download = fileName;
        let base64 = arrayBufferToBase64(data.data.data.data.data);
        chlink.href = 'data:application/octet-stream;base64,' + base64;
        document.body.appendChild(chlink);
        chlink.click();
        document.body.removeChild(chlink);
      } else {
        setModalTitle("Error");
        if (data.data.error && data.data.message) {
          setModalBody(data.data.message)
        } else {
          setModalBody('Please try after sometime');
        }
        toggle();
        setShowLoader(false);
      }
    }).catch(err => {
      setModalTitle("Error");
      if (err && err.response && err.response.data && err.response.data.message) {
        setModalBody(err.response.data.message);
      } else {
        setModalBody('Please try after sometime.');
      }
      toggle();
      setShowLoader(false);
    })
  }
  const previewInvoiceForm = (ele) => {
    const caseType = props?.casetype;
    navigate(path.PREVIEW_UPLOADED_INVOICES+'?'+btoa('invoiceId='+ ele+'&case='+caseid+'&caseType='+caseType));
  }
  return (
    <>
      {props?.documentList.map((ele, i) => {
        return (
          <button title={ele?.document_name ? ele?.document_name : `Invoice-${ele}`} style={{ border: props?.border }} key={i} className={ele?.upload_date?"assign-docs-date-time":"assign-docs"} data-testid="UploadInvoiceFormBottomfilter2" onClick={() => { props?.pageName === "UploadInvoiceDetails" ? previewInvoiceForm(ele) :  ViewDocs(ele?.document_path, ele?.document_name); }}>
            {props?.deleteOwnFileCheck ? 
              (props?.onClose && ele?.vendor_employee_id === props?.employeeID) && <div className='rejected-docs-remove'><img src={props?.showCloseDocs ? `${imageBaseUrl}/trash.svg` : `${imageBaseUrl}/cross.svg`} data-testid="UploadInvoiceFormBottomfilter3" onClick={(e) => props?.onClose(e, i, props?.parentIndex ? props?.parentIndex : null)} /></div>
              : props?.onClose && <div className='rejected-docs-remove'><img src={props?.showCloseDocs ? `${imageBaseUrl}/trash.svg` : `${imageBaseUrl}/cross.svg`} data-testid="UploadInvoiceFormBottomfilter4" onClick={(e) => props?.onClose(e, i, props?.parentIndex ? props?.parentIndex : null)} /></div>
            }
            {props?.deleteOwnFileCheck && 
              (props?.onClose && props?.employeeID) && <div className='rejected-docs-remove'><img src={props?.showCloseDocs ? `${imageBaseUrl}/trash.svg` : `${imageBaseUrl}/cross.svg`} data-testid="UploadInvoiceFormBottomfilter3" onClick={(e) => props?.onClose(e, i, props?.parentIndex ? props?.parentIndex : null)} /></div>
            }
            {(props?.pageName === "UploadInvoiceDetails" || window.location.href.includes("rejectedInvoices-details")) ? <>
            <img src={`${imageBaseUrl}/invoice.svg`} alt="invoice" />
            <div className="assign-docs-right">
              {ele?.document_name ?  <span className='assign-docs-name'>{ele?.document_name?.length < 11 ? ele?.document_name : ele?.document_name.slice(0, 10) + "..."}</span> : 
                <span className='assign-docs-name'>Invoice-{ele}</span>
              }
            </div>
            </> : <>
            <div className='assign-docs-container'>
            <div className='assign-docs-main'>
            <img src={`${imageBaseUrl}/file.png`} alt="file" />
            <div className="assign-docs-right">
                <span className='assign-docs-name'>{ele?.document_name?.length < 11 ? ele?.document_name : ele?.document_name?.slice(0, 10) + "..." + ele?.document_name?.split(".").pop()}</span>
                <span className='assign-docs-size'>{ele?.document_size}</span>
            </div>
            </div>
           {ele?.upload_date && (<div style={{ borderTop: props?.border }} className='assign-docs-date'>{ele?.upload_date}</div>)}
            </div>
            </>}
          </button>
        )
      })}
      <CustomFileViewer download={props?.download} DownloadDocs={DownloadDocs} fileType={fileType} filePath={filePath} setViewFileModal={setViewFileModal} viewFileModal={viewFileModal} />
      {showLoader && <Loader />}
      <ErrorAlert
        modal={modal}
        toggle={toggle}
        modalTitle={modalTitle}
        modalBody={modalBody}
      />
    </>
  )
}

UploadedFileView.propTypes = {
  employeeID: PropTypes.number
};

const mapStateToProps = createStructuredSelector({
  employeeID: getVendorEmployeeID()
});

export function mapDispatchToProps(dispatch) {
  return {
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(withConnect, memo)(UploadedFileView);
