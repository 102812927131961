import React, { useState, memo } from 'react';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose } from 'redux';
import path from '../../constants/pathConstants';
import './index.css';
import ComingSoonPopUp from '../ComingSoonPopUp';
import { useNavigate } from "react-router-dom";
import { imageBaseUrl } from "../../utils/constants";
export function CasesIcons({ filterpopupShow, caseType, downloadReport}) {
    const navigate = useNavigate();
    const [open,setOpen] = useState(false)
    const toggle = ()=>setOpen(!open)
    return (
        <div className='caseicons-main'>
            <img onClick={()=>navigate(path.SEARCH_CASE  + "?" + btoa("caseType="+caseType))} className='caseicons-img1' src={`${imageBaseUrl}/search4.png`} alt="search" />
            <img onClick={filterpopupShow} className='caseicons-img1 caseicons-img2'  src={`${imageBaseUrl}/filter4.png`} alt="filter" />
            {caseType !== "rejectedInvoice" && <img onClick={downloadReport} className='caseicons-img1 caseicons-img3' src={`${imageBaseUrl}/download4.png`} alt="download" />}
            <ComingSoonPopUp open={open} toggle={toggle} />
        </div>
    )
}

const mapStateToProps = createStructuredSelector({
});

export function mapDispatchToProps(dispatch) {
    return {
    };
}

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);

export default compose(withConnect, memo,)(CasesIcons);