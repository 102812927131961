import React from "react";
import Basic from "../containers/AllottedCasesDetailsPage/CardTabs/Basic";
import Endorsement from "../containers/AllottedCasesDetailsPage/CardTabs/Endorsement";
import Investigation  from "../containers/AllottedCasesDetailsPage/CardTabs/Investigation/Investigation";
import Policy from "../containers/AllottedCasesDetailsPage/CardTabs/Policy";
import Proposal from "../containers/AllottedCasesDetailsPage/CardTabs/Proposal";
import Risk from "../containers/AllottedCasesDetailsPage/CardTabs/Risk";
import Dispatch from "../containers/AllottedCasesDetailsPage/CardTabs/Dispatch";
import Claim from "../containers/AllottedCasesDetailsPage/CardTabs/Claim";
import External from "../containers/AllottedCasesDetailsPage/CardTabs/External";

const cardDataVendor = [
    {
      tabId: '1',
      tabTitle: 'Investigation',
      tabContent: <Investigation/>
    },
    {
      tabId: '2',
      tabTitle: 'Basic',
      tabContent: <Basic/>
    },
    {
      tabId: '3',
      tabTitle: 'Policy',
      tabContent: <Policy/>
    },
    {
      tabId: '4',
      tabTitle: 'Claim',
      tabContent: <Claim/>
    },
    {
      tabId: '5',
      tabTitle: 'Proposal',
      tabContent: <Proposal/>
    },
    {
      tabId: '6',
      tabTitle: 'Dispatch',
      tabContent: <Dispatch />
    },
    {
      tabId: '7',
      tabTitle: 'Risk',
      tabContent: <Risk />
    },
    {
      tabId: '8',
      tabTitle: 'Endorsement',
      tabContent: <Endorsement/>
    },
    {
      tabId: '9',
      tabTitle: 'External',
      tabContent: <External />
    }
  ]

  export {cardDataVendor}