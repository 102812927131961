/*
 *
 * SubmitFinalPage reducer
 *
 */
import produce from "immer";
import { DEFAULT_ACTION, DATA } from "./constants";

export const initialState = {};

/* eslint-disable default-case, no-param-reassign */
const submitFinalPageReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case DEFAULT_ACTION:
        break;
      case DATA:
        draft.data = action.payload
        break;
    }
  });

export default submitFinalPageReducer;
