import React, { useRef } from 'react';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import './MyDatePicker.css';
const MyDatePicker = ({startDate,setStartDate,placeholder='From', previousdate = new Date(), nextdate = undefined}) => {
  let calendar = useRef()
  const openDatepicker = () => calendar.setOpen(true);
  const CalendarIcon = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13"
      height="13"
      fill="none"
      viewBox="0 0 13 13"
    >
      <path
        fill="#8F949B"
        d="M10.875 1.5h-.625V.25H9V1.5H4V.25H2.75V1.5h-.625c-.694 0-1.244.563-1.244 1.25L.875 11.5c0 .688.556 1.25 1.25 1.25h8.75c.688 0 1.25-.563 1.25-1.25V2.75c0-.688-.563-1.25-1.25-1.25zm0 10h-8.75V5.25h8.75v6.25zm0-7.5h-8.75V2.75h8.75V4zm-6.25 3.75h-1.25V6.5h1.25v1.25zm2.5 0h-1.25V6.5h1.25v1.25zm2.5 0h-1.25V6.5h1.25v1.25zm-5 2.5h-1.25V9h1.25v1.25zm2.5 0h-1.25V9h1.25v1.25zm2.5 0h-1.25V9h1.25v1.25z"
      ></path>
    </svg>
  );
  return (
    <div className='mydatepicker statusdatepicker' style={{display: 'flex', alignItems: 'center'}}>
      <DatePicker
        autoComplete="off"
        data-testid="mydatepicker"
        placeholderText={placeholder}
        selected={startDate}
        onChange={(date) => {setStartDate(date) }}
        name="startDate"
        dateFormat="dd/MM/yyyy"
        maxDate={placeholder === 'From' ? nextdate : undefined}
        minDate={placeholder === 'To' ? previousdate : new Date()}
        calendarIcon={<CalendarIcon />}
        className={"status-update-input"}
        ref={(c) => calendar = c}
        style={{border:"1px solid #8F949B"}}
        popperPlacement="bottom-end"
      />
      <button onClick={openDatepicker} style={{ marginLeft: '-32px', border: 'none', background: 'none', cursor: 'pointer' }} type="button">
        <div className="calendar-icon">
          <CalendarIcon />
        </div>
      </button>
    </div>
  );
};

export default MyDatePicker;