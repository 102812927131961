import React, { useState, memo } from 'react';
import Icon from '../../../../components/Icon';
import './index.css';
import { imageBaseUrl } from '../../../../utils/constants';

export const DLVerification = props => {
  const [showmore, setshowmore] = useState(false);

  const handleShowmore = () =>{
    setshowmore(true);
  }

  const handleShowless = () =>{
    setshowmore(false);
  }

    let DLDetailsArray = [
        { label: 'Message:', value: props?.External?.detailsOfDrivingLicence ? props?.External?.detailsOfDrivingLicence?.status : '' },
        { label: 'Issue Date:', value: props?.External?.detailsOfDrivingLicence ? props?.External?.detailsOfDrivingLicence?.dateOfIssue : '' },
        { label: 'Expiry Date:', value: props?.External?.detailsOfDrivingLicence ? props?.External?.detailsOfDrivingLicence?.dateOfLastTransaction : '' },
    ]

    return (
        <div className='basic' style={{ width: '100%' }}>
            <div className='map-data'>
                <div className='externalMainView'>
                <div className='externalMainView2'>
                    <div className='externalimg-view1'>
                        <div className='la_id-card-solid-external-img'>
                            <img style={{ width: "20px", height: "20px" }} src={`${imageBaseUrl}/la_id-card-solid.png`} alt="search" />
                        </div>
                        <div className='externalHeader-style'>DL Verification</div>
                    </div>
                    {showmore ?
                        <div>
                            <button data-testid="handleShowless1-view" className='claim-upbuttton-style' onClick={() => { handleShowless(); }}>
                                <img className='claim-upbuttton-img' src={`${imageBaseUrl}/open-claim-up.png`} alt="openclaim" />
                            </button>
                        </div>
                        :
                        <div className='map-data-breakDIV claimbtn-main externalimg-view2' style={{ marginTop: 0 }}>
                            <button data-testid="handleShowAllbtn-view" className='claimview-btn' onClick={() => { handleShowmore(); }}><Icon />  View</button>
                        </div>
                    }
                    </div>
                    {showmore ?
                        <div className='map-data'>
                            {DLDetailsArray.length > 0 && DLDetailsArray.map((item, i) => (
                                <div className='map-data-breakDIV'>
                                    <div className='map-data-text1'>{item.label}</div>
                                    <div className='map-data-text2'>{item.value ? item.value : '-'}</div>
                                </div>
                            ))
                            }
                        </div> : null}
                </div>
            </div>
        </div>
    )
}

DLVerification.propTypes = {}

export default memo(DLVerification);