import { useCallback, useContext, useEffect, useState } from 'react';
import InvoiceDetails from './InvoiceDetails';
import InvoiceStatus from './InvoiceStatus';
import { CommonNavbar } from '../../components/CommonNavbar';
import path from '../../constants/pathConstants';
import { Button } from 'reactstrap';
import { getUrlParameter } from '../../constants/commonFunction';
import "../CasesAndInvoiceTracker/index.css";
import { ApiDispatchContext } from "../../context/ApiContext";
import ErrorAlert from '../../components/ErrorAlert';
import Loader from '../../components/Loader';

const pages = { invoiceDetails: "Invoice Details", invoiceStatus: "Invoice Status" }

const InvoiceDetailsAndStatus = () => {
  const [pagename, setPageName] = useState(pages.invoiceDetails);
  const [invoiceDetailsData, setInvoiceDetailsData] = useState([]);
  const [invoiceStatusData, setInvoiceStatusData] = useState([]);
  const [modal, setModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalBody, setModalBody] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const api = useContext(ApiDispatchContext);
  const caseid = Number(getUrlParameter('case'));
  const toggle = () => setModal(!modal);

  const renderSwitch = () => {
    switch (pagename) {
      case pages?.invoiceDetails:
        return <InvoiceDetails invoiceDetailsData={invoiceDetailsData} currentTab={"invoiceDetails"} />;
      case pages?.invoiceStatus:
        return <InvoiceStatus invoiceStatusData={invoiceStatusData} currentTab={"invoiceStatus"} />;
      default:
        return <InvoiceDetails invoiceDetailsData={invoiceDetailsData} currentTab={"invoiceDetails"} />;
    }
  };

  const getAllInvoiceDetails = useCallback( async (caseid) => {
    const param = {
      "caseId": caseid
  }
    setIsLoading(true);
    await api.getAllInvoiceDetails(param).then(data => {
      if(data?.data?.success){
        setInvoiceDetailsData(data?.data?.data?.invoice_details);
        setInvoiceStatusData(data?.data?.data?.invoice_status);
        setIsLoading(false);
      } else {
        setModalTitle("Error");
        if(data?.data?.error && data?.data?.message){
          setModalBody(data?.data?.message)
        } else{
          setModalBody('Please try after sometime');
        }
        toggle();
        setIsLoading(false);
      }
    }).catch(err=>{
      setModalTitle("Error");
      if (err?.response?.data?.message) {
        setModalBody(err?.response?.data?.message);
      } else {
        setModalBody('Please try after sometime.');
      }
      toggle();
      setIsLoading(false);
    });
  }, [caseid]);

  useEffect(() => {
    getAllInvoiceDetails(caseid);
  }, [caseid]);

  return (
    <div data-testid="container" className="casesAndInvoiceTracker">
    <CommonNavbar title={`Case Number ${caseid}`} search={false} download={false} backButton={path.CASES_AND_INVOICES_TRACKER} navbarname='CasesAndInvoiceTracker' />
    <div className="casesAndInvoiceTracker-container">
      <div className="switch-btn-container">
        {pagename === pages?.invoiceDetails ? <Button className="switch-btn btn-cases">{pages?.invoiceDetails}</Button> : <div className="not-btn" data-testid="container-cases" onClick={() => setPageName(pages?.invoiceDetails)}>{pages?.invoiceDetails}</div>}
        {pagename === pages?.invoiceStatus ? <Button className="switch-btn btn-invoice">{pages?.invoiceStatus}</Button> : <div className="not-btn" data-testid="container-invoices" onClick={() => setPageName(pages?.invoiceStatus)}>{pages?.invoiceStatus}</div>}
      </div>
      {renderSwitch()}
    </div>
    {isLoading && <Loader />}
    <ErrorAlert
        modal={modal}
        toggle={toggle}
        modalTitle={modalTitle}
        modalBody={modalBody}
      />
  </div>
  )
}

export default InvoiceDetailsAndStatus;