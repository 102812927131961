import { createSelector } from "reselect";
import { initialState } from "./reducer";

/**
 * Direct selector to the reinvestigateCases state domain
 */

const selectReinvestigateCasesDomain = state =>
  state.reinvestigateCases || initialState;

/**
 * Other specific selectors
 */

/**
 * Default selector used by ReinvestigateCases
 */

const makeSelectReinvestigateCases = () =>
  createSelector(
    selectReinvestigateCasesDomain,
    substate => substate
  );

  const makeSelectCardCases = () =>
  createSelector(
    selectReinvestigateCasesDomain,
    substate => substate.cardData
  );
export default makeSelectReinvestigateCases;
export { selectReinvestigateCasesDomain,makeSelectCardCases };
