import React,{useState} from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import './cardtab.css';
export const CardTabs = (props) => {
  const [activeTab, setActiveTab] = useState('1');
  const toggle = tab => {
    if (activeTab !== tab) setActiveTab(tab);
  }
  return (
    <div className='cardTabs'>
    <Nav tabs>
      {props.tabData.map(tab => (
        <NavItem key={tab.tabId}>
          <NavLink
            className={activeTab === tab.tabId ? 'active nav-bar-textstyle' : 'nav-bar-textstyle'}
            onClick={() => { toggle(tab.tabId); }}
          >
            {tab.tabTitle}
          </NavLink>
        </NavItem>
      ))}
    </Nav>
    <TabContent activeTab={activeTab}>
      {props.tabData.map(tab => (
        <TabPane key={tab.tabId} tabId={tab.tabId}>
          {activeTab === tab.tabId && tab.tabContent}
        </TabPane>
      ))}
    </TabContent>
  </div>
  )
}

CardTabs.propTypes = {}

export default CardTabs;