import React from 'react';
import { useNavigate } from 'react-router-dom';
import './message.css'
import { imageBaseUrl } from '../../utils/constants';

function Message(props) {
  const navigate = useNavigate();
  const backbutton = () => navigate(-1);
  return (
    <div data-testid="MessagePage" className='message'>
        {props.showBackIcon !== false && <img src={`${imageBaseUrl}/BackArrow.svg`} alt="back" onClick={backbutton} className='backbtn'/>}
        <img src={`${imageBaseUrl}/message.gif`} alt="message" />
        <div className='text-wrapper'>
          <h1 className='message-title'>Coming Soon...</h1>
          <p className='message-desc'>Stay Connected, Stay Updated</p>
        </div>
    </div>
  )
}

export default Message