import React, { useState, memo } from 'react';
import Icon from '../../../../components/Icon';
import { imageBaseUrl } from '../../../../utils/constants';

export const PANVerification = props => {
    const [showmore, setshowmore] = useState(false);

    const handleShowmore = () => {
        setshowmore(true);
    }

    const handleShowless = () => {
        setshowmore(false);
    }

    let panDetailsArray = [
        { label: 'Name:', value: props?.External.name ? props?.External?.name : '' },
        { label: 'First Name:', value: props?.External?.firstName ? props?.External?.firstName : '' },
        { label: 'Middle Name:', value: props?.External?.middleName ? props?.External?.middleName : '' },
        { label: 'last Name:', value: props?.External?.lastName ? props?.External?.lastName : '' },
        { label: 'Title:', value: props?.External?.title ? props?.External?.title : '' },
        { label: 'Type Of Holder:', value: props?.External?.typeOfHolder ? props?.External?.typeOfHolder : '' },
        { label: 'Is Individual:', value: props?.External?.isIndividual ? props?.External?.isIndividual.toString() : '' },
        { label: 'Is Valid', value: props?.External?.isValid ? props?.External?.isValid.toString() : '' },
        { label: 'Number:', value: props?.External?.number ? props?.External?.number : '' },
        { label: 'PAN Status:', value: props?.External?.panStatus ? props?.External?.panStatus : '' },
        { label: 'PAN Status Code:', value: props?.External?.panStatusCode ? props?.External?.panStatusCode : '' },
        { label: 'Aadhaar Seeding Status:', value: props?.External?.aadhaarSeedingStatus ? props?.External?.aadhaarSeedingStatus : '' },
        { label: 'Aadhaar Seeding Status Code:', value: props?.External?.aadhaarSeedingStatusCode ? props?.External?.aadhaarSeedingStatusCode : '' },
        { label: 'Last Updated On:', value: props?.External?.lastUpdatedOn ? props?.External?.lastUpdatedOn : '' },
    ]

    return (
        <div className='basic' style={{ width: '100%' }}>
            <div className='map-data'>
                <div className='externalMainView'>
                    <div className='externalMainView2'>
                        <div className='externalimg-view1'>
                            <div className='la_id-card-solid-external-img'>
                                <img style={{ width: "20px", height: "20px" }} src={`${imageBaseUrl}/la_id-card-solid.png`} alt="search" />
                            </div>
                            <div className='externalHeader-style'>PAN Verification</div>
                        </div>
                        {showmore ?
                            <div>
                                <button data-testid="handleShowless1-view" className='claim-upbuttton-style' onClick={() => { handleShowless(); }}>
                                    <img className='claim-upbuttton-img' src={`${imageBaseUrl}/open-claim-up.png`} alt="openclaim" />
                                </button>
                            </div>
                            :
                            <div className='map-data-breakDIV claimbtn-main externalimg-view2' style={{ marginTop: 0 }}>
                                <button data-testid="handleShowAllbtn-view" className='claimview-btn' onClick={() => { handleShowmore(); }}><Icon />  View</button>
                            </div>
                        }
                    </div>
                    {showmore ?
                        <div className='map-data'>
                            {panDetailsArray.length > 0 && panDetailsArray.map((item, i) => (
                                <div className='map-data-breakDIV'>
                                    <div className='map-data-text1'>{item.label}</div>
                                    <div className='map-data-text2'>{item.value ? item.value : '-'}</div>
                                </div>
                            ))
                            }
                        </div> : null}
                    {showmore ?
                        <div style={{ float: 'right' }}>
                            <button data-testid="handleShowless2-view" className='claim-upbuttton-style' onClick={() => { handleShowless(); }}>
                                <img className='claim-upbuttton-img' src={`${imageBaseUrl}/open-claim-up.png`} alt="openclaim" />
                            </button>
                        </div> : null}
                </div>
            </div>
        </div>
    )
}

PANVerification.propTypes = {}

export default memo(PANVerification);