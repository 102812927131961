import React, { useEffect ,useState,useContext} from 'react'
import { ApiDispatchContext } from "../../../context/ApiContext";
import ErrorAlert from "../../../components/ErrorAlert";
import { getUrlParameter } from "../../../constants/commonFunction";
import { imageBaseUrl } from '../../../utils/constants';
export const Risk = props => {
  const [risk, setRisk] = useState("");
  const api = useContext(ApiDispatchContext);
  const [modal, setModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [loaddata, setloaddata] = useState(false);
  const [modalBody, setModalBody] = useState("");
  const toggle = () => setModal(!modal);
  let casid = Number(getUrlParameter('case'));

   useEffect(() => {
    if(casid){
      let payload ={
        "caseId": casid,
        "segmentType": "risk"
      }
      api.getCaseBPRSegmentDetails(payload).then(data => {
        if (data.data && data.data.success && data.data.data.length) {
          setRisk(data.data.data);
          setloaddata(true);
        }
      }).catch(err => {
        setModalTitle("Failed to load Risk Data.");
        if(err && err.response && err.response.data && err.response.data.message){
          setModalBody(err.response.data.message);
        } else {
          setModalBody('Please try after sometime');
        }
        toggle();
      })
    }
  }, [casid]);

  return (
    <div>
      {loaddata ?
        <div className='map-data'>
          {risk?.length > 0 && risk.map(item => (
            <div className='map-data-breakDIV' key={item?.label}>
              <div className='map-data-text1 riskgreentick-mainstyle'>{item?.label}<div>{item?.green ? <img className='riskgreentick-style' src={`${imageBaseUrl}/tick1.png`} alt="tick" /> : null}</div></div>
              <div className='map-data-text2' style={{ color: item?.wrong ? '#E94D51' : '#3A3A3A' }}>{item?.value ? item?.value:'-'}</div>
            </div>
          ))
          }
        </div>
        : null}
      <ErrorAlert
        modal={modal}
        toggle={toggle}
        modalTitle={modalTitle}
        modalBody={modalBody}
      />
    </div>
  )
}

Risk.propTypes = {}

export default Risk;