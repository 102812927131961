import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13"
      height="13"
      fill="none"
      viewBox="0 0 13 13"
    >
      <g fill="#fff" clipPath="url(#clip0_1_7622)">
        <path d="M6.125 8.166a2.04 2.04 0 100-4.082 2.04 2.04 0 000 4.082z"></path>
        <path d="M11.876 4.808c-.792-1.289-2.592-3.451-5.751-3.451-3.16 0-4.96 2.162-5.75 3.451a2.504 2.504 0 000 2.634c.79 1.289 2.59 3.451 5.75 3.451s4.96-2.162 5.75-3.451a2.504 2.504 0 000-2.634zM6.125 9.186a3.061 3.061 0 110-6.122 3.061 3.061 0 010 6.122z"></path>
      </g>
      <defs>
        <clipPath id="clip0_1_7622">
          <path fill="#fff" d="M0 0H12.25V12.25H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default Icon;