import React from 'react';
import './index.css';
import UploadedFileView from "../../../components/UploadedFileView";
import { imageBaseUrl } from '../../../utils/constants';

export function UploadInvoiceFormBottom(props) {
    return (
        <div className='UploadInvoiceFormBottom'>
            <hr />
            <div className="assignBlock-container">
                <div className="assignBlockdocs-container">
                {props.headerNotRequired ? null :<p className='UploadInvoiceFormBottom-title'>Uploaded Invoices</p>}
                {props.showUploadBtn && (
                    <div className="upload-btn" data-testid="UploadInvoiceFormBottomfilter" onClick={props.filterToggle}>
                        <img src={`${imageBaseUrl}/uploadIcon.png`} alt="uploadIcon" />
                        <span>Upload Invoice</span>
                    </div>
                )}
                </div>
                <div className='documentListContainer'>
                    {props.docs && props.docs.documents && props.docs.documents.length > 0 && 
                        <UploadedFileView download={false} border={props.border} documentList={props.docs.documents} onClose={props.onClose} />
                    }
                </div>
            </div>
        </div>
    )
}

export default UploadInvoiceFormBottom;