import React, { memo, useEffect, useState, useContext, useCallback } from 'react'
import EmployeeMasterCard from '../EmployeeMasterCard'
import EmploymentMasterNavbar from '../EmploymentMasterNavbar'
import FilterButton from '../FilterButton'
import { connect } from "react-redux";
import { compose } from "redux";
import { ApiDispatchContext } from '../../../context/ApiContext';
import "react-datepicker/dist/react-datepicker.css";
import { push } from 'connected-react-router';
import path from '../../../constants/pathConstants'
import './EmpMasterChangeStatus.css'
import EmpMasterButton from '../EmpMasterButton';
import MyDatePicker from '../../../components/MyDatePicker';
import ErrorAlert from "../../../components/ErrorAlert";
import { getUrlParameter } from '../../../constants/commonFunction';
import { useNavigate } from "react-router-dom";
import { decryptData } from '../../../utils/crypto';
import { getEmpInfo } from '../../../utils/getEmpInfo';

export function EmpMasterChangeStatus({ redirectPath }) {
  const [filterEmp, setFilterEmp] = useState({});
  const [showCalender, setShowCalender] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(true);
  const [startDate, setStartDate] = useState();
  const [tillDate, settillDate] = useState();
  const [status, setStatus] = useState("");
  const api = useContext(ApiDispatchContext);
  const [modal, setModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalBody, setModalBody] = useState("");
  const [employee, setEmployee] = useState({})
  const toggle = () => setModal(!modal);
  let empId = getUrlParameter('empIds').split(',').map(Number);
  const [filterBtn, setFilterBtn] = useState([{ text: "Active", active: false },
  { text: "Inactive", active: false },
  { text: "On Leave", active: false }])

  const navigate = useNavigate();

  useEffect(() => {
    let emp = {
      "filters": {},
      "page": 0,
      "pageSize": 0
    }
    api.employeeDetails(emp).then(data => {
      if (data?.data && data?.data?.success) {
        let tempdata = decryptData(data?.data?.data)
        setEmployee(tempdata);
        const filteredEmployeeData = tempdata?.vendor_employees.filter(emp => {
          return empId.includes(emp?.vendor_employee_id);
        });
        const groupedEmpInfo = getEmpInfo({
          ...tempdata,
          "vendor_employees": filteredEmployeeData
        });
        setFilterEmp(groupedEmpInfo);
      } else {
        setModalTitle("Error");
        if (data?.data?.error && data?.data?.message) {
          setModalBody(data?.data?.message)
        }
        else {
          setModalBody('Please try after sometime');
        }
        toggle();
      }
    }).catch(err => {
      setModalTitle("Error");
      if(err?.response?.data?.message){
        setModalBody(err?.response?.data?.message);
      } else {
        setModalBody('Please try after sometime');
      }    
      toggle();
    })
  }, [])

  useEffect(() => {
    if (filterBtn[0].active == true || filterBtn[1].active == true || filterBtn[2].active == true) {
      setDisableSubmit(false)
    }
  }, [filterBtn])

  const onFilterClick = useCallback((i) => {
    if (i == 2) {
      setShowCalender(true)
    }
    else {
      setShowCalender(false)
    }
    let modifiedtabdata = [];
    Object.assign(modifiedtabdata, filterBtn);
    modifiedtabdata.forEach((tab, index) => {
      if (i !== index) {
        tab.active = false;
      } else {
        tab.active = true;
      }
    });
    setFilterBtn(modifiedtabdata)
    if (i == 0) {
      setStatus("active")
    } else if (i == 1) {
      setStatus("inactive")
    } else {
      setStatus("onleave")
    }
  }, [filterBtn]);

  const updateEmpStatus = useCallback((data) => {
    api.employeeStatusUpdate(data).then(data => {
      if (data?.data && data?.data?.success) {
        navigate(path.EMPLOYEMENT_SUCCESS + "?" + btoa("title=Status changed Successfully!"));
      } else {
        setModalTitle("Failed to update employee status.");
        if(data?.data?.error && data?.data?.message){
          setModalBody(data?.data?.message)
        }
        else{
          setModalBody('Please try after sometime');
        }
        toggle();
      }
    }).catch(err => {
      setModalTitle("Failed to update employee status.");
      if(err?.response?.data?.message){
        setModalBody(err?.response?.data?.message);
      } else {
        setModalBody('Please try after sometime');
      }   
      toggle();
    })
  }, []);

  const formatDate = useCallback((dateString) => {
    const dateObj = new Date(dateString);
    const year = dateObj.getFullYear();
    const month = String(dateObj.getMonth() + 1).padStart(2, '0');
    const day = String(dateObj.getDate()).padStart(2, '0');
    const formattedDate = year + "-" + month + "-" + day;
    return formattedDate;
  }, []);
  
  const removeCardFromStatusList = useCallback((index) => {
    let tempArray = [];
    Object.assign(tempArray, filterEmp);
    tempArray.splice(index, 1);
    if (tempArray.length == 0) {
      // history.push(path.EMPLOYMENT_MASTER)
      navigate(path.EMPLOYMENT_MASTER)
    }
    setFilterEmp(tempArray);
  }, [filterEmp]);

  const onSubmit = useCallback(() => {
    let empIdArr = [];
    filterEmp.filter((data) => {
      empIdArr.push(data.vendor_employee_id)
    })
    let emp = {
      "vendor_employee_id": empIdArr,
      "status": status,
    }
    if (showCalender && startDate && tillDate) {
      emp = {
        ...emp,
        "leave_till_date": formatDate(tillDate),
        "leave_from_date" : formatDate(startDate),
      }
    }
    updateEmpStatus(emp)
  }, [filterEmp, status, showCalender, startDate, tillDate]);

  return (
    <div data-testid="changeEmp" className='empChangeStatus'>
      <EmploymentMasterNavbar title="Employee Change Status" backButton={path.EMPLOYMENT_MASTER} />
      <div className="empChangeStatus-body">
        <span className='empChangeStatus-mark'>Mark as</span>
        <div className="filterBtn-container">
          {Array.isArray(filterBtn) &&
            filterBtn.map((ele, index) => (
              <div key={index} className='filterBtn-container-btn'>
                <FilterButton data-testid="filterbtn" children={ele.text} active={ele.active} onFilterClick={() => onFilterClick(index)} id={2} />
              </div>
            ))
          }
        </div>
        {showCalender && (
          <div className="empChangeStatus-calender">
            <label>Select date</label>
            <div className='EmpMasterChangeStatus-datepicker'>
            <div className='EmpMasterChangeStatus-datepicker1'><MyDatePicker startDate={startDate} nextdate={tillDate} setStartDate={setStartDate} id={3} placeholder='From'/></div>
            <div className='EmpMasterChangeStatus-datepicker2'><MyDatePicker startDate={tillDate} previousdate={startDate} setStartDate={settillDate} id={3} placeholder='To'/></div>
            </div>
          </div>
        )}
        {Array.isArray(filterEmp) &&
          filterEmp.map((ele, index) => (
            <div key={index} className="empMasterPage-cardSelect">
                <EmployeeMasterCard key={index} status={ele?.status} srno={ele?.srno} vendor_parent_name={employee?.vendor_parent_name} vendor_state={employee?.vendor_state} changeStatus={true} removeCardFromStatusList={(index) => removeCardFromStatusList(index)}
                  vendor_city={employee?.vendor_city} location={ele?.location} investigator_name={ele?.investigator_name} empID={ele?.vendor_employee_id} email={ele?.email} mobile_number={ele?.mobile_number} filename={ele?.profileImage ? ele?.profileImage?.profile_image_name : ""} />
            </div>
          ))
        }
        <div className="empChangeStatusBtn-div">
          <div className={filterEmp && filterEmp.length < 3 ? "empChangeStatusBtn empmasterSubmitbuttonNew" : "empChangeStatusBtn"}>
            <EmpMasterButton data-testid="save-button" id={1} disabled={disableSubmit} background={disableSubmit ? "#F7BDBE" : "#E94D51"} color={disableSubmit ? "#E94D51" : "#FFFFFF"} onClick={() => onSubmit()}>Submit</EmpMasterButton>
          </div>
        </div>
      </div>
      <ErrorAlert
        modal={modal}
        toggle={toggle}
        modalTitle={modalTitle}
        modalBody={modalBody}
      />
    </div>
  )
}
EmpMasterChangeStatus.propTypes = {
};

export function mapDispatchToProps(dispatch) {
  return {
    redirectPath: (path) => dispatch(push(path)),
  };
}

const withConnect = connect(
  null,
  mapDispatchToProps,
);

export default compose(withConnect, memo,)(EmpMasterChangeStatus);