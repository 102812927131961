import React, { memo } from 'react'
import { Button } from 'reactstrap'
import './InvestigatorBtn.css'
import { connect } from 'react-redux';
import { createStructuredSelector } from "reselect";

export const InvestigatorButton = memo(({selectedInvestigator,investigatorBtnClick,datatestid}) => {
  return (
    <div className={(selectedInvestigator && selectedInvestigator.length !== 0) ? 'assign-investigator newbtn-container selected' : 'assign-investigator newbtn-container' }>
      <Button disabled={(selectedInvestigator && selectedInvestigator.length !== 0) ? false : true} data-testid={datatestid ? datatestid :"btn"} onClick={()=>investigatorBtnClick()} className="assignbtn-custom">Assign</Button>
    </div>
  )
})

InvestigatorButton.propTypes = {
  // selectedInvestigator:PropTypes.func.isRequired
}

const mapStateToProps = createStructuredSelector({
  // selectedInvestigator:makeSelectAssignInvestigatorId()
});

export default connect(mapStateToProps)(InvestigatorButton);
