import produce from 'immer';
import { MOBILE_OTP } from './constants';

export const initialState = {
    otp: '',
  };

const otpReducer = (state = initialState, action) =>
produce(state, draft => {
  switch (action.type) {
    case MOBILE_OTP:
      draft.otp= action.otp;
      break;
  }
});

export default otpReducer;