import React from "react";
import Basic from "../containers/ReinvestigateCasesDetails/CardTabs/Basic"
import Dispatch from "../containers/ReinvestigateCasesDetails/CardTabs/Dispatch"
import Risk from "../containers/ReinvestigateCasesDetails/CardTabs/Risk"
import Proposal from "../containers/ReinvestigateCasesDetails/CardTabs/Proposal";
import Policy from "../containers/ReinvestigateCasesDetails/CardTabs/Policy";
import Claim from "../containers/ReinvestigateCasesDetails/CardTabs/Claim";
import Endorsement from "../containers/ReinvestigateCasesDetails/CardTabs/Endorsement";
import InvestigatorInvestigation from "../containers/ReinvestigateCasesDetails/CardTabs/Investigation/InvestigatorInvestigation";
import Investigation from "../containers/ReinvestigateCasesDetails/CardTabs/Investigation/Investigation";
import External from "../containers/ReinvestigateCasesDetails/CardTabs/External";
const vendorCardData = [
    {
      tabId: '1',
      tabTitle: 'Investigator',
      tabContent:<Investigation />
    },
    {
      tabId: '2',
      tabTitle: 'Basic',
      tabContent: <Basic />
    },
    {
      tabId: '3',
      tabTitle: 'Policy',
      tabContent: <Policy />
    },
    {
      tabId: '4',
      tabTitle: 'Claim',
      tabContent:<Claim />
    },
    {
      tabId: '5',
      tabTitle: 'Proposal',
      tabContent: <Proposal />
    },
    {
      tabId: '6',
      tabTitle: 'Dispatch',
      tabContent: <Dispatch />
    }, 
    {
      tabId: '7',
      tabTitle: 'Risk',
      tabContent: <Risk />
    },
    {
      tabId: '8',
      tabTitle: 'Endorsement',
      tabContent: <Endorsement />
    },
    {
      tabId: '9',
      tabTitle: 'External',
      tabContent: <External />
    }
  ]

  const investigatorCardData = [
    {
      tabId: '1',
      tabTitle: 'Investigation',
      tabContent: <InvestigatorInvestigation />
    },
    {
      tabId: '2',
      tabTitle: 'Basic',
      tabContent: <Basic />
    },
    {
      tabId: '3',
      tabTitle: 'Policy',
      tabContent: <Policy />
    },
    {
      tabId: '4',
      tabTitle: 'Claim',
      tabContent: <Claim />
    },
    {
      tabId: '5',
      tabTitle: 'Proposal',
      tabContent: <Proposal />
    },
    {
      tabId: '6',
      tabTitle: 'Dispatch',
      tabContent: <Dispatch />
    },
    {
      tabId: '7',
      tabTitle: 'Risk',
      tabContent: <Risk />
    },
    {
      tabId: '8',
      tabTitle: 'Endorsement',
      tabContent: <Endorsement />
    },
    {
      tabId: '9',
      tabTitle: 'External',
      tabContent: <External />
    }
  ]

  export {vendorCardData,investigatorCardData}