import React from 'react';
import StatusCard from './StatusCard';
import './index.css';

export const InvoiceStatus = ({ invoiceStatusData }) => {
  return (
    <div>
      {invoiceStatusData?.length > 0 ? (
        invoiceStatusData?.map((item, i) => (
          <StatusCard
            data={item}
            srNumber={i+1}
            key={`invoiceStatus-${i}`}
          />
        ))
      ) : (
        <p className='no-records'>No records to show!</p>
      )}
  </div>
  )
}

export default InvoiceStatus;