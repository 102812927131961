import React, { memo } from "react";
import CommonCaseCard from "../../components/CommonCaseCard/CommonCaseCard";

export const CaseCard = memo(({ selectCards }) => {
  return (
    <div>
      {selectCards &&
        selectCards.map((item, index) => (
          <CommonCaseCard
            key={`openCaseCard-${index}`}
            cardDetail={item}
            btn={false}
            cardType={"Open"}
          />
        ))}
    </div>
  );
});

CaseCard.propTypes = {};

export default CaseCard;
