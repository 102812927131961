import React, { memo, useState, useContext, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { compose } from "redux";
import { useInjectReducer } from "../../utils/injectReducer";
import makeSelectSubmitFinalPage from "./selectors";
import reducer from "./reducer";
import CommonNavbar from "../../components/CommonNavbar";
import Dropdown from "./Dropdown";
import { getUrlParameter } from "../../constants/commonFunction";
import AcceptButtons from "../OpenCasesDetailsPage/AcceptButtons";
import { ApiDispatchContext } from "../../context/ApiContext";
import ErrorAlert from "../../components/ErrorAlert";
import { makeSelectData } from "./selectors";
import { updateData } from "./actions";
import './index.css';
import { push } from 'connected-react-router';
import SuccessMessage from "../../components/SuccessMssgDisplay";
import path from "../../constants/pathConstants";
import Text from "./Text";
import { useNavigate } from "react-router-dom";
import { analyticsEvent } from "../../utils/analytics"
import Loader from "../../components/Loader";
export function SubmitFinalPage({setData,data,redirectPath}) {
  useInjectReducer({ key: "submitFinalPage", reducer });
  const api = useContext(ApiDispatchContext);
  const [modal, setModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalBody, setModalBody] = useState("");
  const [disabled,setDisabled] = useState(true)
  const [showLoader, setShowLoader] = useState(false);
  const toggle = () => setModal(!modal);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  let caseid = Number(getUrlParameter('case'))
  let casetype = getUrlParameter('casetype');

  const navigate = useNavigate();

  const processGradeCase = useCallback((data) => {
    data.map((resp,key)=>{

      if(resp.parameter_name === "grade_sub_status"){ // disable all the dropdowns except the first
        data[key].isDisabled = false;
      }

      if(resp.parameter_name === "grade_sub_status"){
        data[key].parameter_options_copy = data[key]?.parameter_options;
      }

      if(resp.parameter_options && typeof resp.parameter_options[0] !== 'object'){ // if array is sent as parameter_options make the value JSON
        resp.parameter_options.map((item, index) => {
          data[key].parameter_options[index] = { id: index + 1, value: item };
        });
      }
    })
    setData(data)
  }, []);

  const dropdownOnclick = useCallback((data2,index) =>{
    let id = ""
    let val = false;
    data2.map((res,key)=>{
      if(res.parameter_name === "grade_status" && res.parameter_value !== ""){
        res.parameter_options.map((item,index)=>{
          if(item.value === res.parameter_value){
            id = item.id;
          }
        })
      }
      if(data2[index].parameter_name === "grade_status"){
        val = true;
      }

    })
    let modifieddata = [];
    data2.map((res,key)=>{
      if(res.parameter_name === "grade_sub_status" && Number(id)){
        if(val){
          data2[key].parameter_value = '';
        }
        res.parameter_options_copy.map((item,indextwo)=>{
          if(item.grade_status_master_id === id){
            modifieddata.push(item)
          }
        })

        data2[key].parameter_options = modifieddata;
      }
    });

    setData(data2)
  }, []);

  useEffect(() => {
    let payload = {
      "caseId": caseid
    }
    api.getGradeCase(payload).then(data => {
      setShowLoader(true);
      if (data?.data?.success) {
        //Google Analytics Event to Track Case Final Submit
        analyticsEvent(
          "Submit Final",
          "Submit Final Success",
          "Submit Final Success"
        );
        processGradeCase(data?.data?.data?.data)
        setShowLoader(false);
      } else {
        setModalTitle("Failed to load");
        if (data?.data?.error && data?.data?.message) {
          setModalBody(data?.data?.message);
        } else {
          setModalBody('Please try after sometime');
        }
        toggle();
        setShowLoader(false);
      }
    }).catch(err => {
      setModalTitle("Failed to Save remark Data.");
      if (err?.response?.data?.message) {
        setModalBody(err?.response?.data?.message);
      } else {
        setModalBody('Please try after sometime.');
      }
      toggle();
      setShowLoader(false);
    })
  }, [])

  useEffect(()=>{
    if(data && data.length > 0){
    const isAllfieldfilled = data.every(obj => {
     return obj.id != null && obj.parameter_value !== "";
    });
    setDisabled(!isAllfieldfilled);
  }
  },[data])

  function borderColor() {
    return casetype === "opencase" ? " 1px solid #1AB275" : casetype === "allottedcase" ? "1px solid #058AEE" : "1px solid #EBAF00";
  }

  const redirectAssignCase = () => {
    switch (casetype) {
      case "opencases":
        return path.OPEN_CASE;
      case "allottedcase":
        return path.ALLOTTED_CASES;
      default:
        return path.REINVESTIGATE_CASES;
    }
  }

  const onSubmit = useCallback(() => {
    setDisabled(true);
    let payload = {
      "caseId": caseid,
      "gradeMasterId": data[0].id,
      "gradeStatusId": data[1].id,
      "gradeSubStatusId": data[2].id,
      "closureCategory": data[3].parameter_value,
      "comment": data[4].parameter_value
    }
    //event of gtm for add grade_Category
    window.dataLayer.push({
      event: "grade_Category",
      closureCategory: data[3].parameter_value,
    });
    //event of gtm for case submit 
    window.dataLayer.push({
      event: "case_submitted",
    });
    api.submitGradeCase(payload).then(data => {
      if (data?.data && data?.data?.success) {
        navigate(
            path.SUCCESS_PAGE,
            {state: { stateVal: redirectAssignCase()}}
          )
      }
      else {
        setModalTitle("Failed to load");
        if (data?.data?.error && data?.data?.message) {
          setModalBody(data.data.message)
        } else {
          setModalBody('Please try after sometime');
        }
        toggle();
      }
    }).catch(err => {
      setModalTitle("Failed to Save Data.");
      if (err?.response?.data?.message) {
        setModalBody(err?.response?.data?.message);
      } else {
        setModalBody('Please try after sometime.');
      }
      toggle();
    })
  }, [caseid, data, redirectAssignCase]);

  return (
    <div>
      <CommonNavbar title="Submit Final" navbarname={casetype==="opencase"?'AssignedBlocksOpenCases':casetype==="reinvestigatecases"?"AssignedBlocksReinCases":"AssignedBlocksAllottedCases"}/>
      <div>
      <div className='submit-final'>
      <span>Grade Case</span>
      <div style={{border:borderColor()}} className='submitfinal-container'>
       {data && data.length > 0 && data.map((item, i) => {
          switch (item.parameter_type) {
            case 'select':
              return <Dropdown data={data} setData={dropdownOnclick} i={i} casetype={casetype} key={item.block_parameters_id} config={item}  />
            case 'text':
              return <Text data={data} setData={setData} i={i} casetype={casetype} key={item.block_parameters_id} config={item} ifRequired={true} />
          }
          
        })}
      </div>
    </div>
      </div>
      <div className="AcceptButtons-Container">
        <AcceptButtons disabled={disabled} background={disabled?"#F7BDBE":"rgb(233, 77, 81)"} color={!disabled?"rgb(255, 255, 255)":"#E42125"} onClick={() => onSubmit()}  datatestid="save-button">Submit</AcceptButtons>
      </div>
      {showSuccessMessage && (
        <SuccessMessage message="Records Updated Successfully!" delay={3000} />
      )}
      {showLoader && <Loader />}
      <ErrorAlert
        modal={modal}
        toggle={toggle}
        modalTitle={modalTitle}
        modalBody={modalBody}
      />
    </div>
  );
}

SubmitFinalPage.propTypes = {
  dispatch: PropTypes.func.isRequired
};

const mapStateToProps = createStructuredSelector({
  submitFinalPage: makeSelectSubmitFinalPage(),
  data:makeSelectData(),
});

export function mapDispatchToProps(dispatch) {
  return {
    setData: (data) => dispatch(updateData(data)),
    redirectPath: (path) => dispatch(push(path)),
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);

export default compose(withConnect, memo)(SubmitFinalPage);