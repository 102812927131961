import React, { memo, useState , useEffect, useCallback} from 'react';
import PropTypes from 'prop-types';
import { DropdownMenu, DropdownItem, ButtonDropdown, DropdownToggle, Input, } from 'reactstrap';
import './index.css';

export const LocationDropdown = memo((props) => {
  const [dropdownOpen, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [isMouseOver, setIsMouseOver] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [dropdownArrowClicked, setDropdownArrowClicked] = useState(false);
  const toggleDropdown = useCallback(() => {
    if (props?.type === 'city') {
      if (dropdownArrowClicked) {
        setOpen(true);
      } else if (!isMouseOver) {
        setOpen(!dropdownOpen);
      }
    } else {
      setOpen(!dropdownOpen);
    }
    setDropdownArrowClicked(false);
  }, [dropdownArrowClicked, isMouseOver, dropdownOpen, props?.type]);

  const handleInputChange = useCallback((event) => {
    setDropdownArrowClicked(true);
    const value = event.target.value;
    setInputValue(value);
    filterOptions(value);
  }, []);

  const selectDropdown = useCallback((val) => {
    if (val === 'all of state') {
      let allCities = props?.locationItem?.options?.map((data) => data?.city_name);
      const isSelected = selectedOptions?.includes(val);
      if (isSelected) {
        setSelectedOptions([]);
        props.selectDropdown('');
      } else {
        props.selectDropdown([...allCities]);
        setSelectedOptions([...allCities, "all of state"]);
      }
    } else {
      const isSelected = selectedOptions.includes(val);
      if (isSelected) {
        props.selectDropdown(selectedOptions.filter(option => option !== val && option !== 'all of state'));
        setSelectedOptions(selectedOptions.filter(option => option !== val && option !== 'all of state'));
      } else {
        setSelectedOptions([...selectedOptions, val]);
        props.selectDropdown([...selectedOptions, val]);
      }
    }
  }, [props?.locationItem?.options, selectedOptions, props?.selectDropdown]);

  const filterOptions = useCallback((value) => {
    setDropdownArrowClicked(true);
    if (value?.length >= 3) {
      const filteredArray = props?.locationItem?.options
        .filter((data) => data?.city_name?.toLowerCase().indexOf(value?.toLowerCase()) !== -1)
        .sort((a, b) => {
          if (!a.is_present && b.is_present) return -1; // a is not present, but b is
          if (a.is_present && !b.is_present) return 1; // b is not present, but a is
          return 0; // both have the same is_present value or both are true
        });
      const checkedOptions = filteredArray?.filter((data) => data?.is_present);
      const uncheckedOptions = filteredArray?.filter((data) => !data?.is_present);

      const selectedCityNames = checkedOptions?.map((data) => data.city_name);
      if (selectedOptions.length === 0) {
        setSelectedOptions([...selectedCityNames]);
      }
      setFilteredOptions([...checkedOptions, ...uncheckedOptions]);
    } else {
      setFilteredOptions([]);
    }
  }, [props?.locationItem?.options]);

  const selectednew = useCallback((value) => {
    setOpen(!dropdownOpen);
  }, [dropdownOpen]);

  const placeholderinput = useCallback(() => {
    if (selectedOptions.includes('all of state')) {
      return props?.locationItem?.options?.length + '/' + props?.locationItem?.options?.length + ' selected';
    } else {
      return props?.locationItem?.selected?.length + '/' + props?.locationItem?.options?.length + ' selected';
    }
  }, [selectedOptions, props?.locationItem?.options?.length, props?.locationItem?.selected?.length]);

  const setInitialvalueCity = useCallback(() => {
    if(props?.locationItem?.options?.length > 0 && props?.type === 'city'){
    const filteredArray = props?.locationItem?.options
      .filter((data) => data?.city_name?.toLowerCase() !== -1)
      .sort((a, b) => {
        if (!a.is_present && b.is_present) return -1; // a is not present, but b is
        if (a.is_present && !b.is_present) return 1; // b is not present, but a is
        return 0; // both have the same is_present value or both are true
      });
      setFilteredOptions([...filteredArray]);
    }
    setSelectedOptions([]);
    setInputValue('');
  }, [props?.locationItem?.options?.length, props?.type]);

  useEffect(() => {
    if (props?.type === 'city' && !dropdownOpen) {
      setInitialvalueCity();
    }
  }, [dropdownOpen, props?.type, props?.locationItem.options]);

  const handleMouseEnter = useCallback(() => {
    setIsMouseOver(true);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setIsMouseOver(false);
  }, []);

  return (
    <div style={{ zIndex: 8 }} className={dropdownOpen ? 'dropdown generic-dropdown filteractionsdropdown locationDropdown Dropopenlocation' : 'dropdown generic-dropdown filteractionsdropdown locationDropdown'}>
      <ButtonDropdown
        data-testid="LocationDropdown1"
        toggle={toggleDropdown}
        isOpen={dropdownOpen}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {props.type === 'city' ? (
          <DropdownToggle
            disabled={props.stateSelected ? false : true}
            onClick={selectednew}
            caret
            style={{ backgroundColor: '#E5F3FF', color: props.locationItem && props.locationItem.selected ? '#3A3A3A' : '#8D8D8D' }}
            className="locationdropdown-item2"
          >
            <Input
              disabled={props.stateSelected ? false : true}
              type="text"
              value={inputValue}
              data-testid="LocationDropdown3" 
              onChange={handleInputChange}
              className="location-input"
              placeholder={props.locationItem.selected.length > 0 ? placeholderinput() : 'Select'}
              style={{ backgroundColor: '#E5F3FF', border: 'transparent', padding: 0, fontSize: 12 }}
            />
          </DropdownToggle>
        ) : (
          <DropdownToggle
            caret
            data-testid="LocationDropdown1" 
            style={{ backgroundColor: '#E5F3FF', color: props.locationItem && props.locationItem.selected ? '#3A3A3A' : '#8D8D8D' }}
            className="locationdropdown-item2"
          >
            {props.locationItem && props.locationItem.selected ? props.locationItem.selected : 'Select'}
          </DropdownToggle>
        )}
        {props.type === 'city' ? (
          <DropdownMenu style={{ backgroundColor: '#E5F3FF' }} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            <DropdownItem data-testid="LocationDropdown4" className="locationdropdown-item" key={'all of ' + props.stateSelected} onClick={() => selectDropdown('all of state')}>
              All of {props.stateSelected}{' '}
              <input
                onChange={() => {
                  selectDropdown('all of state');
                  setDropdownArrowClicked(true);
                }}
                data-testid="LocationDropdown6"
                value={'all of state'}
                checked={selectedOptions.includes('all of state')}
                type="checkbox"
                className="checkbox-container"
                style={{ float: 'right' }}
              />
            </DropdownItem>
            {filteredOptions &&
              filteredOptions.map((data) => {
                return (
                  <DropdownItem data-testid="LocationDropdown5" className="locationdropdown-item" key={data.city_name} onClick={() => selectDropdown(data.city_name)}>
                    {data.city_name}
                    <input
                     data-testid="LocationDropdown7"
                      value={data.city_name}
                      checked={selectedOptions.includes(data.city_name)}
                      type="checkbox"
                      className="checkbox-container"
                      style={{ float: 'right' }}
                      onChange={() => {
                        selectDropdown(data.city_name);
                        setDropdownArrowClicked(true);
                      }}
                    />
                  </DropdownItem>
                );
              })}
          </DropdownMenu>
        ) : (
          <DropdownMenu style={{ backgroundColor: '#E5F3FF' }} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            {props.showArray &&
              props.showArray.map((data) => {
                return (
                  <DropdownItem
                    data-testid="LocationDropdown2"
                    className="locationdropdown-item"
                    key={data}
                    onClick={() => {
                      props.selectDropdown(data);
                      toggleDropdown();
                      setInitialvalueCity();
                    }}
                  >
                    {data}
                  </DropdownItem>
                );
              })}
          </DropdownMenu>
        )}
      </ButtonDropdown>
    </div>
  );
});

LocationDropdown.propTypes = {
  selectedOptions: PropTypes.array,
};

export default LocationDropdown;
