import React from 'react';
import './Notification.css';
import CommonNavbar from '../CommonNavbar';
import { imageBaseUrl } from '../../utils/constants';
function Notification() {
    const tempdata = [{ error: true, cases: "4 new open cases assigned to you" },
    { error: false, cases: "2 invoices rejected" },
    { error: false, cases: "Akhilesh has completed all assigned blocks for case no. 123456" },
    { error: false, cases: "Anil has completed all assigned blocks for case no. 123456" }]
    return (
        <div>
            <CommonNavbar title="Notifications" navbarname='NotificationPageHeader' />
            <div data-testid="NotificationPage" className='notification'>
                <span className='notification-heading' >Today</span>
                <hr />
                {Array.isArray(tempdata) && tempdata.map((ele, i) => (
                    <div key={i} className={ele.error ? 'notification-container' : "notification-container-warning"}>
                        <img className='notification-triangle' src={ele.error ? `${imageBaseUrl}/alert.png` : `${imageBaseUrl}/AlertYellow.png`} alt="alert" />
                        <p className='notification-container-p'><span>{ele.cases.replace(/ .*/,'')+" "}</span>{ele.cases.split(' ').slice(1).join(' ')}</p>
                        <img className='notification-arrow' src={ele.error ? `${imageBaseUrl}/arrow.png` : `${imageBaseUrl}/warningarrow.png`} alt="alert" />
                    </div>
                ))}
            </div>
        </div>
    )
}

export default Notification;