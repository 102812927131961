import React, { memo, useCallback, useContext, useEffect, useState } from "react";
import { createStructuredSelector } from "reselect";
import { compose } from "redux";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { useInjectReducer } from "../../utils/injectReducer";
import reducer from "./reducer";
import GenericForms from "../../components/GenericForms";
import CommonNavbar from "../../components/CommonNavbar";
import { getUrlParameter } from "../../constants/commonFunction";
import path from "../../constants/pathConstants";
import './index.css';
import { ApiDispatchContext } from "../../context/ApiContext";
import { formData } from "./actions";
import { getEmployeeRole } from '../App/selectors';
import { setFormValue } from "./actions";
import PetitionerForm from "../../components/PetitionerForm";
import AssignBlocksFormBottom from "../../components/AssignBlocksFormBottom";
import FilterPopUp from "../../components/FilterPopUp";
import DocumentUpload from "../../components/DocumentUpload";
import AcceptButtons from '../OpenCasesDetailsPage/AcceptButtons/index';
import ErrorAlert from "../../components/ErrorAlert";
import makeSelectAssignBlocksReinvestigatedCases from "./selectors";
import SuccessMessage from "../../components/SuccessMssgDisplay";
import Loader from "../../components/Loader";
import { useNavigate } from "react-router-dom";
export function AssignBlocksReinvestigatedCases({ assignBlocksReinvestigatedCases, setFormData, employeeRole, saveFormData }) {
  useInjectReducer({ key: "assignBlocksReinvestigatedCases", reducer });
  let caseid = Number(getUrlParameter('case'));
  let blockid = Number(getUrlParameter('blockid'));
  const [open, setOpen] = useState(false)
  let blockname = getUrlParameter('blockname');
  let multiForm = getUrlParameter('multiform');
  let subProduct = getUrlParameter('subProduct');
  let lob = getUrlParameter('lob');
  const [modal, setModal] = useState(false);
  const [docsUploaded,setDocsUploaded] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalBody, setModalBody] = useState("");
  const toggle = () => setModal(!modal);
  const api = useContext(ApiDispatchContext);
  const [showPetitionerForm, setShowPetitionerForm] = useState(false)
  const [allParameterValuesNotEmpty, setAllParameterValuesNotEmpty] = useState(false);
  const [oneParameterValuesNotEmpty, setOneParameterValuesNotEmpty] = useState(true);
  const [renamed_block_parameter, setrenamed_block_parameter] = useState('');
  const [showUploadBtn, setshowUploadBtn] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [showLoader, setShowLoader] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    setFormData([]);
    saveFormData([]);
    window.scrollTo(0, 0);
    if (multiForm === "true") {
      api.createDynamicBlockForm({ caseId: caseid, blockId: blockid }).then(data => {
        if (data?.data?.data) {
          setFormData(data?.data?.data);
          setShowPetitionerForm(true);
          let tempdata = [];
          Object.assign(tempdata, data?.data?.data?.singleParameters);
          for (let i = 0; i < tempdata?.length; i++) {
            if (tempdata[i]?.parameter_type === "upload") {
              setshowUploadBtn(true);
            }
          }
        } else {
          setModalTitle("Error");
          if (data?.data?.error && data?.data?.message) {
            setModalBody(data?.data?.message)
          }
          else {
            setModalBody('Please try after sometime');
          }
          toggle();
        }
      }).catch(err => {
        setModalTitle("Error");
        if (err?.response?.data?.message) {
          setModalBody(err?.response?.data?.message);
        } else {
          setModalBody('Please try after sometime');
        }
        toggle();
      })
    } else {
      api.createDynamicBlockForm({ caseId: caseid, blockId: blockid }).then(data => {
        if (data?.data?.data?.singleParameters) {
          setFormData(data?.data?.data);
          let tempdata = [];
          Object.assign(tempdata, data?.data?.data?.singleParameters);
          for (let i = 0; i < tempdata?.length; i++) {
            if (tempdata[i]?.parameter_type === "upload") {
              setshowUploadBtn(true)
            }
          }
        } else {
          setModalTitle("Error");
          if (data?.data?.error && data?.data?.message) {
            setModalBody(data?.data?.message)
          }
          else {
            setModalBody('Please try after sometime');
          }
          toggle();
        }
      }).catch(err => {
        setModalTitle("Error");
        if (err?.response?.data?.message) {
          setModalBody(err?.response?.data?.message);
        } else {
          setModalBody('Please try after sometime');
        }
        toggle();
      })
      setShowPetitionerForm(false)
    }
  }, [])


  useEffect(() => {
    setOneParameterValuesNotEmpty(handleOneFieldFilled());
    if (!showPetitionerForm && assignBlocksReinvestigatedCases?.formData?.singleParameters) {
      const isAllValuesNotEmpty = assignBlocksReinvestigatedCases?.formData?.singleParameters.every(obj => {
        if (obj.parameter_type != "upload") {
          if (obj.value !== undefined) {
            return obj.value != null && obj.value !== ""
          }
          else {
            return obj.parameter_value != null && obj.parameter_value !== ""
          }
        }
        return true
      });
      let isAllDocNotEmpty = assignBlocksReinvestigatedCases?.formData?.singleParameters.every(obj =>{
        if(obj?.parameter_type === "upload"){
          return obj?.parameter_value?.length
        }
        return true
      })
      setAllParameterValuesNotEmpty(isAllValuesNotEmpty && isAllDocNotEmpty );
    } else if (showPetitionerForm && assignBlocksReinvestigatedCases?.formData && (assignBlocksReinvestigatedCases?.formData?.singleParameters.length > 0 || assignBlocksReinvestigatedCases?.formData?.multipleParameters.length > 0)) {
      const isAllSingleValuesNotEmpty = assignBlocksReinvestigatedCases?.formData?.singleParameters.every(obj => {
        if(obj.parameter_type != "upload"){
        return  obj.value != null && obj.value !== "" || obj.parameter_value != null && obj.parameter_value !== ""
        }
        return true
      });
      const isAllMultiValuesNotEmpty = assignBlocksReinvestigatedCases?.formData?.multipleParameters.every(arr => {
        return arr.every(obj =>obj.parameter_value != null && obj.parameter_value !== "");
      });
      let isAllDocNotEmpty = assignBlocksReinvestigatedCases?.formData?.singleParameters.every(obj =>{
        if(obj.parameter_type === "upload"){
          return obj.parameter_value.length
        }
        return true
      })
      setAllParameterValuesNotEmpty(isAllSingleValuesNotEmpty && isAllMultiValuesNotEmpty && isAllDocNotEmpty)
    }
  }, [assignBlocksReinvestigatedCases?.formData, showPetitionerForm, open]);

  const handleOneFieldFilled = useCallback(() => {
    let fieldFilled = false; 
    assignBlocksReinvestigatedCases?.formData?.singleParameters && assignBlocksReinvestigatedCases?.formData?.singleParameters.map((item) => {
      if(item?.parameter_type === "upload" && item?.parameter_value && item?.parameter_value.length > 0 ) {
        return fieldFilled = true;
      } 
      if(item?.parameter_type !== "upload" && item?.parameter_value && item?.parameter_value !== null && item?.parameter_value !== undefined && item?.parameter_value !== "" ) {
        return fieldFilled = true;
      } 
    })
    if(showPetitionerForm && !fieldFilled) {
      assignBlocksReinvestigatedCases?.formData?.multipleParameters.map((multiData) => {
        multiData.map((item) => {
          if(item?.parameter_type === "upload" && item?.parameter_value && item?.parameter_value.length > 0 ) {
            return fieldFilled = true;
          } 
          if(item?.parameter_type !== "upload" && item?.parameter_value && item?.parameter_value !== null && item?.parameter_value !== undefined && item?.parameter_value !== "" ) {
            return fieldFilled = true;
          } 
        });
      })
    }
    return fieldFilled;
  }, [assignBlocksReinvestigatedCases?.formData, showPetitionerForm]);

  const filterToggle = useCallback((temprenamed_block_parameter) => {
    setrenamed_block_parameter(temprenamed_block_parameter);
    setOpen(!open);
  }, [open]);

  const redirectAssignCase = useCallback(() => {
    return path.REINVESTIGATE_CASES_DETAILS + "?" + btoa("case=" + caseid + "&lob=" + lob + "&subProduct=" + subProduct + "&redirectedFrom=AssignedBlocks");
  }, [caseid, subProduct, lob]);

  const handleChange = useCallback((value, index) => {
    let newPetitioners = { ...assignBlocksReinvestigatedCases.formData }; // create a copy of the petitioners object
    newPetitioners.singleParameters[index].parameter_value = value; // update the parameter_value of the singleParameter at the given index
    setFormData(newPetitioners); // set the new state object
  }, [assignBlocksReinvestigatedCases?.formData]);

  const handlePetChange = useCallback((value, petIndex, petInpIndex) => {
    let newPetitioners = { ...assignBlocksReinvestigatedCases.formData };
    newPetitioners.multipleParameters[petIndex][petInpIndex].parameter_value = value
    setFormData(newPetitioners);
  }, [assignBlocksReinvestigatedCases?.formData]);

  const addAnother = useCallback(() => {
    let array = JSON.parse(JSON.stringify(assignBlocksReinvestigatedCases?.formData?.multipleParameters))
    let lastArray = array[array.length - 1]
    let hasNonEmptyValue = false;
    for (let i = 0; i < lastArray?.length; i++) {
      if (lastArray[i].parameter_value !== "") {
        hasNonEmptyValue = true;
        break;
      }
    }
    if (assignBlocksReinvestigatedCases?.formData?.multipleParameters.length != 3 && hasNonEmptyValue) {
      let tempArray = JSON.parse(JSON.stringify(assignBlocksReinvestigatedCases?.formData))
      let temp = JSON.parse(JSON.stringify(tempArray?.multipleParameters[0]))
      if (temp) {
        temp.forEach(item => {
          item.parameter_value = "";
        });
      }
      tempArray.multipleParameters.push(temp)
      setFormData(tempArray)
    }
  }, [assignBlocksReinvestigatedCases?.formData]);

  const onPetRemove = useCallback((petIndex) => {
    setFormData({})
    let tempArray = JSON.parse(JSON.stringify(assignBlocksReinvestigatedCases?.formData))
    let temp = JSON.parse(JSON.stringify(tempArray?.multipleParameters))
    temp.splice(petIndex, 1)
    tempArray.multipleParameters = temp
    setFormData(tempArray)
  }, [assignBlocksReinvestigatedCases?.formData]);

  const getFormDataWithSingleParams = useCallback(() => {
    const result = {};
    assignBlocksReinvestigatedCases?.formData?.singleParameters.forEach(pay => {
      if (pay.parameter_type !== "upload") {
        result[pay.parameter_name] = pay.value ? pay.value : pay.parameter_value ? pay.parameter_value : null;
      }
    });
    return result;
  }, [assignBlocksReinvestigatedCases?.formData]);

  const getFormDataWithMultiParams = useCallback(() => {
    const result = {};
    const temp = [];
    assignBlocksReinvestigatedCases?.formData?.singleParameters.forEach(pay => {
      if (pay.parameter_type !== "upload") {
        result[pay.parameter_name] = pay?.parameter_value ? pay?.parameter_value : null;
      }
    });
    assignBlocksReinvestigatedCases?.formData?.multipleParameters.map(item => {
      let obj = {};
      item.map(ele => {
        obj[ele.parameter_name] = ele?.parameter_value ? ele?.parameter_value : null;
      });
      temp.push(obj);
    });
    const multipleParameters = "multipleParameters";
    result[multipleParameters] = temp;
    return result;
  }, [assignBlocksReinvestigatedCases?.formData]);

  const getdocumentsArray = useCallback(() => {
    let tempdata = [];
    let config = [];
    Object.assign(tempdata, assignBlocksReinvestigatedCases?.formData?.singleParameters);
    for (let i = 0; i < tempdata.length; i++) {
      if (tempdata[i].parameter_type === "upload") {
        config.push(tempdata[i])
      }
    }
    return config;
  }, [assignBlocksReinvestigatedCases?.formData]);

  const onSubmit = useCallback((saveDraft) => {
    setShowSuccessMessage(false);
    let temperror = false;
    let tempdata = [];
    Object.assign(tempdata, assignBlocksReinvestigatedCases?.formData?.singleParameters);
    for (let i = 0; i < tempdata.length; i++) {
      if (tempdata[i].renamed_block_parameter.toLowerCase().includes('mobile number') || tempdata[i].renamed_block_parameter.toLowerCase().includes('contact number')) {
        if (tempdata[i].parameter_value.length !== 10) {
          setModalTitle("Mobile Input Error");
          setModalBody("Invalid mobile number. Mobile number should contain 10 digits");
          toggle();
          temperror = true;
        }
      }
    }
    if (!temperror) {
      const payload = {
        "blockId": blockid,
        "caseId": caseid,
        "formData": !showPetitionerForm ? getFormDataWithSingleParams() : getFormDataWithMultiParams(),
        "documents": getdocumentsArray(),
        "saveDraft": saveDraft,
        "remark": assignBlocksReinvestigatedCases?.formData && assignBlocksReinvestigatedCases?.formData?.remark ? assignBlocksReinvestigatedCases?.formData?.remark : '',
      }
      api.submitBlockFormDetails(payload).then(data => {
        if (data?.data?.success) {
          navigate(
              path.SUCCESS_PAGE,
              {state: {stateVal: redirectAssignCase()}}
            );
        }
        else {
          setModalTitle("Error");
          if (data?.data?.error && data?.data?.message) {
            setModalBody(data.data.message)
          }
          else {
            setModalBody('Please try after sometime');
          }
          toggle();
        }
      }).catch(err => {
        setModalTitle("Error");
        if (err?.response?.data?.message) {
          setModalBody(err?.response?.data?.message);
        } else {
          setModalBody('Please try after sometime');
        }
        toggle();
      });
    }
  }, [assignBlocksReinvestigatedCases?.formData, showPetitionerForm, blockid, caseid, getFormDataWithSingleParams, getFormDataWithMultiParams, getdocumentsArray, redirectAssignCase]);

  const uploadDocuments = useCallback(async(file, filetype) => {
    setShowLoader(true);
    await api.uploadDocuments(file).then(data => {
      if (data?.data?.success) {
        let array = assignBlocksReinvestigatedCases?.formData; // let array = JSON.parse(JSON.stringify(assignBlocksReinvestigatedCases.formData))
        let tempdata = [];
        Object.assign(tempdata, array?.singleParameters);
        for (let i = 0; i < tempdata?.length; i++) {
          if (tempdata[i]?.parameter_type === "upload" && tempdata[i]?.renamed_block_parameter === renamed_block_parameter) {
            let newdata = {
              document_name: data?.data?.data?.document_name,
              document_path: data?.data?.data?.document_path,
              document_size: data?.data?.data?.document_size,
              document_type: filetype,
            }
            tempdata[i].parameter_value.push(newdata)
          }
        }
        array.singleParameters = tempdata;
        setFormData(array);
        setOpen(!open);
        setShowLoader(false);
      } else {
        setModalTitle("Error");
        if (data?.data?.error && data?.data?.message) {
          setModalBody(data?.data?.message)
        }
        else {
          setModalBody('Please try after sometime');
        }
        toggle();
        setShowLoader(false);
      }
    }).catch(err => {
      setModalTitle("Error");
      if (err?.response?.data?.message) {
        setModalBody(err?.response?.data?.message);
      } else {
        setModalBody('Please try after sometime');
      }
      toggle();
      setShowLoader(false);
    });
  }, [assignBlocksReinvestigatedCases?.formData, renamed_block_parameter, open]);

  const onClose = useCallback((e,index,parentIndex) =>{
    e.stopPropagation();
    let array = JSON.parse(JSON.stringify(assignBlocksReinvestigatedCases?.formData));
    let temp = JSON.parse(JSON.stringify(assignBlocksReinvestigatedCases?.formData?.singleParameters));
    let tempindex = JSON.parse(JSON.stringify(temp[parentIndex]))
    let tempdoc = tempindex.parameter_value
    tempdoc.splice(index,1)
    tempindex.parameter_value = tempdoc
    temp[parentIndex] = tempindex
    array.singleParameters = temp
    setFormData(array)
  }, [assignBlocksReinvestigatedCases?.formData]);
  
  const handleGenFormInput = useCallback((value,index) => {
    let array = JSON.parse(JSON.stringify(assignBlocksReinvestigatedCases?.formData));
    let temp = JSON.parse(JSON.stringify(assignBlocksReinvestigatedCases?.formData?.singleParameters));
    temp[index].parameter_value = value
    array.singleParameters = temp
    setFormData(array)
  }, [assignBlocksReinvestigatedCases?.formData]);

  const onRemarkChange = useCallback((value) => {
    let array = assignBlocksReinvestigatedCases?.formData;
    let tempdata={};
    Object.assign(tempdata, array);
    tempdata.remark = value;
    setFormData(tempdata);
  }, [assignBlocksReinvestigatedCases?.formData]);

  return (
    <div className="assigned-blocks AssignedBlocksReinCases">
      <CommonNavbar title="Assigned Blocks" backButton={redirectAssignCase()} search={false} download={false} navbarname='assignBlocksReinvestigatedCases' />
      <div className="input-container">
        <div className="fir-gd-text">{blockname}</div>
        {!showPetitionerForm && assignBlocksReinvestigatedCases.formData && assignBlocksReinvestigatedCases.formData.singleParameters && assignBlocksReinvestigatedCases.formData.singleParameters.map((data,index) => {
          return <GenericForms ifRequired={true} key={data?.block_parameters_id} handleGenFormInput={(value)=>handleGenFormInput(value,index)} config={data} formName={'AssignedblockReincases'} saveFormData={saveFormData} formData={assignBlocksReinvestigatedCases.formData} formcolor={"rgba(235, 175, 0, 0.1)"} />
        })}
      </div>
      {showPetitionerForm && (
        <PetitionerForm
          ifRequired={true}
          formcolor={"rgba(235, 175, 0, 0.1)"}
          formName={'AssignedblockReincases'}
          datatestid='petitionerinput'
          datatestid2='petitionerinput2'
          petitioners={assignBlocksReinvestigatedCases?.formData}
          addAnother={addAnother}
          onPetRemove={(petIndex) => onPetRemove(petIndex)}
          handleChange={(value, index) => handleChange(value, index)}
          handlePetChange={(value, petIndex, petInpIndex) => handlePetChange(value, petIndex, petInpIndex)}
        />
      )}
      <AssignBlocksFormBottom onRemarkChange={onRemarkChange} border="1px solid #EBAF00" download={false} onClose={(e, i, j) => onClose(e, i, j)} remark={(assignBlocksReinvestigatedCases.formData && assignBlocksReinvestigatedCases.formData.remark ) ? assignBlocksReinvestigatedCases.formData.remark : ""} docs={assignBlocksReinvestigatedCases.formData.singleParameters ? assignBlocksReinvestigatedCases.formData.singleParameters : null} filterToggle={filterToggle} showUploadBtn={false} headerNotRequired={!showUploadBtn} showRemarks={true} />
      {open && (<FilterPopUp open={open} backgroundColor={'#8F949B80'} filterToggle={filterToggle} >
      <DocumentUpload uploadDocuments={uploadDocuments}  docsUploaded={docsUploaded} setDocsUploaded={setDocsUploaded}/>
      </FilterPopUp>)}
      {showLoader && <Loader />}
      <div className="AcceptButtons-ContainerParent">
        <div className="AcceptButtons-Container">
          <AcceptButtons disabled={!allParameterValuesNotEmpty} boxShadow={allParameterValuesNotEmpty ? '0px 4px 4px rgba(0, 0, 0, 0.25)': 'none'} background={allParameterValuesNotEmpty ? "rgb(233, 77, 81)" : "#F7BDBE"} color={allParameterValuesNotEmpty ? "rgb(255, 255, 255)" : "#E42125"} onClick={() => onSubmit(false)} data-testid="save-button">Submit</AcceptButtons>
          <AcceptButtons disabled={!oneParameterValuesNotEmpty} boxShadow={oneParameterValuesNotEmpty ? '0px 4px 4px rgba(0, 0, 0, 0.25)': 'none'} background={oneParameterValuesNotEmpty?"#339DFF":"#ACD7FF"} color={oneParameterValuesNotEmpty?"#FFF":"#339DFF"} onClick={() => onSubmit(true)} data-testid="save-button">Save</AcceptButtons>
        </div>
      </div>
      {showSuccessMessage && (
        <SuccessMessage message="Records Updated Successfully!" delay={3000} />
      )}
      <ErrorAlert
        modal={modal}
        toggle={toggle}
        modalTitle={modalTitle}
        modalBody={modalBody}
      />
    </div>
  );
}

AssignBlocksReinvestigatedCases.propTypes = {
  dispatch: PropTypes.func.isRequired
};

const mapStateToProps = createStructuredSelector({
  assignBlocksReinvestigatedCases: makeSelectAssignBlocksReinvestigatedCases(),
  employeeRole: getEmployeeRole(),
});

export function mapDispatchToProps(dispatch) {
  return {
    setFormData: (emp) => dispatch(formData(emp)),
    saveFormData: (data) => dispatch(setFormValue(data))
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);

export default compose(withConnect, memo)(AssignBlocksReinvestigatedCases);
