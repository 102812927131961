import { createSelector } from "reselect";
import { initialState } from "./reducer";

const selectUploadInvestigationReportDomain = state =>
  state.uploadInvestigationReport || initialState;

const makeSelectUploadInvestigationReport = () =>
  createSelector(
    selectUploadInvestigationReportDomain,
    substate => substate
  );

export default makeSelectUploadInvestigationReport;
export { selectUploadInvestigationReportDomain };
