import { createGlobalStyle } from "styled-components";
import "./css/font.css";
import "./css/variable.css";
import "./css/web.css";

const GlobalStyle = createGlobalStyle`


:root { font-family: 'Inter', sans-serif; }
@supports (font-variation-settings: normal) {
  :root { font-family: 'Inter var', sans-serif; }
}

  html{
    cursor: default;
  }

  html,
  body {
    height: 100%;
    width: 100%;
    line-height: 1.5;
  }

  body {
    font-family: 'Inter', sans-serif;
  }

  body.fontLoaded {
    font-family: 'Inter', sans-serif;
  }

  #root {
    background-color: #fafafa !important;
    min-height: 100%;
    min-width: 100%;
  }

  p,
  label {
    font-family: 'Inter', sans-serif;
    line-height: 1.5em;
  }
  sup {
    color: red;
    top: -2px;
    left: 2px;
  }
  @media screen and (max-width: 991px) {
    #root {
      background-color: #fff !important;
    }
  }
`;

export default GlobalStyle;
