import React, { memo } from "react";
import Text from "./Text";
import Radiobutton from './Radiobutton';
import MyDatePicker from './Datepicker';
import Textarea from './textarea';
import Dropdown from "./Dropdown";
import Number from "./Number";
import Hours from "./Hours";
import TimeConstant from "../../constants/hours"
import { formatTimeAmPm } from "../../constants/commonFunction";
export function GenericForms({ config, saveFormData ,formcolor, formData, formName,ifRequired,handleGenFormInput}) {
  switch (config.parameter_type) {
    case 'text':
      return <Text config={config} saveFormData={saveFormData} formcolor={formcolor} formData={formData} ifRequired={ifRequired} handleGenFormInput={(value) => handleGenFormInput(value)} />;
    case 'number':
      return <Number config={config} saveFormData={saveFormData} formcolor={formcolor} ifRequired={ifRequired} handleGenFormInput={(value) => handleGenFormInput(value)} />;
    case 'radio':
      return <Radiobutton config={config} saveFormData={saveFormData} formcolor={formcolor} ifRequired={ifRequired} handleGenFormInput={(value) => handleGenFormInput(value)} />;
    case 'date':
      return <MyDatePicker datetype="date" config={config} saveFormData={saveFormData} formcolor={formcolor} formName={formName ? formName : ''} ifRequired={ifRequired} handleGenFormInput={(value) => handleGenFormInput(value)} />;
    case 'past_date':
      return <MyDatePicker datetype="past_date" config={config} saveFormData={saveFormData} formcolor={formcolor} formName={formName ? formName : ''} ifRequired={ifRequired} handleGenFormInput={(value) => handleGenFormInput(value)} />;
    case 'future_date':
      return <MyDatePicker datetype="future_date" config={config} saveFormData={saveFormData} formcolor={formcolor} formName={formName ? formName : ''} ifRequired={ifRequired} handleGenFormInput={(value) => handleGenFormInput(value)} />;
    case 'date_and_time':
      return <MyDatePicker datetype="date_and_time" config={config} saveFormData={saveFormData} formcolor={formcolor} formName={formName ? formName : ''} ifRequired={ifRequired} handleGenFormInput={(value) => handleGenFormInput(value)} />;
    case 'past_date_and_time':
      return <MyDatePicker datetype="past_date_and_time" config={config} saveFormData={saveFormData} formcolor={formcolor} formName={formName ? formName : ''} ifRequired={ifRequired} handleGenFormInput={(value) => handleGenFormInput(value)} />;
    case 'future_date_and_time':
      return <MyDatePicker datetype="future_date_and_time" config={config} saveFormData={saveFormData} formcolor={formcolor} formName={formName ? formName : ''} ifRequired={ifRequired} handleGenFormInput={(value) => handleGenFormInput(value)} />;
    case 'textarea':
      return <Textarea config={config} saveFormData={saveFormData} formcolor={formcolor} handleGenFormInput={(value) => handleGenFormInput(value)} />;
    case 'select':
      return <Dropdown config={config} saveFormData={saveFormData} formcolor={formcolor} ifRequired={ifRequired} handleGenFormInput={(value) => handleGenFormInput(value)} />
    case 'time':
      config.parameter_options = TimeConstant;
      config.parameter_value = formatTimeAmPm(config.parameter_value);
      return <Hours config={config} saveFormData={saveFormData} formcolor={formcolor} formName={formName ? formName : ''} ifRequired={ifRequired} handleGenFormInput={(value) => handleGenFormInput(value)} />;
    default:
      return null;
  }
}

GenericForms.propTypes = {};

export default memo(GenericForms);