import React, { memo, useState } from "react";
import { Input } from "reactstrap";
import "./index.css";
import { textParameterName } from "../../containers/AssignedBlocksAllottedCases/constants";
const Text = memo((props) => {
  const [textVal, setTextVal] = useState(props.config.parameter_value)
  const regexNumberSpecialChar = /[0-9!@#$%^&*()_+{}\[\]:;<>,.?~\\\-]/;
  return (
    <div className="textinput-genric-main">
      <label className="Genericforms-text">
        {props.config.renamed_block_parameter}
        {props.ifRequired && <sup>*</sup>}
      </label>
        <Input
          required={props.ifRequired}
          className="Genericforms-input"
          style={{
            backgroundColor: props.formcolor
              ? props.formcolor
              : "rgba(5, 138, 238, 0.1)",
            border: "none",
          }}
          data-testid="genericinput"
          type="text"
          defaultValue={textVal}
          value={textVal}
          onChange={(evt) => {
            if(textParameterName.includes(props.config.parameter_name) && evt.target.value.match(regexNumberSpecialChar)){
              return
            } 
            if(props.config.block_parameters_id === 116){
              setTextVal(evt.target.value.toUpperCase())
              props.handleGenFormInput(evt.target.value.toUpperCase())
              return
            } 
              setTextVal(evt.target.value);
              props.handleGenFormInput(evt.target.value)
            
          }}
        />
    </div>
  );
});

Text.propTypes = {};

export default Text;
