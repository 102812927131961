import React from "react";

function Filtericon() {
  return (
    <svg
      data-testid="filter-icon"
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="20"
      fill="none"
      viewBox="0 0 16 20"
    >
      <path
        fill="#E94D51"
        d="M5 3a1 1 0 100 2 1 1 0 000-2zM2.17 3a3.001 3.001 0 015.66 0H15a1 1 0 110 2H7.83a3.001 3.001 0 01-5.66 0H1a1 1 0 010-2h1.17zM11 9a1 1 0 100 2 1 1 0 000-2zM8.17 9a3.001 3.001 0 015.66 0H15a1 1 0 110 2h-1.17a3 3 0 01-5.66 0H1a1 1 0 110-2h7.17zM5 15a1 1 0 100 2 1 1 0 000-2zm-2.83 0a3 3 0 015.66 0H15a1 1 0 010 2H7.83a3 3 0 01-5.66 0H1a1 1 0 110-2h1.17z"
      ></path>
    </svg>
  );
}

export default Filtericon;