import { createSelector } from "reselect";
import { initialState } from "./reducer";

const selectAddNewInvoiceDomain = state =>
  state.addNewInvoice || initialState;

const makeSelectAddNewInvoice = () =>
  createSelector(
    selectAddNewInvoiceDomain,
    substate => substate
  );

const selectForms = () =>
  createSelector(
    selectAddNewInvoiceDomain,
    substate => { return substate.formData2 }
  );

  const setvaluefetched = () =>
  createSelector(
    selectAddNewInvoiceDomain,
    substate => {
      return substate.valuefetched;
    }
  );

export default makeSelectAddNewInvoice;
export { selectAddNewInvoiceDomain, selectForms, setvaluefetched };
