import { createSelector } from "reselect";
import { initialState } from "./reducer";

const selectAssignedBlocksAllottedCasesDomain = state =>
  state.assignedBlocksAllottedCases || initialState;

const makeSelectAssignedBlocksAllottedCases = () =>
  createSelector(
    selectAssignedBlocksAllottedCasesDomain,
    substate => substate
  );

const selectForms = () =>
  createSelector(
    selectAssignedBlocksAllottedCasesDomain,
    substate => { return substate.formData2 }
  );

export default makeSelectAssignedBlocksAllottedCases;
export { selectAssignedBlocksAllottedCasesDomain, selectForms };
