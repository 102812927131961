import React, { useContext, useEffect, useState } from 'react';
import "./index.css";
import { ApiDispatchContext } from '../../context/ApiContext';
import ErrorAlert from '../ErrorAlert';
import { imageBaseUrl } from '../../utils/constants';

export const ProfileImg = (props) => {
  const [preview, setPreview] = useState("");
  const api = useContext(ApiDispatchContext);
  const [modal, setModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalBody, setModalBody] = useState("");
  const toggle = () => setModal(!modal);
    
  useEffect(() => {
    if(props.filename) {
      let payload = {
        "fileName": props.filename ? props.filename : ""
      }
      api.viewDocuments(payload).then(data => {
        if (data.data && data.data.success) {
          setPreview(data.data.data[0]);
        } else {
          setModalTitle("Error");
          if (data.data.error && data.data.message) {
            setModalBody(data.data.message)
          } else {
            setModalBody('Please try after sometime');
          }
          toggle();
        }
      }).catch(err => {
        setModalTitle("Error");
        if (err && err.response && err.response.data && err.response.data.message) {
          setModalBody(err.response.data.message);
        } else {
          setModalBody('Please try after sometime.');
        }
        toggle();
      })
    } else {
      setPreview(`${imageBaseUrl}/avatar.png`)
    }
  }, [props.filename]);

  const handleUserImgChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = function(event) {
        setPreview(event.target.result);
      };
      reader.readAsDataURL(file);
      props.onProfilePicHandler(file);
    }
  };
  
  return (
    <>
      <div className="profile-img-circle test123">
        <span>
          <img alt="preview" src={preview} />
        </span>
        <label htmlFor="profile-img" className="edit-img">
          <input id="profile_img" type="file" data-testid="profile_img" accept=".jpg,.jpeg,.png,.svg" onChange={handleUserImgChange}/>
          <span>+</span>
        </label>
      </div>
      <ErrorAlert
        modal={modal}
        toggle={toggle}
        modalTitle={modalTitle}
        modalBody={modalBody}
      />
    </>
  )
}

export default ProfileImg;