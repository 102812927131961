import PropTypes from 'prop-types'
import React, { memo, useCallback, useEffect, useState } from 'react'
import { DropdownMenu, DropdownItem, ButtonDropdown, DropdownToggle } from "reactstrap"

export const Dropdown = memo((props) => {
    const [dropdownOpen, setOpen] = useState(false);
    let headername = props.config ? props.config.renamed_block_parameter : '';
    const [selectedValue, setSelectedValue] = useState(props?.config?.parameter_value !== "" ? props?.config?.parameter_value : 'Please select ' + headername)

    const selectDropdown = useCallback((val) => {
        setSelectedValue(val.value)
        let temp = JSON.parse(JSON.stringify(props?.data));
        temp[props.i].parameter_value = val?.value;
        temp[props.i].id = val?.id;
        temp[props.i+1].isDisabled = false;
        props.setData(temp,props?.i)
    }, [props?.data, props?.i]);

    function backgroundColor() {
        return props?.casetype === "opencase" ? "rgba(26, 178, 117, 0.1)" : props?.casetype === "allottedcase"?'rgba(5, 138, 238, 0.1)':"rgba(235, 175, 0, 0.1)"
    }

    return (
        <div className='dd submit-dropdown'>
            <label className='label-text'>{props.config.renamed_block_parameter}<span style={{color:"red"}}>*</span></label>
            <ButtonDropdown disabled={props.config.isDisabled} className={props.config.isDisabled ? 'disabled' : ''} data-testid="dropdownItem22" toggle={() => { setOpen(!dropdownOpen) }}
                isOpen={dropdownOpen}>
                <DropdownToggle className="" caret style={{ backgroundColor: backgroundColor() }}>
                    {props.config.parameter_value !== "" ? props.config.parameter_value : 'Please select ' + headername}
                </DropdownToggle>
                <DropdownMenu style={{ backgroundColor: backgroundColor() }} className="generic-dropdownitems-main">
                    {props.config.parameter_options && props.config.parameter_options.map(data => {
                        return <DropdownItem data-testid="dropdownItem" key={data.id} onClick={() => selectDropdown(data)} className="generic-dropdownitems">{data.value}</DropdownItem>
                    })}
                </DropdownMenu>
            </ButtonDropdown>
        </div>
    )
})

Dropdown.propTypes = {};

export default Dropdown;