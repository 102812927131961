import React from "react";
import Text from "./Text";
import Dropdown from "./Dropdown";
import MyDatePicker from "./Datepicker";
import Radiobutton from "./Radiobutton";
import Number from './Number';
import Hours from "./Hours";
import Textarea from "./textarea";

export function GenricPetitionerForm({index, config,formcolor,showLabel,handleChange,formName,ifRequired, isDisabled}) {
  switch (config.parameter_type) {
    case 'text':
      return <Text isDisabled={isDisabled} config={config} formcolor={formcolor} handleInput={(value) => handleChange(value)} showLabel={showLabel} ifRequired={ifRequired} />;
    case 'number':
      return <Number isDisabled={isDisabled} config={config} formcolor={formcolor} handleInput={(value) => handleChange(value)} showLabel={showLabel} ifRequired={ifRequired} />;
    case 'radio':
      return <Radiobutton isDisabled={isDisabled} index={index} config={config} formcolor={formcolor} handleInput={(value) => handleChange(value)} showLabel={showLabel} ifRequired={ifRequired} />;
    case 'past_date':
      return <MyDatePicker isDisabled={isDisabled} datetype="past_date" config={config} formcolor={formcolor} handleInput={(value) => handleChange(value)} showLabel={showLabel} formName={formName ? formName : ''} ifRequired={ifRequired} />;
    case 'future_date':
      return <MyDatePicker isDisabled={isDisabled} datetype="future_date" config={config} formcolor={formcolor} handleInput={(value) => handleChange(value)} showLabel={showLabel} formName={formName ? formName : ''} ifRequired={ifRequired} />;
    case 'date_and_time':
      return <MyDatePicker isDisabled={isDisabled} datetype="date_and_time" config={config} formcolor={formcolor} handleInput={(value) => handleChange(value)} showLabel={showLabel} formName={formName ? formName : ''} ifRequired={ifRequired} />;
    case 'past_date_and_time':
      return <MyDatePicker isDisabled={isDisabled} datetype="past_date_and_time" config={config} formcolor={formcolor} formName={formName ? formName : ''} showLabel={showLabel} ifRequired={ifRequired} handleInput={(value) => handleChange(value)} />;
    case 'future_date_and_time':
      return <MyDatePicker isDisabled={isDisabled} datetype="future_date_and_time" config={config} formcolor={formcolor} formName={formName ? formName : ''} showLabel={showLabel} ifRequired={ifRequired} handleInput={(value) => handleChange(value)} />;
    case 'date':
      return <MyDatePicker isDisabled={isDisabled} config={config} formcolor={formcolor} handleInput={(value) => handleChange(value)} showLabel={showLabel} formName={formName ? formName : ''} ifRequired={ifRequired} />;
    case 'textarea':
      return <Textarea isDisabled={isDisabled} config={config} formcolor={formcolor} showLabel={showLabel} ifRequired={ifRequired} />;
    case 'select':
      return <Dropdown isDisabled={isDisabled} config={config} formcolor={formcolor} handleInput={(value) => handleChange(value)} showLabel={showLabel} ifRequired={ifRequired} />
    case 'time':
      return <Hours isDisabled={isDisabled} config={config} formcolor={formcolor} handleInput={(value) => handleChange(value)} showLabel={showLabel} formName={formName ? formName : ''} ifRequired={ifRequired} />;
    default:
      return null;
  }
}

export default GenricPetitionerForm