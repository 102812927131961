import { DEFAULT_ACTION , CLAIMNUMBERUPDATE} from "./constants";

export function defaultAction() {
  return {
    type: DEFAULT_ACTION
  };
}
export function claimnumberUpdate(payload) {
  return {
    type: CLAIMNUMBERUPDATE,
    payload:payload
  };
}