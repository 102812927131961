import React from 'react';
import './case.css';
function Case(props) {
  return (
    <div className={props.employeeRole == "vendor"?'case':"case-investigator"} style={{ backgroundColor: props.bgColor,boxShadow:props.boxShadow, color: props.textcolor ? props.textcolor : '#fff' }} onClick={props.onClick} data-testid="caseinput">
      <span className='case-number' style={{color:props.color}} >{props.caseNumber}</span>
      <span className='case-type' style={{color:props.color}}>{props.cases}</span> 
    </div>
  )
}

export default Case