import produce from "immer";
import { DEFAULT_ACTION, CLAIMNUMBERUPDATE } from "./constants";

export const initialState = {
  claimNumber: '',
};

const selectOpenCasesDetailsPageDomain = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case DEFAULT_ACTION:
        break;

      case CLAIMNUMBERUPDATE:
        draft.claimNumber = action.payload;
        break;
    }
  });

export default selectOpenCasesDetailsPageDomain;
