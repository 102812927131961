import React from 'react';
import A from './A';
import NavBar from './NavBar';
import HeaderLink from './HeaderLink';
import { imageBaseUrl } from '../../utils/constants';

function Header() {
  return (
    <div>
      <A href="https://www.reactboilerplate.com/">
      </A>
      <NavBar>
      <HeaderLink to="/" className="HeaderLink">
        <img src={`${imageBaseUrl}/Logo2.png`} alt="imgage" className="imageLogoMain"/>
        </HeaderLink>
      </NavBar>
    </div>
  );
}

export default Header;
