import React, { useState, memo } from 'react';
import { DropdownMenu, DropdownItem, ButtonDropdown, DropdownToggle} from "reactstrap";

const Dropdown = memo((props) => {
    const [dropdownOpen, setOpen] = useState(false);
    let headername = props?.config ? props?.config?.renamed_block_parameter :'';
    const [selectedValue, setSelectedValue] = useState(props?.config?.parameter_value ? props?.config?.parameter_value : 'Please select '+headername)
    const selectDropdown = (val) => {
        props.handleInput(val)
        setSelectedValue(val)
    }
    return (
        <div className='petdropdown genericpet-petdropdown'>
            {props?.showLabel && (<label className='label-text'>{props?.config?.renamed_block_parameter}{props?.ifRequired && <sup>*</sup>}</label>)}
            <ButtonDropdown data-testid="dropdownItem" toggle={() => { setOpen(!dropdownOpen) }} 
                isOpen={dropdownOpen}>
                <DropdownToggle disabled={props?.isDisabled} className="" caret style={{backgroundColor: props?.formcolor ? props?.formcolor : 'rgba(5, 138, 238, 0.1)',border:"none"}}>
                    {selectedValue}
                </DropdownToggle>
                <DropdownMenu style={{backgroundColor: props?.formcolor ? props?.formcolor : 'rgba(5, 138, 238, 0.1)',border:"none"}} className="generic-petdropdownitems-main">
                    {props?.config?.parameter_options && props?.config?.parameter_options.map(data => {
                        return <DropdownItem data-testid="petdropdownItem" key={data} onClick={() => selectDropdown(data)} className="generic-petdropdownitems">{data}</DropdownItem>
                    })}
                </DropdownMenu>
            </ButtonDropdown>
        </div>
    )
})

Dropdown.propTypes = {};

export default Dropdown;