import React, { useEffect, useState, useContext, memo } from 'react';
import { ApiDispatchContext } from "../../../../context/ApiContext";
import ErrorAlert from "../../../../components/ErrorAlert";
import { getUrlParameter } from "../../../../constants/commonFunction";
import '../cardtab.css';
import '../../../AllottedCasesDetailsPage/CardTabs/cardtab.css';
import propTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { compose } from 'redux';
import SuccessMessage from "../../../../components/SuccessMssgDisplay";
import Loader from '../../../../components/Loader';
import NewBlocksAssigned from './NewBlocksAssigned';

export const Investigation = ({redirectPath}) => {
  const api = useContext(ApiDispatchContext);
  const [modal, setModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalBody, setModalBody] = useState("");
  const toggle = () => setModal(!modal);
  const [itemsToDisplay, setitemsToDisplay] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  let casid = Number(getUrlParameter('case'));

  useEffect(() => {
    if (casid) {
      let payload = {
        "caseId": casid,
        "assignedToInvestigator": false,
        "page": 1,
        "pageSize": 10,
      }
      setShowLoader(true);
      api.getAssignedInvestigatorsDetails(payload).then(data => {
        if (data.data && data.data.success) {
          setShowLoader(false);
          setitemsToDisplay(data.data.data);
        } else {
          setModalTitle("Failed to load open cases data.");
          if(data.data.error && data.data.message){
            setModalBody(data.data.message)
          }
          else{
            setModalBody('Please try after sometime');
          }
          toggle();
          setShowLoader(false);
        }
      }).catch(err => {
        setModalTitle("Failed to load open cases data.");
        if (err && err.response && err.response.data && err.response.data.message) {
          setModalBody(err.response.data.message);
        } else {
          setModalBody('Please try after sometime.');
        }
        toggle();
        setShowLoader(false);
      })
    }
  }, [casid]);
  
  return (
    <div className='openinvclaim-data'>
      {itemsToDisplay.assigned_blocks && itemsToDisplay.assigned_blocks.count > 0 && <NewBlocksAssigned assigned_blocks={itemsToDisplay.assigned_blocks} />}
      {showLoader && <Loader />}
      <ErrorAlert
        modal={modal}
        toggle={toggle}
        modalTitle={modalTitle}
        modalBody={modalBody}
      />
      {showSuccessMessage && (
        <SuccessMessage message="Records Updated Successfully!" delay={3000} />
      )}
    </div>
  )
}

Investigation.propTypes = {
  redirectPath: propTypes.func,
}

const mapStateToProps = createStructuredSelector({
});

export function mapDispatchToProps(dispatch) {
  return {
    redirectPath: (path) => dispatch(push(path)),
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(withConnect, memo,)(Investigation);