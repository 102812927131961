import { createSelector } from "reselect";
import { initialState } from "./reducer";

const selectEmploymentMasterPageDomain = state => {
  const storedData = JSON.parse(localStorage.getItem('Employment-master')); // Get stored data from local storage
  return storedData || state.employmentMasterPageReducer || initialState; // Use stored data if available, or fallback to state or initial state
}

const makeSelectEmployee = () =>
  createSelector(
    selectEmploymentMasterPageDomain,
    substate => substate.employee
  );

const makeSelectEmployeeIds = () =>
  createSelector(
    selectEmploymentMasterPageDomain,
    substate => substate.ids
  )
  
export { selectEmploymentMasterPageDomain, makeSelectEmployee, makeSelectEmployeeIds };