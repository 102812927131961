import produce from "immer";
import { DEFAULT_ACTION, UPDATE_EMPLOYEE,UPDATE_EMPLOYEE_IDS } from "./constants";

export const initialState = {
  employee: {},
  ids: []
};

const employmentMasterPageReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case DEFAULT_ACTION:
        break;
      case UPDATE_EMPLOYEE:
        draft.employee = action.employee;
         const storedData = JSON.parse(localStorage.getItem('Employment-master')) || {}; 
         storedData.employee = action.employee;
         localStorage.setItem('Employment-master', JSON.stringify(storedData)); 
        break;
      case UPDATE_EMPLOYEE_IDS:
        draft.ids = action.ids;
        break;
    }
  });

export default employmentMasterPageReducer;
