import React, { useCallback } from 'react';
import './EmployeeMasterCard.css';
import CustomAvatar from '../../../components/CustomAvatar';
import { imageBaseUrl } from '../../../utils/constants';

function EmployeeMasterCard(props) {

    const statusView = useCallback((status) => {
        if(status === "active"){
            return "Active";
        } else if (status === "inactive"){
            return "Inactive";
        } else if (status === "onleave") {
            return "On Leave";
        } else {
            return "Inactive"
        }
    }, []);

    const handleHighlight = useCallback((value) => {
        if(props?.searchValue && props?.searchValue == value) {
            return "highlight";
        } else {
            return "";
        }
    }, [props?.searchValue]);

    return (
        <div className={props?.changeStatus && props?.changeStatus === true ? 'empMasterCard chageStatusCard': 'empMasterCard'}>
            {props?.changeStatus === true && 
            <button data-testid="removeCardFromStatusList" className='closeIcon' onClick={() => props?.removeCardFromStatusList(props.index)}>
                <img src={`${imageBaseUrl}/close.png`} alt="close" />
            </button>}
            <div className="empMasterCard-profile">
                <CustomAvatar size="50" round={true} filename={props?.filename} />
                <button className={`empMasterCard-profile-btn ${props?.status === "active" ? "active" : props?.status === "onleave" ? "onleave" : "inactive"}`}>{statusView(props?.status)}</button> 
            </div>
            <div className="empMasterCard-container">
                   <div className="empMasterCard-line1">
                <div className='empMasterCard-child'>
                    <span>Sr. No</span>
                    <strong className={handleHighlight(props?.srno)}>{props?.srno}</strong>
                </div>
                <div className='empMasterCard-child'>
                    <span>Vendor Parent Name</span>
                    <strong className={handleHighlight(props?.vendor_parent_name)}>{props?.vendor_parent_name}</strong>
                </div>
                <div className='empMasterCard-child'>
                    <span>Vendor State</span>
                    <strong className={handleHighlight(props?.vendor_state)}>{props?.vendor_state}</strong>
                </div>
                </div>
                <div className="empMasterCard-line2">
                <div className='empMasterCard-child'>
                    <span>Vendor City</span>
                    <strong className={handleHighlight(props?.vendor_city)}>{props?.vendor_city}</strong>
                </div>
                <div className='empMasterCard-child'>
                    <span>Investigator Name</span>
                    <strong className={handleHighlight(props?.investigator_name)}>{props?.investigator_name}</strong>
                </div>
                <div className='empMasterCard-child'>
                    <span>State</span>
                    {props?.location.map(item => (
                        <strong key={item?.state} title={item?.state} className={handleHighlight(item?.state)}>{item?.state?.length < 13 ? item?.state : item?.state?.slice(0, 12) + "..."} </strong>
                    ))}
                </div>
                <div className='empMasterCard-child'>
                    <span>City</span>
                    {props?.location.map((item, i) => (
                        <strong key={`${item.state}-${i}`}>{item?.cities?.length}</strong>
                    ))}
                </div>
                </div>
                <div className="empMasterCard-line3">
                <div className='empMasterCard-child'>
                    <span>Reg.Email Id</span>
                    <strong className={handleHighlight(props?.email)}>{props?.email?.length < 22 ? props?.email : props?.email.slice(0,21)+".."}</strong>
                </div>
                <div className='empMasterCard-child'>
                    <span>Reg.Mobile No</span>
                    <strong className={handleHighlight(props?.mobile_number)}>{props?.mobile_number}</strong>
                </div>
                </div>
            </div>
        </div>
    )
}

export default EmployeeMasterCard;