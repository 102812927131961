import React, { memo , useState} from 'react';
import { Input } from 'reactstrap';
import './index.css';
const Number = memo((props) => {
  const [mobileNumber, setMobileNumber] = useState(props?.config?.parameter_value);
  const handleChange = (event) => {
    let input = event.target.value;
    if(props?.config?.max_length){
      const cleanedInput = input.replace(/\D/g, '');  
      input = cleanedInput.slice(0, props?.config?.max_length);
    }
    setMobileNumber(input);
    props.handleInput(input);
    
  };

  const handleKeyDown = (e) => {
    if (e.key === "e" || e.key === "-" || e.key === ".") {
      e.preventDefault();
    }
  };
  
  return (
    <div className={`numberinput-genric-main ${props?.config?.max_length===10?"numberinput-genric-mb-main":""}`}>
      {props?.showLabel && ( <label className='Genericforms-text'>{props?.config?.renamed_block_parameter}{props?.ifRequired && <sup>*</sup>}</label>)}
      <Input
        disabled={props?.isDisabled}
        className='Genericforms-input'
        type='Number'
        data-testid="genericinput2"
        onKeyDown={handleKeyDown}
        defaultValue = {props?.config?.parameter_value}
        placeholder={props?.config?.renamed_block_parameter}
        style={{backgroundColor: props?.formcolor ? props?.formcolor :'rgba(5, 138, 238, 0.1)',border:"none"}}
        onChange={(evt) => handleChange(evt)}
        min={'0'}
        pattern="[0-9]{10}"
        value={mobileNumber}
      />
    </div>
  )
})

Number.propTypes = {};

export default Number;