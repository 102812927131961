import React, { useState, useEffect, useContext, memo } from 'react';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { ApiDispatchContext } from '../../context/ApiContext';
import './index.css';
import Dropdown from "./Dropdown";
import { compose } from 'redux';
import ErrorAlert from '../ErrorAlert'
import Loader from '../Loader';
import propTypes from 'prop-types';
import CustomFileViewer from '../CustomFileViewer';
import { getEmployeeRole } from '../../containers/App/selectors';
import Date from './Date';

export function FilterActions({caseType, employeeRole,submitFilterform, filterform,datepickerClicked, onResetFilter}) {
  const actionsUrl = []; 
  const [viewFileModal, setViewFileModal] = useState(false);
  const api = useContext(ApiDispatchContext);
  const [modal, setModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalBody, setModalBody] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const [filters,setFilters] = useState(actionsUrl)
  const toggle = () => setModal(!modal);
  const [enablebutton, setenablebutton] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
    setShowLoader(true);
    if(filterform.length >0){
      setShowLoader(false);
      setenablebutton(true);
      setFilters(filterform);
    } else {
    api.getFilterForm({caseType: caseType}).then(data => {
      setShowLoader(false);
      if (data.data.success) {
        let temp = data.data.data;
        let Filterdata = temp.map((filter, i) => {
          return { ...filter, show: false, selectedvalue1: '', selectedvalue2: '' };
        });
        Filterdata.unshift({ label: 'Date', value: '', show: false, selectedvalue1: '', selectedvalue2: '' });
        setFilters(Filterdata);
      }
    }).catch(err=>{
      setFilters([])
      setShowLoader(false);
      setModalTitle("Error");
      if(err && err.response && err.response.data && err.response.data.message){
        setModalBody(err.response.data.message);
      } else {
        setModalBody('Please try after sometime.');
      }
      toggle();
    });
  }
  }, []);

  const filePath = "";

  const onFilterClick = (index) => {
    setFilters((prevFilters) =>
      prevFilters.map((filter, i) =>
        i === index ? { ...filter, show: true } : { ...filter, show: filter.selectedvalue1 === '' ? false : true }
      )
    );
  };

  const reset = ()=>{
    setenablebutton(false);
    let newdata =  filters.map((filter, i) =>{
      return  { ...filter, show: false, selectedvalue1: '', selectedvalue2: '' }
    })
    setFilters(newdata);
    onResetFilter();
  }

  const apply = ()=>{
    submitFilterform(filters);
  }

  const dropdownselected =(name,value) =>{
    setenablebutton(true);
    let newdata = filters.map((filter, i) => {
      if(filter.label === name){
      return { ...filter, selectedvalue1: value, selectedvalue2: '' };
      } else return { ...filter };
    })
    setFilters(newdata)
  }

  const setNewdate2 =(name, date) =>{
    setenablebutton(true);
    datepickerClicked(false)
    let newdata = filters.map((filter, i) => {
      if(filter.label === name){
      return { ...filter, selectedvalue2: date };
      } else return { ...filter };
    })
    setFilters(newdata)
  }

  const setNewdate1 =(name, date) =>{
    setenablebutton(true);
    datepickerClicked(false)
    let newdata = filters.map((filter, i) => {
      if(filter.label === name){
      return { ...filter, selectedvalue1: date,};
      } else return { ...filter };
    })
    setFilters(newdata)
  }

  return (
    <>
      <div data-testid="filter-actions-container" className='filters' style={{paddingBottom:10}}>
        <span className='newfilters-title'>Filter By</span>
        {filters && filters.length > 0 && filters.map((ele, index) => (
          <div style={{marginBottom:6,marginTop:6}}>
          <span key={index} data-testid='filters-action' className={filters.every(item => item.show === false)?'filters-filter-selected':ele.show?'filters-filter-selected':'filters-filter'} onClick={()=>onFilterClick(index)}>{ele.label}</span>
          {(ele.label === "Date" && ele.show) && <Date datepickerClicked={datepickerClicked} date1={ele.selectedvalue1} date2={ele.selectedvalue2} setNewdate1={(date) => setNewdate1(ele.label,date)} setNewdate2={(date) => setNewdate2(ele.label,date)} />}
          {ele.label !== "Date" && ele.show ? (
            <Dropdown 
            label = {ele.label}
            value={ele.selectedvalue1}
            showArray={ele.value}
            dropdownselected={(value) => dropdownselected(ele.label,value)}
            />
          ):null}
          </div>
        ))}
        {enablebutton && (
          <div className='filters-btns'>
          <button className='filter-btn-reset' data-testid='filter-btn-reset' onClick={reset}>Reset</button>
          <button className='filter-btn-apply' data-testid='filter-btn-apply' onClick={apply}>Apply</button>
        </div>
        )}
      </div>
      {showLoader && <Loader />}
      <ErrorAlert
        modal={modal}
        toggle={toggle}
        modalTitle={modalTitle}
        modalBody={modalBody}
      />
      <CustomFileViewer download={false} DownloadDocs={null} fileType={"pdf"} filePath={filePath} setViewFileModal={setViewFileModal} viewFileModal={viewFileModal} />
    </>
  )
}

FilterActions.propTypes = {
  employeeRole: propTypes.string
};

const mapStateToProps = createStructuredSelector({
  employeeRole: getEmployeeRole()
});

export function mapDispatchToProps(dispatch) {
  return {
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(withConnect, memo,)(FilterActions);