export const getEmpInfo = (parameter) => {
    const formettedData = parameter?.vendor_employees?.reduce((acc, emp) => {
        const existingEmp = acc.find(
          (e) => e?.vendor_employee_id === emp?.vendor_employee_id
        );
      
        if (existingEmp) {
          const existingState = existingEmp?.location?.find(
            (loc) => loc?.state === emp?.state
          );
      
          if (existingState) {
            existingState?.cities?.push(emp?.city);
          } else {
            existingEmp?.location?.push({
              state: emp?.state,
              cities: [emp?.city],
            });
          }
        } else {
          acc.push({
            srno: acc?.length+1,
            status: emp?.status,
            vendor_employee_id: emp?.vendor_employee_id,
            investigator_name: emp?.investigator_name,
            mobile_number: emp?.mobile_number,
            email: emp?.email,
            location: [
              {
                state: emp?.state,
                cities: [emp?.city],
              },
            ],
          });
        }
      
        return acc;
      }, []);

      return formettedData;
}