import React, { memo, useCallback, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Card, CardBody, Row } from "reactstrap";
import CardField from "../CardField/CardField";
import Icon from "../Icon";
import { createStructuredSelector } from "reselect";
import { useNavigate } from "react-router-dom";
import "./CommonCaseCard.css";
import { makeSelectCaseSearchValue } from "../../containers/SearchCase/selectors";
import { compose } from "redux";
import { makeMultiSelectCases } from "../../containers/OpenCases/selectors";
import { getEmployeeRole } from "../../containers/App/selectors";
import { imageBaseUrl } from "../../utils/constants";
import InfoIcon from "../../../src/info.png";
import ErrorAlert from "../ErrorAlert";

export const CommonCaseCard = ({
  cardDetail,
  btn,
  caseType,
  searchValue,
  path,
  showCaseType,
  buttonClass,
  navigateParams,
  employeeRole,
  caseidArr,
  handleMultiCaseSelect,
  showSelect,
}) => {
  const [modal, setModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("Manual Trigger Remarks");
  const [modalBody, setModalBody] = useState("");

  const navigate = useNavigate();
  const toggle = () => setModal(!modal);

  const caseNumber = useMemo(
    () => cardDetail.find((item) => item.label === "Case Num")?.value || "-",
    [cardDetail]
  );

  const caseNo = useMemo(
    () => cardDetail.find((item) => item.label === "Case No.")?.value || "-",
    [cardDetail]
  );

  const navigateToDetails = useCallback(() => {
    const subProduct = cardDetail.find(
      (item) => item.label === "Sub-Product"
    ).value;
    const lobType = cardDetail.find((item) => item.label === "LOB")?.value;
    localStorage.setItem("lobType", lobType);
    const lob = lobType === "A&H" ? "AH" : lobType;
    navigate(
      path +
        "?" +
        btoa(
          `case=${caseNo}&lob=${lob}&subProduct=${subProduct}${
            navigateParams || ""
          }`
        )
    );
  }, [caseNo, cardDetail, navigateParams]);

  const { LOB, manualTriggerRemark } = useMemo(() => {
    const result = {};

    cardDetail.forEach((item) => {
      if (item.label === "LOB") {
        result.LOB = item.value;
      } else if (item.label === "Manual Trigger Remark") {
        result.manualTriggerRemark = item.value;
      }
    });

    return result;
  }, [cardDetail]);

  return (
    <>
      <Card
        className={
          showSelect && !caseidArr?.includes(caseNumber)
            ? "select-card-wrap"
            : ""
        }
      >
        {showSelect && employeeRole === "vendor" && (
          <button
            data-testid="handleMultiCaseSelect"
            className="card-shadow"
            onClick={() => handleMultiCaseSelect(caseNo)}
          >
            <div className="center-circle">
              {caseidArr?.includes(caseNo) && (
                <img
                  src={`${imageBaseUrl}/white-circle-tick.svg`}
                  alt="CircleTick"
                />
              )}
            </div>
          </button>
        )}
        {showCaseType && (
          <div className={`caseTypeName${caseType}`}>{caseType}</div>
        )}
        <CardBody style={{ position: "relative" }}>
          <Row style={{ padding: 0, margin: 0 }}>
            <div className="card-case" id="info">
              <div>
                <span className="card-case-lable">
                  {cardDetail[1]?.label} :
                </span>{" "}
                <span
                  className={
                    searchValue && cardDetail[0]?.value === searchValue
                      ? "card-case-value highlight"
                      : "card-case-value"
                  }
                >
                  {cardDetail[0]?.value ? cardDetail[0]?.value : "-"}
                </span>
              </div>
              <div id="tooltip-position">
                {manualTriggerRemark && LOB === "A&H" && (
                  <img
                    src={InfoIcon}
                    onClick={() => {
                      setModalBody(manualTriggerRemark);
                      toggle();
                    }}
                    alt="Info Icon"
                    style={{
                      width: "14px",
                      height: "14px",
                    }}
                  />
                )}
              </div>
            </div>
            {cardDetail?.length > 0 &&
              cardDetail
                ?.filter((item) => item.label !== "Manual Trigger Remark")
                ?.map(
                  (item, index) =>
                    index > 1 && (
                      <CardField key={item.label} item={item} index={caseNo} />
                    )
                )}
          </Row>
          {btn && (
            <div className="details-view-btn">
              <button
                data-testid="btn-view"
                className={`${buttonClass} view-btn`}
                onClick={navigateToDetails}
              >
                <Icon /> View
              </button>
            </div>
          )}
        </CardBody>
      </Card>
      <ErrorAlert
        modal={modal}
        toggle={toggle}
        modalTitle={modalTitle}
        modalBody={modalBody}
        isImage={false}
      />
    </>
  );
};

CommonCaseCard.propTypes = {
  cardDetail: PropTypes.array,
  btn: PropTypes.bool,
  caseType: PropTypes.string,
  searchValue: PropTypes.string,
  path: PropTypes.string,
  showCaseType: PropTypes.bool,
  buttonClass: PropTypes.string,
  navigateParams: PropTypes.string,
  employeeRole: PropTypes.string,
  caseidArr: PropTypes.array,
  handleMultiCaseSelect: PropTypes.func,
  showSelect: PropTypes.bool,
};

const mapStateToProps = createStructuredSelector({
  searchValue: makeSelectCaseSearchValue(),
  caseidArr: makeMultiSelectCases(),
  employeeRole: getEmployeeRole(),
});

const withConnect = connect(mapStateToProps);

export default compose(withConnect, memo)(CommonCaseCard);
