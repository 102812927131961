import React,{useState,useEffect,useContext} from 'react';
import { ApiDispatchContext } from "../../../context/ApiContext";
import ErrorAlert from "../../../components/ErrorAlert";
import { getUrlParameter } from "../../../constants/commonFunction";

function Dispatch() {
   const [dispatch,setDispatch] = useState([])
   const api = useContext(ApiDispatchContext);
   const [modal, setModal] = useState(false);
   const [modalTitle, setModalTitle] = useState("");
   const [modalBody, setModalBody] = useState("");
   const toggle = () => setModal(!modal);
   let casid = Number(getUrlParameter('case'));

    useEffect(() => {
       let payload ={
         "caseId": casid,
         "segmentType": "dispatch"
       }
       api.getCaseBPRSegmentDetails(payload).then(data => {
         if (data.data && data.data.success && data.data.data.length) {
           setDispatch(data.data.data)
         }
       }).catch(err => {
         setModalTitle("Failed to load Basic Data.");
         if(err && err.response && err.response.data && err.response.data.message){
           setModalBody(err.response.data.message);
         } else {
           setModalBody('Please try after sometime');
         }
         toggle();
       })
     
   }, [casid]);
    return (
        <div className='dispatch'>
            {dispatch.length > 0 && Array.isArray(dispatch) && dispatch.map((item, index) => (
                <div key={index} className="rein-dispatch-card">
                    {Array.isArray(item) && item.map((ele, i) => (
                        <div className="map-data-breakdiv">
                            <div className='map-data-text1'>{ele.label}</div>
                            <div className='map-data-text2'>{ele.value? ele.value : '-'}</div>
                        </div>
                    ))}
                </div>
            ))}
                <ErrorAlert
                modal={modal}
                toggle={toggle}
                modalTitle={modalTitle}
                modalBody={modalBody}
            />
        </div>
    )
}

export default Dispatch