import React, { memo } from 'react';

const LabelValueLoop = memo(({ element }) => {
    return (
        <div className='Label-Value map-data'>{
            Array.isArray(element) && element.map((item, key) => (
                <div className='map-data-breakDIV' key={key}>
                    <div className='map-data-text1'>{item.label}</div>
                    <div className='map-data-text2'>{item.value ? item.value : '-'}</div>
                </div>
            ))
        }
        </div>
    )
})

LabelValueLoop.propTypes = {}

export default LabelValueLoop