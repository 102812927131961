import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { ALLOTED_CASE_DETAILS } from "../../constants/pathConstants";
import CommonCaseCard from "../../components/CommonCaseCard/CommonCaseCard";

export const CaseCard = ({ selectCards, showCaseType }) => {
  return (
    <div>
      {selectCards &&
        selectCards.map((item, index) => (
          <CommonCaseCard
            key={`allottedCasesCard-${index}`}
            cardDetail={item}
            btn={true}
            showCaseType={showCaseType ? showCaseType : false}
            path={ALLOTED_CASE_DETAILS}
            cardType={"Allotted"}
          />
        ))}
    </div>
  );
};

const mapStateToProps = createStructuredSelector({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(CaseCard);
