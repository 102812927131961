import React, { memo, useCallback, useContext, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { compose } from "redux";
import { useInjectReducer } from "../../utils/injectReducer";
import makeSelectGenerateFactFindingReport from "./selectors";
import reducer from "./reducer";
import  CommonNavbar  from "../../components/CommonNavbar";
import { getUrlParameter } from "../../constants/commonFunction";
import './index.css';
import jsPDF from 'jspdf';
import FilterPopUp from "../../components/FilterPopUp";
import { getEmployeeRole } from "../App/selectors";
import ErrorAlert from "../../components/ErrorAlert";
import { push } from 'connected-react-router';
import { ApiDispatchContext } from "../../context/ApiContext";
export function GenerateFactFindingReport() {
  useInjectReducer({ key: "generateFactFindingReport", reducer });
  const [open, setOpen] = useState(false);
  const caseid = Number(getUrlParameter('case'));
  const reportTemplateRef = useRef(null);
  const [modal, setModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalBody, setModalBody] = useState("");
  const toggle = () => setModal(!modal);
  const api = useContext(ApiDispatchContext);
  const [reportData, setReportData] = useState(null);
  
  useEffect(() => {
    window.scrollTo(0, 0);
    api.factFindingReport({ caseId: caseid }).then(data => {
      if (data?.data?.data) {
        setReportData(data?.data?.data);
      } else {
        setModalTitle("Error");
        if(data?.data?.error && data?.data?.message){
          setModalBody(data?.data?.message)
        }
        else{
          setModalBody('Please try after sometime');
        }
        toggle();
      }
    }).catch(err => {
      setModalTitle("Error");
      if (err?.response?.data?.message) {
        setModalBody(err?.response?.data?.message);
      } else {
        setModalBody('Please try after sometime.');
      }
      toggle();
    })
  }, []);

  const filterToggle = () => {
    setOpen(!open);
  }

  const DownloadPDF = useCallback(() => {
    const doc = new jsPDF({
      orientation: 'portrait',
      format: 'a4',
      unit: 'px',
    });
  
    doc.setFont('Inter-Regular', 'normal');
  
    doc.html(reportTemplateRef?.current, {
      async callback(doc) {
        doc.save('Fact-Finding-Report');
      },
    });
  }, [reportTemplateRef]);

  return(
    <div>
      <CommonNavbar title="Fact Finding Report" download={true} onDownloadClick={DownloadPDF} moreOptionPopup={false} filterToggle={filterToggle} />
      <div className='fact-finding-report-container'>
        <div ref={reportTemplateRef}>
        </div>
      </div>
      {open && (<FilterPopUp open={open} backgroundColor={'#8F949B80'} filterToggle={filterToggle} >
        <div className='moreOptions'>
          <p key="Download" className='moreOptions-action' onClick={DownloadPDF}>Download</p>
        </div>
      </FilterPopUp>)}
      <ErrorAlert
        modal={modal}
        toggle={toggle}
        modalTitle={modalTitle}
        modalBody={modalBody}
      />
    </div>
  );
}

GenerateFactFindingReport.propTypes = {
  dispatch: PropTypes.func.isRequired
};

const mapStateToProps = createStructuredSelector({
  generateFactFindingReport: makeSelectGenerateFactFindingReport(),
  employeeRole: getEmployeeRole(),
});

export function mapDispatchToProps(dispatch) {
  return {
    redirectPath: (path) => dispatch(push(path)),
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);

export default compose(
  withConnect,
  memo
)(GenerateFactFindingReport);
