import { createSelector } from "reselect";
import { initialState } from "./reducer";

const selectCasesDetailsDomainRejectedInvoices = state => state.casesDetails || initialState;

const makeSelectCasesDetails = () =>
  createSelector(
    selectCasesDetailsDomainRejectedInvoices,
    substate => substate
  );

const makeSelectCardCases = () =>
  createSelector(
    selectCasesDetailsDomainRejectedInvoices,
    substate => {
      return substate.cardData
    }
  );

export default makeSelectCasesDetails;
export { selectCasesDetailsDomainRejectedInvoices, makeSelectCardCases };