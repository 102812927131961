import React, { memo, useState } from 'react'
import { Accordion, AccordionBody, AccordionHeader, AccordionItem,} from 'reactstrap';
import "../cardtab.css";

export const NewBlocksAssigned = memo((props) => {
    const [open, setOpen] = useState('0');
    const toggle = (id) => {
      if (open === id) {
        setOpen();
      } else {
        setOpen(id);
      }
    };
    return (
        <div className='assignedInvestigators NewBlocksAssigned' style={{ width: '100%' }}>
            <Accordion data-testid="NewBlocksAssigned1" open={open} toggle={toggle}>
                <AccordionItem>
                    <AccordionHeader targetId="1">New Blocks Assigned - {props.new_assigned_blocks && props.new_assigned_blocks.count}</AccordionHeader>
                    <AccordionBody accordionId="1" className='new-assigned-block'>
                        <div className='row'>
                            {props.new_assigned_blocks && props.new_assigned_blocks.new_blocks && props.new_assigned_blocks.new_blocks.map((item) => {
                                return (
                                    <div className='col-4' style={{marginBottom:"10px"}} key={item.block_name}>
                                        <button className='NewBlocksAssignedButton'>{item.renamed_block_name} </button>
                                    </div>
                                )
                            })}
                        </div>
                    </AccordionBody>
                </AccordionItem>
            </Accordion>
        </div>
    )
})

NewBlocksAssigned.propTypes = {}

export default NewBlocksAssigned;