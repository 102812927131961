import React, { memo } from 'react';
import './index.css';
import GenricPetitionerForm from './GenricPetitionerForm';
import { imageBaseUrl } from '../../utils/constants';

export const PetitionerForm = memo(({ petitioners, handleChange, handlePetChange, addAnother, datatestid, datatestid2, onPetRemove,formcolor,formName,ifRequired }) => {
  return (
    <div data-testid="PetitionerFormContainer" className='petitioner-form'>
      <div className="petitioner-form-container">
        {petitioners.singleParameters.map((ele, index) => {
          if (ele.parameter_type !== "upload") {
            return (
              <>
              <GenricPetitionerForm ifRequired index={index} data-testid="GenricPetitionerForm1" config={ele} formcolor={formcolor} formName={formName} handleChange={(event) => handleChange(event, index)} showLabel={true} />
              </>
            );
          }
        })}
        {petitioners.multipleParameters.map((pet, petIndex) => {
          return (
            <React.Fragment key={petIndex}>
              <div className='petitioner-details-container'>
                <label className='petitioner-form-label'>Details{ifRequired && <sup>*</sup>}</label>
                {petIndex !== 0 && <button className="petitioner-details-remove" data-testid="GenricPetitionerForm3" onClick={() => onPetRemove(petIndex)}>
                  <img src={`${imageBaseUrl}/cross.svg`} alt="cross" />
                </button>}
              </div>
              {Array.isArray(pet) && pet.map((petInp, petInpIndex) => {
                return (
                  <GenricPetitionerForm is index={petIndex} config={petInp} data-testid="GenricPetitionerForm2" formcolor={formcolor} formName={formName} handleChange={(value) => handlePetChange(value, petIndex, petInpIndex)} showLabel={false} />
                );
              })}
            </React.Fragment>
          )
        })}
      </div>
      <div className="addPetitioner-addAnother-parent">
        <button data-testid="petitioneraddanother" className='addPetitioner-addAnother' onClick={addAnother}>+ Add another details</button>
      </div>
    </div>
  )
})
PetitionerForm.propTypes = {};

export default PetitionerForm;