import React from "react";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import "@cyntler/react-doc-viewer/dist/index.css";
import './PdfViewer.css'

const PdfViewer = ({
  pdfFileLink= "",
}) => {
  const docs = [
    { uri: pdfFileLink },
  ];

  return (
    <DocViewer
      documents={docs}
      initialActiveDocument={docs[1]}
      pluginRenderers={DocViewerRenderers}
      allow
    />
  );
};
export default PdfViewer;
