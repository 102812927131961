import ReactGA from "react-ga4";

//Initialize React Google Analytics
export const analyticsInitialize = () => {
  if (process.env.REACT_APP_GA_TRACKING_ID)
    ReactGA?.initialize(process.env.REACT_APP_GA_TRACKING_ID);
};
//Google Analytics Helper Function for Page View
export const analyticsPageview = (hitType, page, title) => {
  if (process.env.REACT_APP_GA_TRACKING_ID)
    ReactGA?.send({ hitType, page, title });
};
//Google Analytics Helper Function for triggering events
export const analyticsEvent = (category, action, label) => {
  if (process.env.REACT_APP_GA_TRACKING_ID)
    ReactGA?.event({ category, action, label });
};