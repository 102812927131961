import { createSelector } from "reselect";
import { initialState } from "./reducer";

const selectAssignBlocksReinvestigatedCasesDomain = state =>
  state.assignBlocksReinvestigatedCases || initialState;

const makeSelectAssignBlocksReinvestigatedCases = () =>
  createSelector(
    selectAssignBlocksReinvestigatedCasesDomain,
    substate => substate
  );

  const selectForms = () =>
  createSelector(
    selectAssignBlocksReinvestigatedCasesDomain,
    substate => { return substate.formData2 }
  );

export default makeSelectAssignBlocksReinvestigatedCases;
export { selectAssignBlocksReinvestigatedCasesDomain,selectForms };
