import React, { memo, useEffect, useState } from 'react'
import { Accordion, AccordionBody, AccordionHeader, AccordionItem,} from 'reactstrap';
import "../cardtab.css"
import Icon from '../../../../components/Icon';
import { getUrlParameter } from '../../../../constants/commonFunction';
import { imageBaseUrl } from '../../../../utils/constants';
import { mandatoryBlocksIdsForHealth, mandatoryBlocksIdsForOD, mandatoryBlocksIdsForTP } from '../../../../constants/constants';

export const CaseBlocks = memo((props) => {
    const [open, setOpen] = useState('0');
    const subProductType = getUrlParameter('subProduct');
    const lob = getUrlParameter('lob');
    const lobType = localStorage.getItem("lobType");
    const toggle = (id) => {
      if (open === id) {
        setOpen();
      } else {
        setOpen(id);
      }
    };
    useEffect(() => {
      props?.blockOpen == true ? setOpen('1') : setOpen('0');
    },[props?.blockOpen]);
    
    return (
      <div className='assignedInvestigators Caseblocks' style={{ width: '100%' }}>
        <Accordion data-testid="CaseBlocks1" open={open} toggle={toggle}>
          <AccordionItem>
            <AccordionHeader targetId="1">Case Blocks - {props?.assigned_blocks && props?.assigned_blocks.count}</AccordionHeader>
            <AccordionBody accordionId="1">
              <div className='allotedclaim-div-data' style={{marginBottom:10}}>
                <div className='allotedclaim-div-data'>
                  <div className='reininvestigator-maindiv2'>
                    <div className='reinvestigatedclaim-data'>
                        <div className='assignedblocks-maindiv' style={{ width: '100%', paddingTop:0 }}>
                          <div className='assignedblocks-flexbox'>
                            {props?.assigned_blocks && props?.assigned_blocks?.blocks && props?.assigned_blocks?.blocks.map((item, index) => {
                              return (
                                <div className='reinassignedblocks-mains opencaseblocks-block-width' key={index}>
                                  <div className='assignedblockimage-position'><img className='assignedblockimage' src={item?.block_status === "completed" ? `${imageBaseUrl}/investigatorgreentick.png` : item?.block_status === "inProgress" ? `${imageBaseUrl}/investigatororangetick.svg` : `${imageBaseUrl}/investigatorgreytick.png`} alt="tick" /></div>
                                  
                                  {(subProductType === "TP" && mandatoryBlocksIdsForTP.includes(item?.block_master_id)) ? (
                                      <div className='assignedblocks-subhead'>{item?.renamed_block_name} <sup style={{fontSize: "10px"}}>*</sup></div>
                                    ) : (subProductType === "OD" && mandatoryBlocksIdsForOD.includes(item?.block_master_id)) ? (
                                      <div className='assignedblocks-subhead'>{item?.renamed_block_name} <sup style={{fontSize: "10px"}}>*</sup></div>
                                    ) : ((lob === "AH" || lobType === "A&H") && mandatoryBlocksIdsForHealth.includes(item?.block_master_id)) ? (
                                      <div className='assignedblocks-subhead'>{item?.renamed_block_name} <sup style={{fontSize: "10px"}}>*</sup></div>
                                    ) : (
                                      <div className='assignedblocks-subhead'>{item?.renamed_block_name} </div>
                                  )}

                                  <div className='assignedblocks-viewbtn-position'>
                                    <button className='assignedblocks-viewbtn' data-testid="CaseBlocks2" onClick={() => { props?.Onviewclick(item?.block_master_id, item?.renamed_block_name, item?.flag_to_add_another) }}><Icon />  View</button>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                          <div className='assignedblocks-textareamain' style={{padding:5}}>
                            <textarea placeholder='Remarks' value={props?.remark} className='assignedblocks-textarea' data-testid="CaseBlocks4" onChange={(event) => { props?.handleChange(event) }} />
                            <div className='assignedblocks-viewbtn-position2'>
                              <button data-testid="CaseBlocks3" onClick={() => { props?.submitRemark(props?.remark) }} className='assignedblocks-viewbtn' >Submit</button>
                            </div>
                          </div>
                        </div>
                    </div>
                      {/* <UpArrowButton
                        datatestid="CaseBlocks5"
                        onClick={toggle}
                      /> */}
                    <button data-testid="CaseBlocks5" className='toggle' onClick={toggle}>
                      <img src={`${imageBaseUrl}/assign_not_collapsed.png`} alt="toggleimg" />
                    </button>
                  </div>
                </div>
              </div>
            </AccordionBody>
          </AccordionItem>
        </Accordion>
      </div>
    )
})

CaseBlocks.propTypes = {}

export default CaseBlocks;