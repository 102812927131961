import React, { useState, memo, useCallback } from 'react'
import Icon from "../../../../components/Icon";
import { imageBaseUrl } from "../../../../utils/constants";

export const Vahan = props => {
    const [showmore, setshowmore] = useState(false);

    const handleShowmore = useCallback(() => {
        setshowmore(true);
    }, []);

    const handleShowless = useCallback(() => {
        setshowmore(false);
    }, []);

    let vahanArray = [
        { label: 'Vehicle Number:', value: props?.External?.vehicleNumber ? props?.External?.vehicleNumber : '' },
        { label: 'Vehicle Manufacturer name:', value: props?.External?.vehicleManufacturerName ? props?.External?.vehicleManufacturerName : '' },
        { label: 'Model:', value: props?.External?.model ? props?.External?.model : '' },
        { label: 'Vehicle Color:', value: props?.External?.vehicleColour ? props?.External?.vehicleColour : '' },
        { label: 'Type:', value: props?.External?.type ? props?.External?.type : '' },
        { label: 'Body Type:', value: props?.External?.bodyType ? props?.External?.bodyType : '' },
        { label: 'Reg Authority:', value: props?.External?.regAuthority ? props?.External?.regAuthority : '' },
        { label: 'Reg Date:', value: props?.External?.regDate ? props?.External?.regDate : '' },
        { label: 'RC Expiry date:', value: props?.External?.rcExpiryDate ? props?.External?.rcExpiryDate : '' },
        { label: 'Vehicle Seat Capacity:', value: props?.External?.vehicleSeatCapacity ? props?.External?.vehicleSeatCapacity : '' },
        { label: 'Is Commercial:', value: props?.External?.isCommercial ? props?.External?.isCommercial.toString() : '' },
        { label: 'Class:', value: props?.External?.class ? props?.External?.class : '' },
        { label: 'Engine:', value: props?.External?.engine ? props?.External?.engine : '' },
        { label: 'Status:', value: props?.External?.status ? props?.External?.status : '' },
        { label: 'Chassis:', value: props?.External?.chassis ? props?.External?.chassis : '' },
        { label: 'Status As On:', value: props?.External?.statusAsOn ? props?.External?.statusAsOn : '' },
        { label: 'Vehicle Manufacturing Month Year:', value: props?.External?.vehicleManufacturingMonthYear ? props?.External?.vehicleManufacturingMonthYear : '' },
        { label: 'Vehicle Cubic Capacity:', value: props?.External?.vehicleCubicCapacity ? props?.External?.vehicleCubicCapacity : '' },
        { label: 'Vehicle Category:', value: props?.External?.vehicleCategory ? props?.External?.vehicleCategory : '' },
        { label: 'Vehicle Cylinders No:', value: props?.External?.vehicleCylindersNo ? props?.External?.vehicleCylindersNo : '' },
        { label: 'Blacklist Status:', value: props?.External?.blacklistStatus ? props?.External?.blacklistStatus : '' },
        { label: 'Owner:', value: props?.External?.owner ? props?.External?.owner : '' },
        { label: 'Owner father Name:', value: props?.External?.ownerFatherName ? props?.External?.ownerFatherName : '' },
        { label: 'Owner Count:', value: props?.External?.ownerCount ? props?.External?.ownerCount : '' },
        { label: 'Vehicle Insurance Company Name:', value: props?.External?.vehicleInsuranceCompanyName ? props?.External?.vehicleInsuranceCompanyName : '' },
        { label: 'Vehicle Insurance Upto:', value: props?.External?.vehicleInsuranceUpto ? props?.External?.vehicleInsuranceUpto : '' },
        { label: 'Present Address:', value: props?.External?.presentAddress ? props?.External?.presentAddress : '' },
        { label: 'Pincode:', value: props?.External?.splitPresentAddress ? props?.External?.splitPresentAddress?.pincode : '' },
        { label: 'Permanent Address:', value: props?.External?.permanentAddress ? props?.External?.permanentAddress : '' },
        { label: 'Pincode:', value: props?.External?.splitPermanentAddress ? props?.External?.splitPermanentAddress?.pincode : '' }
    ]

    return (
        <div className='basic' style={{ width: '100%' }}>
            <div className='map-data'>
                <div className='externalMainView-alloted'>
                    <div className='externalMainView2'>
                        <div className='externalimg-view1'>
                            <div className='phcar-external-img'>
                                <img style={{ width: "20px", height: "20px" }} src={`${imageBaseUrl}/ph_car.png`} alt="search" />
                            </div>
                            <div className='externalHeader-style'>Vahan</div>
                        </div>
                        {showmore ?
                            <div>
                                <button data-testid="handleShowless1-view" className='claim-upbuttton-style' onClick={() => { handleShowless(); }}>
                                    <img className='claim-upbuttton-img' src={`${imageBaseUrl}/allotted-claim-up.png`} alt="allotted" />
                                </button>
                            </div>
                            :
                            <div className='map-data-breakDIV claimbtn-main externalimg-view2' style={{ marginTop: 0 }}>
                                <button data-testid="handleShowAllbtn-view" className='claimview-btn' onClick={() => { handleShowmore(); }}><Icon />  View</button>
                            </div>
                        }
                    </div>
                    {showmore ?
                        <div className='map-data'>
                            {vahanArray.length > 0 && vahanArray.map((item, i) => (
                                <div className='map-data-breakDIV'>
                                    <div className='map-data-text1'>{item.label}</div>
                                    <div className='map-data-text2'>{item.value ? item.value : '-'}</div>
                                </div>
                            ))
                            }
                        </div> : null}
                    {showmore ?
                        <div style={{ float: 'right' }}>
                            <button data-testid="handleShowless2-view" className='claim-upbuttton-style' onClick={() => { handleShowless(); }}>
                                <img className='claim-upbuttton-img' src={`${imageBaseUrl}/allotted-claim-up.png`} alt="allotted" />
                            </button>
                        </div> : null}
                </div>
            </div>
        </div>
    )
}

Vahan.propTypes = {}

export default memo(Vahan);