import React, { memo, useEffect, useContext, useState } from 'react';
import ErrorAlert from '../../../components/ErrorAlert';
import { ApiDispatchContext } from '../../../context/ApiContext';
import { getUrlParameter } from '../../../constants/commonFunction';
import './cardtab.css';
import LabelValueLoop from '../../../components/LabelValueLoop';

const Endorsement = memo((props) => {
  const api = useContext(ApiDispatchContext);
  const [modal, setModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalBody, setModalBody] = useState("");
  const toggle = () => setModal(!modal);
  const [loaddata, setloaddata] = useState(false);
  let casid = Number(getUrlParameter('case'));
  const [endorsement, setendorsement] = useState();

  useEffect(() => {
    if (casid) {
      let payload = {
        "caseId": casid,
        "segmentType": "endorsement"
      }
      api.getCaseBPRSegmentDetails(payload).then(data => {
        if (data.data && data.data.success && data.data.data.length) {
          setendorsement(data.data.data)
          setloaddata(true)
        }
      }).catch(err => {
        setModalTitle("Error Endorsement");
        if(err && err.response && err.response.data && err.response.data.message){
          setModalBody(err.response.data.message);
        } else {
          setModalBody('Please try after sometime');
        }
        toggle();
      })
    }
  }, [casid]);
  return (
    <div className='rein-endorsement'>
      {loaddata ?
        <div className=''>
          {endorsement.length > 0 && endorsement.map((end, i) => (
            <LabelValueLoop element={end} />
          ))
          }
        </div>
        : null}
      <ErrorAlert
        modal={modal}
        toggle={toggle}
        modalTitle={modalTitle}
        modalBody={modalBody}
      />
    </div>
  )
})

Endorsement.propTypes = {}

export default Endorsement