import { createSelector } from "reselect";
import { initialState } from "./reducer";

/**
 * Direct selector to the generateFactFindingReport state domain
 */

const selectGenerateFactFindingReportDomain = state =>
  state.generateFactFindingReport || initialState;

/**
 * Other specific selectors
 */

/**
 * Default selector used by GenerateFactFindingReport
 */

const makeSelectGenerateFactFindingReport = () =>
  createSelector(
    selectGenerateFactFindingReportDomain,
    substate => substate
  );

export default makeSelectGenerateFactFindingReport;
export { selectGenerateFactFindingReportDomain };