export const DEFAULT_ACTION = "app/AssignedBlocksAllottedCases/DEFAULT_ACTION";
export const FORMDATA = "app/AssignedBlocksAllottedCases/BLOCKDATA";
export const SETFORMDATA = "app/AssignedBlocksAllottedCases/BLOCKDATA/SET/VALUE";

export const textParameterName = [
    "first_name",
    "last_name",
    "father_name",
    "state",
    "district",
    "class_of_vehicle",
    "color_of_iv",
]