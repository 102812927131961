import React, { memo } from 'react';
import { DropdownMenu, DropdownItem, ButtonDropdown, DropdownToggle} from "reactstrap";

export const Dropdown = memo((props) => {
    const [dropdownOpen, setOpen] = React.useState(false);
    const [selectedValue, setSelectedValue] = React.useState(props.value)
    const selectDropdown = (val) => {
        setSelectedValue(val);
        props.dropdownselected(val);
    }
    return (
        <div className='dropdown generic-dropdown filteractionsdropdown'>
            <ButtonDropdown data-testid="dropdownItem22" toggle={() => { setOpen(!dropdownOpen) }} 
                isOpen={dropdownOpen}>
                <DropdownToggle className={dropdownOpen&&"custom-toggle"} caret style={{backgroundColor:'#FAFAFA'}}>
                    {selectedValue ? selectedValue:'Please Select '+ props.label}
                </DropdownToggle>
                <DropdownMenu style={{backgroundColor:'#FAFAFA'}} className="generic-dropdownitems-main">
                {props.showArray && props.showArray.map(data => {
                        return <DropdownItem  data-testid="dropdownItem" key={data.value} onClick={() => selectDropdown(data.value)} className="generic-dropdownitems">{data.value}</DropdownItem>
                    })}
                </DropdownMenu>
            </ButtonDropdown>
        </div>
    )
})

Dropdown.propTypes = {};

export default Dropdown;