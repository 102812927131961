/*
 *
 * ReinvestigateCases actions
 *
 */

import { DEFAULT_ACTION ,CASEUPDATE} from "./constants";

export function defaultAction() {
  return {
    type: DEFAULT_ACTION
  };
}
export function casesUpdate(payload) {
  return {
    type: CASEUPDATE,
    payload:payload
  };
}
