import React, {useEffect, useState} from 'react';
import './EmployementSuccess.css';
import EmpMasterButton from '../EmpMasterButton';
import path from '../../../constants/pathConstants';
import { useNavigate } from "react-router-dom";
import { getUrlParameter } from '../../../constants/commonFunction';
import { imageBaseUrl } from '../../../utils/constants';

const EmployeeMasterSuccess =() => {
  let [title,setTitle] = useState("");
  
  const navigate = useNavigate();

  useEffect(() => {
    setTitle(getUrlParameter('title'));
  }, [])
  
  return (
    <div data-testid="employementSuccess" className='employementSuccess'>
        <div className='employementSuccess-upper'>
          <img className='employementSuccess-tick' src={`${imageBaseUrl}/success.gif`} alt="success" />
          <p className='employementSuccess-title'>{title}</p>
        </div>
        <div className="employementSuccess-btnDiv">
          <EmpMasterButton data-testid="save-button" onClick={()=> navigate(path.EMPLOYMENT_MASTER)} background={"#59CE8F"} color={"#FFFFFF"}>Proceed</EmpMasterButton>
        </div>
    </div>
  )
}

export default EmployeeMasterSuccess