import { createSelector } from "reselect";
import { initialState } from "./reducer";

const selectCasesDetailsDomain = state => state.casesDetails || initialState;

const makeSelectCasesDetails = () =>
  createSelector(
    selectCasesDetailsDomain,
    substate => substate
  );

const makeSelectCardCases = () =>
  createSelector(
    selectCasesDetailsDomain,
    substate => {
      return substate.cardData
    }
  );

const makeMultiSelectCases = () =>
  createSelector(
    selectCasesDetailsDomain,
    substate => {
      return substate.caseidArr
    }
  );

export default makeSelectCasesDetails;
export { selectCasesDetailsDomain, makeSelectCardCases, makeMultiSelectCases };