import { createSelector } from "reselect";
import { initialState } from "./reducer";

const selectCaseDetailspageDomain = state =>
  state.allottedCasesPage || initialState;

const makeSelectCaseDetailspage = () =>
  createSelector(
    selectCaseDetailspageDomain,
    substate => substate
  );

  const makeSelectCardCasesPage = () =>
  createSelector(
    selectCaseDetailspageDomain,
    substate => {
      return substate.cardData
    }
  );
export default makeSelectCaseDetailspage;
export { selectCaseDetailspageDomain, makeSelectCardCasesPage };
