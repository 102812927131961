
import states from './states.json';
import { CFB, SSF, parse_xlscfb, parse_zip, read, readFile, readFileSync, set_cptable, set_fs, stream, utils, version, write, writeFile, writeFileAsync, writeFileSync, writeFileXLSX, writeXLSX } from 'xlsx';
const regexForHtmlMatch = "<([a-z]+)[^>]*>"
const regexMatchForLink = "(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})(\.[a-zA-Z0-9]{2,})?\/[a-zA-Z0-9]{2,}"
const caseCountApiCall = (employeeRole, dummyCases, result) => {
  let data = result.data.data;
  if (employeeRole === "investigator") {
    const newData = [...dummyCases];
    newData.splice(1, 1);
    newData[0].cases = data[0].label
    newData[1].cases = data[1].label
    newData[2].cases = data[2].label
    newData[0].caseNumber = data[0].value
    newData[1].caseNumber = data[1].value
    newData[2].caseNumber = data[2].value
    return newData
  } else if (employeeRole === "vendor") {
    const newData = [...dummyCases];
    newData[0].cases = data[0].label
    newData[1].cases = data[1].label
    newData[2].cases = data[2].label
    newData[3].cases = data[3].label
    newData[0].caseNumber = data[0].value
    newData[1].caseNumber = data[1].value
    newData[2].caseNumber = data[2].value
    newData[3].caseNumber = data[3].value
    return newData
  }
  return
}

const getUrlParameter = (value) => {
  try {
  let queryString = window.location.search;
  let parts = queryString.split('?');
  let newstring = parts[1] ? atob(parts[1]) : '';
  const urlParams = new URLSearchParams(newstring);
  if(urlParams.get(value) && (urlParams.get(value).match(regexForHtmlMatch) || urlParams.get(value).match(regexMatchForLink))) return "";
  return urlParams.get(value)
  } catch (error) {
    return ""
  }
}

const FilterSearch = (state) => {
  let res = states.filter((result) =>
    result.name.toLowerCase().includes(state.toLowerCase())
  );
  return res
}

const formatTimeAmPm = (param) => {
  if(param === "" || param.split(" ")[1]){
    return param;
  }
  const inputTime = param;
  const [hours, minutes, seconds] = inputTime.split(":").map(Number);

  // Adjust the hours value for AM/PM format
  let adjustedHours = hours;
  let period = "AM";
  if (hours >= 12) {
    period = "PM";
    if (hours > 12) {
      adjustedHours -= 12;
    }
  } else if (hours === 0) {
    adjustedHours = 12;
  }

  // Format the time to "hh:mm PM"
  const formattedTime = `${adjustedHours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")} ${period}`;

  return formattedTime;
}

function toPascaleCase(str) {
  // Split the string into an array of words
  const words = str.split(' ');

  // Capitalize the first letter of each word and make the rest lowercase
  const pascalWords = words.map(word => {
    const firstLetter = word.charAt(0).toUpperCase();
    const restOfWord = word.slice(1).toLowerCase();
    return firstLetter + restOfWord;
  });

  // Join the words together without any spaces
  const pascalCaseStr = pascalWords.join('');

  return pascalCaseStr;
}

function valueToClassName(str) {
  // Remove period at the last index if present
  if (str[str.length - 1] === '.') {
    str = str.slice(0, -1);
  }

  // Convert string to camel case
  const words = str.split(' ');
  const camelCaseWords = words.map((word, index) => {
    if (index === 0) {
      return word.toLowerCase();
    }
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  });
  
  return camelCaseWords.join('');
}

function handleExportClick(jsonData, sheetName, fileName) {
  const worksheet = utils.json_to_sheet(jsonData);
  const workbook = utils.book_new();
  utils.book_append_sheet(workbook, worksheet, sheetName);

  // Generate Excel file as a Blob
  const excelBlob = new Blob([writeXLSX(workbook, { bookType: 'xlsx', type: 'array' })], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  });

  // Create a temporary link to trigger the download
  const link = document.createElement('a');
  link.href = URL.createObjectURL(excelBlob);
  link.download = `${fileName}.xlsx`;
  link.click();

  // Clean up
  URL.revokeObjectURL(link.href);
};

export { caseCountApiCall, getUrlParameter, FilterSearch, formatTimeAmPm, toPascaleCase, valueToClassName, handleExportClick }

