import produce from "immer";
import { DEFAULT_ACTION, CASEUPDATE } from "./constants";

export const initialState = {
};

const casesDetailsReducerRejectedInvoices = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case DEFAULT_ACTION:
        break;
      case CASEUPDATE:
        draft.cardData = action.payload
        break;
    }
  });

export default casesDetailsReducerRejectedInvoices;
