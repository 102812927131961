import React, { memo } from 'react';
import { Input, Label } from 'reactstrap';
import './index.css';

const Radiobutton = memo((props) => {
    return (
        <div className='radiobutton-genric-main'>
            <div className='Genericforms-text' style={{flex:1}}>{props.config.renamed_block_parameter}{props.ifRequired && <sup>*</sup>}</div>
            <div style={{flex:1, display:'flex'}}>
            {props.config.parameter_options && props.config.parameter_options.map(tab => (
            <Label key={tab} check style={{flex:1, textAlign:'end'}} className='Genericforms-radio-text'>
                {tab}
                    <Input
                    required={props.ifRequired}
                        style={{ marginLeft: 20 }}
                        type="radio"
                        name={props.config.parameter_name}
                        data-testid="genericinput"
                        checked={(props.config.parameter_value && tab === props.config.parameter_value) || tab === props.config.value  ? true : false}
                        className='radiobuttongeneric'
                        value={tab}
                        onClick={(evt) => props.handleGenFormInput(evt.target.value)}
                    /> 
            </Label>
            ))}
            </div>
        </div>
    )
})

Radiobutton.propTypes = {};

export default Radiobutton;