export const addInvestigatorBulkUpload = [
     {
      "employee_name": "Avadhesh Chauhan",
      "mobile_number": 9906111509,
      "email": "avadhesh@gmail.com",
      "state": "Maharashtra",
      "city": "Mumbai"
     },
     {
      "employee_name": "Avadhesh Chauhan",
      "mobile_number": 9906111509,
      "email": "avadhesh@gmail.com",
      "state": "Maharashtra",
      "city": "Pune"
     },
     {
      "employee_name": "Avadhesh Chauhan",
      "mobile_number": 9906111509,
      "email": "avadhesh@gmail.com",
      "state": "Maharashtra",
      "city": "Nashik"
     },
     {
      "employee_name": "Laique Mirza",
      "mobile_number": 2323232323,
      "email": "laique@gmail.com",
      "state": "Karnataka",
      "city": "Manglore"
     },
     {
      "employee_name": "Laique Mirza",
      "mobile_number": 2323232323,
      "email": "laique@gmail.com",
      "state": "Karnataka",
      "city": "Bengaluru"
     }
]