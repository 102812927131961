import React, { memo } from 'react';
import './index.css';

export const Textarea = memo((props) => {
  return (
    <div className='textinput-genricpet-main'>
     {props?.showLabel && (<div className='Genericforms-text'>{props?.config?.renamed_block_parameter}{props?.ifRequired && <sup>*</sup>}</div>)}
      <textarea 
      disabled={props?.isDisabled}
      required={props?.ifRequired}
      placeholder='Remarks'
      className='assignedblocks-textarea' 
      data-testid="textareaassignedblock" 
      />
    </div>
  )
})

Textarea.propTypes = {};

export default Textarea;