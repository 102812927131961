import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { UPLOAD_INVOICE_DETAILS } from "../../constants/pathConstants";
import CommonCaseCard from "../../components/CommonCaseCard/CommonCaseCard";

export const CaseCard = ({ selectCards, showCaseType }) => {
  return (
    <div>
      {selectCards &&
        selectCards.map((item, index) => (
          <CommonCaseCard
            key={`rejectedCasesCard-${index}`}
            cardDetail={item}
            btn={true}
            showCaseType={showCaseType ? showCaseType : false}
            path={UPLOAD_INVOICE_DETAILS}
          />
        ))}
    </div>
  );
};

const mapStateToProps = createStructuredSelector({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(CaseCard);
