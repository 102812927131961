import { DEFAULT_ACTION,UPDATE_EMPLOYEE,UPDATE_EMPLOYEE_IDS } from "./constants";

export function defaultAction() {
  return {
    type: DEFAULT_ACTION
  };
}

export function updateEmployee(employee){
  return{
    type: UPDATE_EMPLOYEE,
    employee
  }
}

export function updateEmployeeIds(ids){
  return{
    type: UPDATE_EMPLOYEE_IDS,
    ids
  }
}