import React, { useEffect, useState, useContext } from 'react';
import { ApiDispatchContext } from "../../../context/ApiContext";
import ErrorAlert from "../../../components/ErrorAlert";
import { getUrlParameter } from "../../../constants/commonFunction";
import './cardtab.css';
import LabelValueLoop from '../../../components/LabelValueLoop';
import UploadedFileView from '../../../components/UploadedFileView';

export const Policy = () => {
    const [policy, setPolicy] = useState("");
    const [documents,setDocuments] = useState("");
    const api = useContext(ApiDispatchContext);
    const [modal, setModal] = useState(false);
    const [modalTitle, setModalTitle] = useState("");
    const [modalBody, setModalBody] = useState("");
    const toggle = () => setModal(!modal);
    let casid = Number(getUrlParameter('case'));

    useEffect(() => {
        if (casid) {
            let payload = {
                "caseId": casid,
                "segmentType": "policy"
            }
            api.getCaseBPRSegmentDetails(payload).then(data => {
                if (data.data.success && (data.data.data.data.length || data.data.data.documents.length)) {
                    setPolicy(data.data.data.data);
                    setDocuments(data.data.data.documents);
                }
            }).catch(err => {
                setModalTitle("Error");
                setModalBody('Please try after sometime');
                toggle();
            })
        }
    }, [casid]);

    return (
        <div className='policy'>
            <div className='map-data'>
                {policy.length > 0 && policy.length?(
                    <LabelValueLoop element={policy} />
                ):null}
            </div>
            {documents.length > 0 &&(
            <>
            <hr />
            <span className='document-title'>Documents</span>
            <div className="docs-container">
                {Array.isArray(documents) && documents.length > 0 && 
                    <UploadedFileView download={false} border="1px solid #6C5CE7" documentList={documents}  />
                }
            </div>
            </>
            )}
            <ErrorAlert
                modal={modal}
                toggle={toggle}
                modalTitle={modalTitle}
                modalBody={modalBody}
            />
        </div>
    )
}

Policy.propTypes = {};

export default Policy;