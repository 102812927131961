import { createSelector } from "reselect";
import { initialState } from "./reducer";

/**
 * Direct selector to the submitFinalPage state domain
 */

const selectSubmitFinalPageDomain = state =>
  state.submitFinalPage || initialState;

/**
 * Other specific selectors
 */

/**
 * Default selector used by SubmitFinalPage
 */

const makeSelectSubmitFinalPage = () =>
  createSelector(
    selectSubmitFinalPageDomain,
    substate => substate
  );
  const makeSelectData = () =>
  createSelector(
    selectSubmitFinalPageDomain,
    substate => substate.data
  );

export default makeSelectSubmitFinalPage;
export { selectSubmitFinalPageDomain,makeSelectData };
