/*
 *
 * AssignInvestigator reducer
 *
 */
import produce from "immer";
import { DEFAULT_ACTION, ASSIGN_INVESTIGATOR, FILTER_STATE, FILTER_CITY } from "./constants";

export const initialState = {
  assigned_investigator:[],
  selectState:'',
  selectCity:''
};

/* eslint-disable default-case, no-param-reassign */
const assignInvestigatorReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case ASSIGN_INVESTIGATOR:
        if(action.id.length === 0){
          draft.assigned_investigator = [];
        } else {
        if (!draft.assigned_investigator.includes(action.id)) {
          draft.assigned_investigator.push(action.id);
        } else{
          draft.assigned_investigator = draft.assigned_investigator.filter(num => num !== action.id);
        }
      }
        break;
      case FILTER_STATE:
        draft.selectState = action.payload;
        break;
      case FILTER_CITY:
        draft.selectCity = action.payload
        break;
    }
  });

export default assignInvestigatorReducer;
