/*
 * Mobile Actions
 *
 * Actions change things in your application
 * Since this boilerplate uses a uni-directional data flow, specifically redux,
 * we have these actions which are the only way your application interacts with
 * your application state. This guarantees that your state is up to date and nobody
 * messes it up weirdly somewhere.
 *
 * To add a new Action:
 * 1) Import your constant
 * 2) Add a function like this:
 *    export function yourAction(var) {
 *        return { type: YOUR_ACTION_CONSTANT, var: var }
 *    }
 */

import { UPDATE_EMPLOYEECOUNT,DASHBOARD_MENU, AGEING_REPORT } from './constants';

/**
 * Changes the input field of the form
 *
 * @param  {string} username The new text of the input field
 *
 * @return {object} An action object with a type of CHANGE_USERNAME
 */

export function updateEmployeecount(employeeCountdata){
  return{
    type: UPDATE_EMPLOYEECOUNT,
    employeeCountdata
  }
}

export function dashbordMenuUpdate(menu){
  return {
    type: DASHBOARD_MENU,
    menu
  }
}

export function setAgeingReportDataAction(ageingData){
  return {
    type: AGEING_REPORT,
    ageingData
  }
}