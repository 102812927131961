import { createSelector } from "reselect";
import { initialState } from "./reducer";

const selectOpenCasesDetailsPageDomain = state =>
  state.openCasesDetailsPage || initialState;

const makeSelectOpenCasesDetailsPage = () =>
  createSelector(
    selectOpenCasesDetailsPageDomain,
    substate => substate
  );

export default makeSelectOpenCasesDetailsPage;
export { selectOpenCasesDetailsPageDomain };
