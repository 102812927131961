import { createSelector } from "reselect";
import { initialState } from "./reducer";

const selectUploadInvoiceDetailsDomain = state =>
  state.uploadInvoiceDetails || initialState;

const makeSelectUploadInvoiceDetails = () =>
  createSelector(
    selectUploadInvoiceDetailsDomain,
    substate => substate
  );

export default makeSelectUploadInvoiceDetails;
export { selectUploadInvoiceDetailsDomain };
