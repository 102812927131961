import CryptoJS from "crypto-js";

const secretKey = process.env.REACT_APP_CRYPTO_KEY;

// Encrypt data
const encryptData = (data) => {
    const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(data), secretKey).toString();
    return encryptedData;
};
  
// Decrypt data
const decryptData = (encryptedData) => {
    const bytes = CryptoJS.AES.decrypt(encryptedData, secretKey);
    const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    return decryptedData;
};

export {encryptData, decryptData}
  