import '../index.css';
import path from '../../../constants/pathConstants';
import { useNavigate } from "react-router-dom";
import { imageBaseUrl } from '../../../utils/constants';

export function DoNotAcceptSuccess() {
  const navigate = useNavigate();
  return (
    <div className='accept-success-page'>
        <img src={`${imageBaseUrl}/CloseErrorIcon.svg`} alt="close" />
        <span className='accept-success-page-title'>You have not accepted this case</span>
        <button data-testid="DoNotAcceptSuccess" className='accept-success-page-btn' onClick={()=>navigate(path.OPEN_CASE)}>Back to case list</button>
    </div>
  )
}

export default DoNotAcceptSuccess;