import React from 'react'
import MyDatePicker from './DatePicker'
import './index.css'

export function Date(props) {
  return (
    <div className='filter-date-container'>
        <MyDatePicker placeholder="From" setNewdate = {(date) => props.setNewdate1(date)} dateselected={props.date1} datepickerClicked={props.datepickerClicked}/>
        <MyDatePicker placeholder="To" setNewdate = {(date) => props.setNewdate2(date)}  dateselected={props.date2} datepickerClicked={props.datepickerClicked}/>
    </div>
  )
}

export default Date;