import { createSelector } from "reselect";
import { initialState } from "./reducer";

/**
 * Direct selector to the assignInvestigator state domain
 */

const selectAssignInvestigatorDomain = state =>
  state.assignInvestigator || initialState;

/**
 * Other specific selectors
 */

/**
 * Default selector used by AssignInvestigator
 */

const makeSelectAssignInvestigator = () =>
  createSelector(
    selectAssignInvestigatorDomain,
    substate => substate
  );

  const makeSelectAssignInvestigatorId = () =>
  createSelector(
    selectAssignInvestigatorDomain,
    substate => substate.assigned_investigator
  );

  const makeSelectAssignInvestigatorState = () =>
  createSelector(
    selectAssignInvestigatorDomain,
    substate => substate.selectState
  );

  const makeSelectAssignInvestigatorCity = () =>
  createSelector(
    selectAssignInvestigatorDomain,
    substate => substate.selectCity
  );

export default makeSelectAssignInvestigator;
export { selectAssignInvestigatorDomain, makeSelectAssignInvestigatorId, makeSelectAssignInvestigatorState, makeSelectAssignInvestigatorCity };
