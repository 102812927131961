import React, { memo } from 'react';
import { Input } from 'reactstrap';
export const Text = memo((props) => {
  let headername = props.config ? props.config.renamed_block_parameter : '';
  const [selectedValue, setSelectedValue] = React.useState(props.config.parameter_value ? props.config.parameter_value : 'Please select ' + headername)
  const setText = (val) => {
    setSelectedValue(val.value)
    let temp = JSON.parse(JSON.stringify(props.data));
    temp[props.i].parameter_value = val.value;
    temp[props.i].id = val.id;
    props.setData(temp)
  }

  function backgroundColor() {
    return props.casetype === "opencase" ? "rgba(26, 178, 117, 0.1)" : props.casetype === "allottedcase" ? 'rgba(5, 138, 238, 0.1)' : "rgba(235, 175, 0, 0.1)"
  }

  return (
    <div className='textinput-genric-submit dd submit-dropdown'>
      <label className='label-text'>{props.config.renamed_block_parameter}{props.ifRequired && <span style={{color:"red"}}>*</span>}</label>
      <Input
        required={props.ifRequired}
        className='Genericforms-input'
        style={{backgroundColor: backgroundColor()}}
        data-testid="genericinput"
        type='text'
        defaultValue = {props.config.parameter_value}
        onChange={(evt)=>setText(evt.target)}
        value={props.config.parameter_value}
      />
    </div>
  )
})

Text.propTypes = {}

export default Text