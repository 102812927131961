/*
 *
 * AssignInvestigator actions
 *
 */

import { ASSIGN_INVESTIGATOR, FILTER_STATE, FILTER_CITY } from "./constants";

export function selectInvestigator(id) {
  return{
    type:ASSIGN_INVESTIGATOR,
    id
  }
}

export function setFilterByState(payload) {
  return{
    type:FILTER_STATE,
    payload
  }
}

export function setFilterByCity(payload) {
  return{
    type:FILTER_CITY,
    payload
  }
}