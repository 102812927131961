import React, { useState, useEffect } from "react";
import { FaCheckCircle } from "react-icons/fa"; // import the green tick icon from react-icons library
import './index.css';

export function SuccessMessage({ message, delay = 2000 }) {
  const [showMessage, setShowMessage] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowMessage(false);
    }, delay);
    return () => clearTimeout(timer);
  }, [delay]);

  return (
    <div className="SuccessMessage-main">
    <div className="SuccessMessage-main2" style={{ display: showMessage ? "flex" : "none",}}>
      <FaCheckCircle style={{ marginRight: "10px", color: "#4caf50" }} />
      <p style={{ margin: "0" }}>{message}</p>
    </div>
    </div>
  );
}
export default SuccessMessage;
