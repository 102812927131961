import { createSelector } from "reselect";
import { initialState } from "./reducer";

const selectSearchCaseDomain = state => state.searchCase || initialState;

const makeSelectSearchCase = () =>
  createSelector(
    selectSearchCaseDomain,
    substate => substate
  );

const makeSelectCaseSearchValue = () =>
  createSelector(
    selectSearchCaseDomain,
    substate => substate.caseSearchValue
  );

export default makeSelectSearchCase;
export { selectSearchCaseDomain, makeSelectCaseSearchValue };
