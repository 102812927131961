import React, { memo } from 'react';
import { DropdownMenu, DropdownItem, ButtonDropdown, DropdownToggle} from "reactstrap";

const Dropdown = memo((props) => {
    const [dropdownOpen, setOpen] = React.useState(false);
    let headername = props.config ? props.config.renamed_block_parameter :'';
    const [selectedValue, setSelectedValue] = React.useState(props.config.parameter_value ? props.config.parameter_value : 'Please select '+headername)
    const selectDropdown = (val) => {
        setSelectedValue(val)
        props.handleGenFormInput(val)
        // props.saveFormData({block_parameters_id:props.config.block_parameters_id,value:val})
    }
    return (
        <div className='dropdown generic-dropdown'>
            <label className='label-text'>{props.config.renamed_block_parameter}{props.ifRequired && <sup>*</sup>}</label>
            <ButtonDropdown data-testid="dropdownItem22" toggle={() => { setOpen(!dropdownOpen) }} 
                isOpen={dropdownOpen}>
                <DropdownToggle className="" caret style={{backgroundColor: props.formcolor ? props.formcolor : 'rgba(5, 138, 238, 0.1)',border:"none"}}>
                    {selectedValue}
                </DropdownToggle>
                <DropdownMenu style={{backgroundColor: props.formcolor ? props.formcolor : 'rgba(5, 138, 238, 0.1)',}} className="generic-dropdownitems-main">
                    {props.config.parameter_options && props.config.parameter_options.map(data => {
                        return <DropdownItem  data-testid="dropdownItem" key={data} onClick={() => selectDropdown(data)} className="generic-dropdownitems">{data}</DropdownItem>
                    })}
                </DropdownMenu>
            </ButtonDropdown>
        </div>
    )
})

Dropdown.propTypes = {};

export default Dropdown;