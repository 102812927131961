import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { OPEN_CASE_DETAILS } from "../../constants/pathConstants";
import CommonCaseCard from "../../components/CommonCaseCard/CommonCaseCard";

export const CaseCard = ({
  selectCards,
  showCaseType,
  showSelect,
  handleMultiCaseSelect,
}) => {
  return (
    <div>
      {selectCards &&
        selectCards.map((item, index) => (
          <CommonCaseCard
            key={`openCasesCard-${index}`}
            showCaseType={showCaseType ? showCaseType : false}
            cardDetail={item}
            btn={true}
            showSelect={showSelect}
            handleMultiCaseSelect={handleMultiCaseSelect}
            path={OPEN_CASE_DETAILS}
            cardType={"Open"}
          />
        ))}
    </div>
  );
};

const mapStateToProps = createStructuredSelector({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(CaseCard);
