import React, { useRef } from "react";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import "@cyntler/react-doc-viewer/dist/index.css";
import "./DocxOpener.css"

const DocxOpener = ({ 
  path= "",
 }) => {
  const containerRef = useRef(null);

  const docs = [
    { uri: path },
  ];

  return (
        <div className="docxOpener-maindiv">
          <div ref={containerRef} className="docxOpener-innerdiv" >
            <DocViewer
              documents={docs}
              initialActiveDocument={docs[1]}
              pluginRenderers={DocViewerRenderers}
              allow
            />
          </div>
        </div>
  );
};

export default DocxOpener;
