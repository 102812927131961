import React, { useState, useEffect, memo } from 'react';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose } from 'redux';
import './index.css';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';
export function Barchart({ageingData}) {
    let data = [
        { name: 'Health', in_tat: 0, over_tat: 0, },
        { name: 'Motor', in_tat: 0, over_tat: 0, },
      ];
    const [barchartData, setbarchartData] = useState(data);
   
      useEffect(() => {
        if(ageingData && ageingData.graphData){
          let assesmentData = ageingData.graphData;
          data.forEach(item => {
            if (item.name === 'Health' && assesmentData["A&H"]) { 
              item.in_tat = assesmentData["A&H"].in_tat;
              item.over_tat = assesmentData["A&H"].over_tat;
            } else if (item.name === 'Motor' && assesmentData["Motor"]) {
              item.in_tat = assesmentData["Motor"].in_tat;
              item.over_tat = assesmentData["Motor"].over_tat;
            }
          });
        }
        setbarchartData(data)
      }, [ageingData]);

    const RenderCustomizedLabel = ({ x, y, value, dataKey, index,width}) => {
        return (
            <text x={x+ width / 2} y={y+9} dy={5} fill="#fff" fontSize={10} textAnchor="middle">
                {labelvalue(index, dataKey) === "0" ? null :labelvalue(index, dataKey)}
            </text>
        );
    };

    const labelvalue = (index, dataKey) => {
        const dataPoint = barchartData[index][dataKey];
        return dataPoint.toString();
    };

    return (
        <div className='main-radialprocesschart' style={{height: 50, marginTop: 15}}>
            <BarChart
                isAnimationActive={false}
                width={230}
                height={55}
                data={barchartData}
                margin={{ top: 0.5, right: 0.5, left: 0.5, bottom: 0.5 }}
                layout="vertical"
            >
                <XAxis tickLine={false} allowDuplicatedCategory={true} type="number" hide />
                <YAxis tickLine={false} axisLine={true} dataKey="name" type="category" fontSize={12} />
                <Bar
                    isAnimationActive={false}
                    dataKey="in_tat"
                    stackId="a"
                    fill="#22A6B3"
                    label={<RenderCustomizedLabel dataKey="in_tat" />}
                    barSize={20}
                    className="barchartmain"
                />
                <Bar
                    isAnimationActive={false}
                    dataKey="over_tat"
                    stackId="a"
                    fill="#9980FA"
                    label={<RenderCustomizedLabel dataKey="over_tat" />}
                    barSize={20}
                    className="barchartmain2"
                    radius={[0, 5, 5, 0]}
                />
            </BarChart>
        </div>
    );
}

Barchart.propTypes = {
};

const mapStateToProps = createStructuredSelector({
});

export function mapDispatchToProps(dispatch) {
    return {
    };
}

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);

export default compose(withConnect, memo)(Barchart);
