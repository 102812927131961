import { redirect } from "react-router-dom";

export const PrivatePath = ({ children }) => {
  //event of google tag manager when user is logged out.
  if (!sessionStorage.getItem("isLoggedin")) {
    window.dataLayer.push({
      event: "Logged_out",
    });
    return redirect("/");
  } else{
    return children;
  }
  
};