import { DEFAULT_ACTION, CASEUPDATE, SELECTCASE } from "./constants";

export function defaultAction() {
  return {
    type: DEFAULT_ACTION
  };
}

export function casesUpdate(payload) {
  return {
    type: CASEUPDATE,
    payload: payload
  };
}
export function setMultiCaseId(payload) {
  return {
    type: SELECTCASE,
    payload: payload
  };
}