import React from "react";
import Basic from "../containers/OpenCasesDetailsPage/CardTabs/Basic";
import Policy from "../containers/OpenCasesDetailsPage/CardTabs/Policy";
import Proposal from "../containers/OpenCasesDetailsPage/CardTabs/Proposal";
import Risk from "../containers/OpenCasesDetailsPage/CardTabs/Risk";
import Endorsement from "../containers/OpenCasesDetailsPage/CardTabs/Endorsement";
import Dispatch from "../containers/OpenCasesDetailsPage/CardTabs/Dispatch";
import Claim from "../containers/OpenCasesDetailsPage/CardTabs/Claim";
import Investigation from "../containers/OpenCasesDetailsPage/CardTabs/Investigation/Investigation";
import InvestigatorInvestigation from "../containers/OpenCasesDetailsPage/CardTabs/Investigation/InvestigatorInvestigation";
import External from "../containers/OpenCasesDetailsPage/CardTabs/External";

const cardDataVendor = [
    {
      tabId: '1',
      tabTitle: 'Investigation',
      tabContent: <Investigation />
    },
    {
      tabId: '2',
      tabTitle: 'Basic',
      tabContent: <Basic/>
    },
    {
      tabId: '3',
      tabTitle: 'Policy',
      tabContent:<Policy />
    },
    {
      tabId: '4',
      tabTitle: 'Claim',
      tabContent: <Claim />
    },
    {
      tabId: '5',
      tabTitle: 'Proposal',
      tabContent: <Proposal />
    },
    {
      tabId: '6',
      tabTitle: 'Dispatch',
      tabContent: <Dispatch />
    },
    {
      tabId: '7',
      tabTitle: 'Risk',
      tabContent: <Risk />
    },
    {
      tabId: '8',
      tabTitle: 'Endorsement',
      tabContent: <Endorsement/>
    },
    {
      tabId: '9',
      tabTitle: 'External',
      tabContent: <External />
    },
  ];


    const cardDataInvstigator = [
    {
      tabId: '1',
      tabTitle: 'Investigation',
      tabContent: <InvestigatorInvestigation />
    },
    {
      tabId: '2',
      tabTitle: 'Basic',
      tabContent: <Basic/>
    },
    {
      tabId: '3',
      tabTitle: 'Policy',
      tabContent:<Policy />
    },
    {
      tabId: '4',
      tabTitle: 'Claim',
      tabContent: <Claim />
    },
    {
      tabId: '5',
      tabTitle: 'Proposal',
      tabContent: <Proposal />
    },
    {
      tabId: '6',
      tabTitle: 'Dispatch',
      tabContent: <Dispatch />
    },
    {
      tabId: '7',
      tabTitle: 'Risk',
      tabContent: <Risk />
    },
    {
      tabId: '8',
      tabTitle: 'Endorsement',
      tabContent: <Endorsement/>
    },
    {
      tabId: '9',
      tabTitle: 'External',
      tabContent: <External />
    },
  ];

  export{cardDataVendor,cardDataInvstigator}