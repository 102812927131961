/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { combineReducers } from "redux";
import globalReducer from './containers/App/reducer';
import mobileReducer from './containers/Mobile/reducer';
import otpReducer from './containers/OtpPage/reducer';
import dashBoardReducer from './containers/dashboard/reducer';
import employmentMasterPageReducer from './containers/EmploymentMasterPage/reducer';
/**
 * Merges the main reducer with the router state and dynamically injected reducers
 */
export default function createReducer(injectedReducers = {}) {
  const rootReducer = combineReducers({
    global: globalReducer,
    ...injectedReducers,
    mobileReducer:mobileReducer,
    otpReducer:otpReducer,
    dashBoardReducer:dashBoardReducer,
    employmentMasterPageReducer:employmentMasterPageReducer,
  });

  return rootReducer;
}
