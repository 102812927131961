/*
 * mobileReducer
 *
 * The reducer takes care of our data. Using actions, we can
 * update our application state. To add a new action,
 * add it to the switch statement in the reducer function
 *
 */
import produce from 'immer';
import { CHANGE_USERNAME, MOBILE_NUMBER, DASHBOARD_MENU, UPDATE_EMPLOYEECOUNT, AGEING_REPORT } from './constants';
export const initialState = { // The initial state of the App
  username: '',
  menu: [],
  employeeCountdata: {},
  ageingData: {},
};

const dashBoardReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case CHANGE_USERNAME:
        draft.username = action.username.replace(/@/gi, '');
        break;
      case UPDATE_EMPLOYEECOUNT:
        draft.employeeCountdata = action.employeeCountdata;
        break;
      case MOBILE_NUMBER:
        draft.mobileNumber = action.number;
        break;
      case DASHBOARD_MENU:
        draft.menu = action.menu;
        break;
      case AGEING_REPORT:
        draft.ageingData = action.ageingData
    }
  });

export default dashBoardReducer;
