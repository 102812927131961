import React, { memo } from 'react'
import { Navbar, NavbarBrand,} from 'reactstrap';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { compose } from 'redux';
import path from '../../../constants/pathConstants';
import './navbar.css';
import NavbarBackButton from './NavbarBackButton';
import propTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { imageBaseUrl } from '../../../utils/constants';
export function EmploymentMasterNavbar({ title, backButton, search}) {
    const navigate = useNavigate();
    return (
        <div className='navbar-container navbar-employee-master' data-testid="employment-master-navbar">
            <Navbar>
                <NavbarBrand onClick={()=> backButton ? navigate(backButton) : navigate(-1)} className='d-flex' style={{cursor: "pointer"}}>
                    <NavbarBackButton />
                    <div className='title' style={{ marginTop: 3 }}>{title}</div>
                </NavbarBrand>
                <span>
                    {search && <img data-testid="search-icon" className='search-icon' onClick={() => navigate(path.EMPLOYEMENT_SEARCH)} src={`${imageBaseUrl}/search.svg`} alt="message" />}
                    <img data-testid="bell-icon" className='bell-icon' onClick={() => navigate(path.COMING_SOON+"?" + btoa("title=Notification"))} src={`${imageBaseUrl}/bell.svg`} alt="notification" />
                </span>
            </Navbar>
        </div>
    )
}
EmploymentMasterNavbar.propTypes = {
    title: propTypes.string,
    backButton: propTypes.string,
    search: propTypes.bool,
    redirectPath: propTypes.func,
    dispatch: propTypes.func
};

const mapStateToProps = createStructuredSelector({
});

export function mapDispatchToProps(dispatch) {
    return {
        redirectPath: (path) => dispatch(push(path)),
    };
}

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);

export default compose(withConnect, memo,)(EmploymentMasterNavbar);