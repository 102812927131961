import React, { memo } from 'react';
import { Input } from 'reactstrap';
import './index.css';

function Text(props) {

  const checkDisabled = (name) => {
    const disable = ['Agency Name', 'Product Category', 'Invoice Number'];
    if(disable.includes(name)){
      return true;
    } else {
      return false;
    }
  }
  return (
    <div className='textinput-genricpet-main'>
    {props?.showLabel && (<div className='Genericforms-text'>{props?.config?.renamed_block_parameter}{props?.ifRequired && <sup>*</sup>}</div>)}
    <Input
      className='GenericPetforms-input'
      style={{backgroundColor: props?.formcolor ? props?.formcolor  :'rgba(5, 138, 238, 0.1)',border:"none"}}
      data-testid="genericinput"
      type='text'
      disabled={props?.isDisabled ? true : checkDisabled(props?.config?.renamed_block_parameter)}
      required={props?.ifRequired}
      defaultValue = {props?.config?.parameter_value}
      value={props?.config?.parameter_value}
      placeholder={props?.config?.renamed_block_parameter}
      onChange={(event) => props.handleInput(event.target.value)}
    />
  </div>
  )
}

export default memo(Text);