import React from "react";

function NavbarBackButton() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10"
      height="16"
      fill="none"
      viewBox="0 0 10 16"
    >
      <path
        fill="#3A3A3A"
        d="M7.604 15.583l-7.02-7a.726.726 0 01-.177-.27A.916.916 0 01.354 8c0-.111.018-.215.053-.313a.726.726 0 01.176-.27L7.604.396a.99.99 0 01.73-.292c.29 0 .54.104.75.313a1 1 0 01.312.729 1 1 0 01-.313.729L2.958 8l6.125 6.125a.976.976 0 01.292.718c0 .285-.104.532-.313.74a1 1 0 01-.729.313 1 1 0 01-.729-.313z"
      ></path>
    </svg>
  );
}

export default NavbarBackButton;